import { motion } from "framer-motion";
import styled from "styled-components";


export const StyledSubscriptionSuccessInfo = styled(motion.div)`
  padding: 24px;

  
	.status {
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 19px;

		margin-bottom: 14px;
		justify-content: center;
	}

	.info {


		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 14px;
		margin-bottom: 20px;
	}

	.button-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 24px;

		.button {
			min-width: 124px;
		}
	}
`;