import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledService = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;

  .logo {
    height: 110px;
    min-width: 70px;
    background-image: ${({ logo }) =>
      logo ? `url(${logo})` : "url(/images/6061412-2.png)"};
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .info {
    font-size: 12px;
    font-family: ${({ theme }) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
    font-weight: 700;
    font-style: normal;
    line-height: 14.06px;
    display: flex;
    flex-direction: column;
    padding: 24px 0 12px 24px;

    .welcome-action {
      font-size: 16px;
      margin-bottom: 5px;
    }

    .sub-info {
      font-size: 14px;
      font-weight: 400;
      opacity: 0.6;
    }

    .learn-more {
      color: #ffcb05;
      padding-left: 5px;
    }
  }
`;
