import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledClosestSearchResultCard = styled(motion.div)`
  .tag {
    padding: 6px;
    padding-left: 0;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .title {
    font-family: "MTN";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding: 0;
  }
  .desc {
    font-family: "MTN";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: auto;
  }

  .genre {
    font-family: "MTNBrighterSansRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }

  .tag-description-sub {
    display: flex;
    justify-items: center;
    justify-content: center;

    padding: 6px;

    background: #ffcb05;
    color: #111111;
    border-radius: 2px 0px 0px 2px;
    font-family: "MTNBrighterSansRegular";
    font-style: normal;
    font-weight: bold;

    line-height: 12px;
    text-transform: capitalize;
  }

  .tag-description-free {
    display: flex;
    justify-items: center;
    justify-content: center;

    padding: 6px;
    background: #111111;
    color: #ffffff;
    border-radius: 2px 0px 0px 2px;
    font-family: "MTNBrighterSansRegular";
    font-style: normal;
    font-weight: bold;

    line-height: 12px;
    text-transform: capitalize;
  }

  .tag-description-once {
    display: flex;
    justify-items: center;
    justify-content: center;

    padding: 6px;

    background: #111111;
    color: #ffcb05;
    border-radius: 2px 0px 0px 2px;
    font-family: "MTNBrighterSansRegular";
    font-style: normal;
    font-weight: bold;

    line-height: 12px;
    text-transform: capitalize;
  }

  .tag-description-pending {
    display: flex;
    justify-items: center;
    justify-content: center;

    padding: 6px;

    background: #dc6428;
    color: #fff;
    border-radius: 2px 0px 0px 2px;
    font-family: "MTNBrighterSansRegular";
    font-style: normal;
    font-weight: bold;

    line-height: 12px;
    text-transform: capitalize;
  }

  .metadata {
    margin-top: 12px;
    min-height: ${({ item }) => (item.description ? "30px" : "20px")};
    max-width: 170px;
    line-height: 12px;
    font-family: ${({ theme }) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
    font-size: ${({ theme }) => theme?.size?.f2 ?? "10px"};
    font-style: normal;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    text-transform: capitalize;

    & span {
      font-size: ${({ theme }) => theme?.size?.f1 ?? "8px"};
      line-height: 9px;
      font-family: ${({ theme }) =>
        theme?.fonts[0] ?? "MTNBrighterSansRegular"};
    }
  }

  .thumbnail {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    font-size: 10px;
    padding-top: 12px;
    font-family: "MTNBrighterSansRegular";
    font-style: normal;
    font-weight: bold;
    height: 202px;
    width: 150px;
    border-radius: 8px;

    background-image: ${({ item }) =>
      item?.imageUrl?.length > 0 ? `url(${item?.imageUrl})` : ""};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  height: 202px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;
  margin-bottom: 24px;
`;
