export const FETCH_SUBSCRIPTION_REQUEST = "FETCH_SUBSCRIPTION_REQUEST";
export const SELECT_SUBSCRIPTION_ITEM = "SELECT_SUBSCRIPTION_ITEM";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const SELECT_SERVICE_SUCCESS = "SELECT_SERVICE_SUCCESS";
export const SELECT_SERVICE_ERROR = "SELECT_SERVICE_ERROR";
export const SELECT_SERVICE_REQUEST = "SELECT_SERVICE_REQUEST";
export const FETCH_SUBSCRIPTION_ERROR = "FETCH_SUBSCRIPTION_ERROR";
export const SELECT_CONSENT_REQUEST = "SELECT_CONSENT_REQUEST";
export const SELECT_CONSENT_SUCCESS = "SELECT_CONSENT_SUCCESS";
export const SELECT_CONSENT_ERROR = "SELECT_CONSENT_ERROR";
export const SELECT_SUBSCRIPTION_CHARGE_ERROR = "SELECT_SUBSCRIPTION_CHARGE_ERROR";
export const SELECT_SUBSCRIPTION_CHARGE_SUCCESS = "SELECT_SUBSCRIPTION_CHARGE_SUCCESS";
export const SELECT_SUBSCRIPTION_CHARGE_REQUEST = "SELECT_SUBSCRIPTION_CHARGE_REQUEST";
export const CONFIRM_SUBSCRIPTION = "CONFIRM_SUBSCRIPTION";
export const RESET_SUBSCRIPTION = "RESET_SUBSCRIPTION";
export const CLEAR_CONSENT = "CLEAR_CONSENT";



/** Payments Method */
export const SELECT_PAYMENT_METHOD_SUCCESS = "SELECT_PAYMENT_METHOD_SUCCESS";
export const SELECT_PAYMENT_METHOD_REQUEST = "SELECT_PAYMENT_METHOD_REQUEST";
export const SELECT_PAYMENT_METHOD_ERROR = "SELECT_PAYMENT_METHOD_ERROR";

/**
 * BUYING Methods
 */
export const BUY_ITEM_SUCCESS = "BUY_ITEM_SUCCESS";
export const BUY_ITEM_REQUEST = "BUY_ITEM_REQUEST";
export const BUY_ITEM_ERROR = "BUY_ITEM_ERROR";

/**
 * BUYING Methods
 */
export const FETCH_PAYMENT_METHODS_SUCCESS = "FETCH_PAYMENT_METHODS_SUCCESS";
export const FETCH_PAYMENT_METHODS_REQUEST = "FETCH_PAYMENT_METHODS_REQUEST";
export const FETCH_PAYMENT_METHODS_ERROR = "FETCH_PAYMENT_METHODS_ERROR";

export const FETCH_TOPUP_OPTIONS_SUCCESS = "FETCH_TOPUP_OPTIONS_SUCCESS";
export const FETCH_TOPUP_OPTIONS_REQUEST = "FETCH_TOPUP_OPTIONS_REQUEST";
export const FETCH_TOPUP_OPTIONS_ERROR = "FETCH_TOPUP_OPTIONS_ERROR";

export const FETCH_BALANCES_SUCCESS = "FETCH_BALANCES_SUCCESS";
export const FETCH_BALANCES_REQUEST = "FETCH_BALANCES_REQUEST";
export const FETCH_BALANCES_ERROR = "FETCH_BALANCES_ERROR";

export const VOUCHER_TOPUP_SUCCESS = "VOUCHER_TOPUP_SUCCESS";
export const VOUCHER_TOPUP_REQUEST = "VOUCHER_TOPUP_REQUEST";
export const VOUCHER_TOPUP_ERROR = "VOUCHER_TOPUP_ERROR";

export const CLEAR_SUBSCRIPTION = "CLEAR_SUBSCRIPTION";

/**
  *  USER SUBSCRIPTIONS
  */

export const FETCH_SUBSCRIPTION_LIST_SUCCESS = "FETCH_SUBSCRIPTION_LIST_SUCCESS";
export const FETCH_SUBSCRIPTION_LIST_REQUEST = "FETCH_SUBSCRIPTION_LIST_REQUEST";
export const FETCH_SUBSCRIPTION_LIST_ERROR = "FETCH_SUBSCRIPTION_LIST_ERROR";

export const FETCH_PURCHASE_LIST_SUCCESS = "FETCH_PURCHASE_LIST_SUCCESS";
export const FETCH_PURCHASE_LIST_REQUEST = "FETCH_PURCHASE_LIST_REQUEST";
export const FETCH_PURCHASE_LIST_ERROR = "FETCH_PURCHASE_LIST_ERROR";

export const FETCH_USER_SUBSCRIPTION_SUCCESS = "FETCH_USER_SUBSCRIPTION_SUCCESS";
export const FETCH_USER_SUBSCRIPTION_REQUEST = "FETCH_USER_SUBSCRIPTION_REQUEST";
export const FETCH_USER_SUBSCRIPTION_ERROR = "FETCH_USER_SUBSCRIPTION_ERROR";
  
/**
  *  USER PURCHASES
  */
export const FETCH_USER_PURCHASE_SUCCESS = "FETCH_USER_PURCHASE_SUCCESS";
export const FETCH_USER_PURCHASE_REQUEST = "FETCH_USER_PURCHASE_REQUEST";
export const FETCH_USER_PURCHASE_ERROR = "FETCH_USER_PURCHASE_ERROR";

/**
 * UN-SUBSCRIBE
 */
export const UN_SUBSCRIBE_ITEM_SUCCESS = "UN_SUBSCRIBE_ITEM_SUCCESS";
export const UN_SUBSCRIBE_ITEM_REQUEST = "UN_SUBSCRIBE_ITEM_REQUEST";
export const UN_SUBSCRIBE_ITEM_ERROR = "UN_SUBSCRIBE_ITEM_ERROR";