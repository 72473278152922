import {useEffect, useState} from "react";
import {StyledMenu} from "./SubComponent.styles";
import {SubMenuItem} from "..";
import {ModalSortOptions} from "../ModalSortOptions";
import { useSelector } from "react-redux";

const sortOptions = [
	{
		value: "title",
		title: "A-Z",
	},
	{
		value: "date",
		title: "Date",
	},
	{
		value: "price",
		title: "Price",
	}
];

const Menu = ({items, title, events = {}, chosenTab}) => {
	console.log("check@@", title );
	const [sort, setSort] = useState(false);
	const [currentFilter, setCurrentFilter] = useState(sortOptions[0]);
	const [defaultFilter, setDefaultFilter] = useState(false);

	const {
		language,
	  } = useSelector((state) => state);

	useEffect(() => {
		if (defaultFilter) {
			if (currentFilter?.value?.toLowerCase() in events && "dispatch" in events) {
				const mKey = currentFilter?.value?.toLowerCase();
				if ("type" in events[mKey] && "event" in events[mKey] &&
          Object.keys(`${events[mKey]?.event?events[mKey]?.event(mKey):""}`).length > 0) {
					let _event = {
						event: events[mKey]?.event(currentFilter?.title),
						type: events[mKey].type,
					};
					events.dispatch(_event);
				}
			}
		} else {
			setDefaultFilter(true);
		}
	}, [currentFilter]);

	const handleDoSort = (item) =>{
		setCurrentFilter(item);
		setSort(false);
	};

	const ItemsView = () =>{
		if(items===undefined || items === null || items?.length===0) return <></>;

		if(currentFilter?.value === "title")
			return items
        ?.sort((a, b) =>
          a["service"][currentFilter?.value] >
          b["service"][currentFilter?.value]
            ? 1
            : b["service"][currentFilter?.value] >
              a["service"][currentFilter?.value]
            ? -1
            : 0,
        )
        .map((item, index) => (
          <SubMenuItem key={index} item={item} chosenTab={chosenTab} />
        ));

		if(currentFilter?.value === "price")
			return items
        ?.sort(
          (a, b) =>
            a["service"][currentFilter?.value] -
            b["service"][currentFilter?.value],
        )
        .map((item, index) => (
          <SubMenuItem key={index} item={item} chosenTab={chosenTab} />
        ));

		//Note: this is to be looked into as the determinant date is not yet part of the item properties.
		if(currentFilter?.value === "date")
			return items
        ?.sort(
          (a, b) =>
            a["service"][currentFilter?.value] -
            b["service"][currentFilter?.value],
        )
        .map((item, index) => (
          <SubMenuItem key={index} item={item} chosenTab={chosenTab} />
        ));
	};

	return (
		<StyledMenu>
			<div className="span">
        
				<div className="title">{title}</div>
				<div
					className="actions"
					onClick={() => {
						setSort(true);
					}}>
					<div className="sort-icon icon"></div>
					<div className="icon">{currentFilter?.title}</div>
				</div>
			</div>
			{
				language?.chosenPack?.language?.name === "english" &&
				<p style ={{marginTop: 35, fontSize: 12}}>{language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections_content?.subscriptions?.subscription_list_one} {chosenTab.toLowerCase()}.</p>
			}
						{
				language?.chosenPack?.language?.name === "french" &&
				<p style ={{marginTop: 35, fontSize: 12}}>{language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections_content?.subscriptions?.subscription_list_one} {chosenTab.toLowerCase()} {language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections_content?.subscriptions?.subscription_list_2}.</p>
			}

			{sort ?
				<ModalSortOptions
					show={true}>
					<div className="sorting-option-body">
						<p style={{fontFamily: "MTN", fontSize: "16px", fontWeight: "700", lineHeight: "20.8px", marginBottom: "15px"}}>Select a sort option</p>
						{sortOptions.map((item, index)=>{
							return <p style={{fontFamily: "MTNBrighterSansRegular", fontSize: "16px", fontWeight: "400", lineHeight: "24px"}} onClick={()=>{handleDoSort(item);}} key={index}>{item.title}</p>;
						})}
						<button style={{fontWeight:"700", fontSize:"12px", fontFamily:"MTNBrighterSansRegular", marginTop: "24px", float: "right", display: "flex", marginBottom:"24px", color: "#FBC800", background:"#00000000", border:"none"}} onClick={()=>{handleDoSort(sortOptions[0]);}}>CANCEL</button>
					</div>
				</ModalSortOptions>: <></>}
			<ItemsView/>
		</StyledMenu>
	);
};

export default Menu;
