import {useEffect} from "react";
import {useState} from "react";
import {StyledHeaderTabs} from "./HeaderTabs.styles";


const HeaderTabs = ({tabs, toggleTabs, events = {}, initPosition=0}) => {
	const [active, setActive] = useState(tabs[initPosition]);
	const [defaultTabActive, setDefaultTabActive] = useState(false);

	useEffect(() => {
try {
  if (defaultTabActive == true) {
    const activeModified = () => {
      if (active === "InActive") {
        return "Inactive";
      } else {
        return active;
      }
    };

    if (activeModified() in events && "dispatch" in events) {
      if (
        "type" in events[activeModified()] &&
        "event" in events[activeModified()] &&
        Object?.keys(events[activeModified()]?.event(activeModified()))
          ?.length > 0
      ) {
        let _event = {
          event: events[activeModified()].event(activeModified()),
          type: events[activeModified()].type,
        };

        events.dispatch(_event);
      }
    }
  } else {
    setDefaultTabActive(true);
  }
} catch (e) {
  console.log(e);
}
	}, [active]);

	return (
		<StyledHeaderTabs>
			{tabs.map((tab) => (
				<div
					key={tab}
					onClick={() => {
						setActive(tab);
						toggleTabs(tab);
					}}
					className={`tab ${active === tab ? "active" : ""}`}>
					{tab}
				</div>
			))}
		</StyledHeaderTabs>
	);
};

export default HeaderTabs;
