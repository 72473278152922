import {useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import { MenuModal } from "../MenuModal";
import { StyledCategoryBar } from "./SubMenu.styles";
import {appState} from "../../../../utils/redux/actions";

const SubMenu = ({state, onUpdateState, dataSet, onResetMenu}) => {
	const dispatch = useDispatch();

	const [modalDataSet, setModalDataSet] = useState(undefined);
	
	const handleSelectCategory = (key) =>{
		const _default = dataSet[key].default;
		const _dataSet = dataSet[key].dataSet;

		setModalDataSet({dataSet:_dataSet, default:_default, key:key});
	};

	const handleOnUpdateState = (data) =>{
		onUpdateState(data);
	};

	const handleCloseModal = () =>{
		setModalDataSet(undefined);
	};

	useEffect(()=>{
		dispatch(appState.saveHomeScrollState(0.0));
	}, []);

	const SubMenuView = () => {
		return <div className="sub-cat-parent">
			<div className="sub-cat-body">
				{
					Object.keys(dataSet).map((key, index) => {
						const previouslySelectedIndex = state[key].selectedIndex;
						const _default = state[key].default;
						const previouslySelectedItem = previouslySelectedIndex!==undefined ? dataSet[key].dataSet[previouslySelectedIndex] : _default;
					
						return <div key={`bg$${index}`} className="background">
							<div className="category" key={`cat$${index}`} style={{fontFamily:previouslySelectedIndex!==undefined?"MTNBrighterSansBold":"MTNBrighterSansRegular", fontSize:previouslySelectedIndex!==undefined?"16px":"14px"}} onClick={()=>handleSelectCategory(key)}>{previouslySelectedItem}</div>
							<div className="sub-icon icon" onClick={()=>handleSelectCategory(key)}/>
						</div>;
					})
				}
			</div>

			<div className="sub-cat-tail">
				<div onClick={onResetMenu} className="cancel-sub-icon icon"/>
			</div>
		</div>;
	};

	return (
		<StyledCategoryBar>
			<SubMenuView/>
			{modalDataSet?<MenuModal dataSet={modalDataSet} previouslySelectedIndex={state[modalDataSet.key].selectedIndex} 
				onSelectItem={(data)=>handleOnUpdateState(data)} onCloseModal={handleCloseModal}/>:null}
		</StyledCategoryBar>
	);
};

export default SubMenu;