import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledCategoryBar = styled(motion.div)`
  height: ${props => props.isheighted ? heighted : "44px"};
  background-color: #000;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  overflow-x :auto;
  position: sticky;
  top: 60px;

  .category {
	  margin: 0 24px;
	  font-family: MTN;
	  text-transform: capitalize;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 21px;
    color: #BDBDBD;  
  }

  .bar-with-icon {
    display: flex;
  }

  .sub-icon {
   background-image: url('/images/icons/right_chevron.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.icon {
    /* margin: ${({theme}) => theme?.spacing?.f4 ?? "12px"}; */
    height: 20px;
    width: 20px;
}
`;