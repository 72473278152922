// import React from "react";
import { Link } from "react-router-dom";
import { StyledOverlay, StyledBannerTransRes, StyledPaymentBannerTransRes} from "./../Banner/Banner.styles";
const SubscriptionBanner = ({imageUrl, isOpacity, bannerHeader, route, bannerSummary, paymentStyle=false}) => {
	return (
		<div>
			{
				paymentStyle ? 
					<StyledPaymentBannerTransRes isOpacity={isOpacity} image={imageUrl}>
						{isOpacity && (
							<StyledOverlay>
								<span className="banner-question">{bannerHeader}</span>
								<span className="banner-justification">
					 {bannerSummary}
								</span>
								<Link to={route} style={{textDecoration: "none", color: "#000"}}><div className="outline-btn">Optimise My Experience</div></Link>
							</StyledOverlay>
						)}
					</StyledPaymentBannerTransRes> : 
					<StyledBannerTransRes isOpacity={isOpacity} image={imageUrl}>
						{isOpacity && (
							<StyledOverlay>
								<span className="banner-question">{bannerHeader}</span>
								<span className="banner-justification">
									{bannerSummary}
								</span>
								<Link to={route} style={{textDecoration: "none", color: "#000"}}><div className="outline-btn">Optimise My Experience</div></Link>
							</StyledOverlay>
						)}
					</StyledBannerTransRes>
			}
		</div>
	);
};

export default SubscriptionBanner;




