import { categories } from "../types";

const initialState = {
	loading: false,
	categories: [],
	subCategories: [],
	isShowSubCategories: false,
	error: null
};

const categoriesReducer = (state = initialState, action) => {
	switch (action.type) {
	case categories.FETCH_CATEGORIES_REQUEST:
		return {
			...state,
			loading: true,
			error: null
		};
	case categories.FETCH_CATEGORIES_SUCCESS:
		return {
			...state,
			loading: false,
			error: null,
			categories: action.payload
		};
	case categories.FETCH_CATEGORIES_ERROR:
		return {
			...state,
			loading: false,
			error: action.payload
		};
	case categories.TOGGLE_CATEGORIES:
		return {
			...state,
			loading: false,
			isShowSubCategories: !state.isShowSubCategories
		};
	case categories.UPDATE_SUBCATEGORIES:
		return {
			...state,
			loading: false,
			subCategories: action.payload
		};
	default:
		return state;
	}
};

export default categoriesReducer;