import MetaStreamIO from "metastreamio-web";
import { analyticUri } from "../constants/appConstants";

let metastreamioConfig = {
  app: {
    id: "mtn-play",
    channel: "web",
    environment: analyticUri.environment,
    endpoints: [analyticUri.endpoint],
    // headers: {"X-Api-Key":"F7D78689-30E6-47E9-A699-4743184AEC7B"},
  },
  config: {
    logging: false,
    loggingLevel: "INFO",
    loggingCategories: ["network-request"],
    loggingAlwaysWarn: true,
    loggingAlwaysError: true,
    silentMode: false,
    sessionLength: null,
  },
  user: {
    guestMode: true,
    user_id: "",
    ciam_id: null,
    country: null,
    cart_id: null,
  },
};

let tracker = new MetaStreamIO(metastreamioConfig);

export const setAnalyticsAppId = (iso3Region) => {
  tracker.app_id = `mtn-play-${iso3Region}`.toLowerCase();
};
 
export default tracker;

  
