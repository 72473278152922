import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

const TvModal = ({ show, children }) => {
	const [isBrowser, setIsBrowser] = useState(false);
  
	useEffect(() => {
	  setIsBrowser(true);
	}, []);
  
	const modalContent = show ? (
	  <StyledModalOverlay>
			<StyledModal>
		  {/* {title && <StyledModalTitle>{title}</StyledModalTitle>}
		  {titleBorder && <StyledBorder></StyledBorder>} */}
		  <StyledModalBody>{children}</StyledModalBody>
			</StyledModal>
	  </StyledModalOverlay>
	) : null;
  
	if (isBrowser) {
	  return ReactDOM.createPortal(
			modalContent,
			document.getElementById("modal-root")
	  );
	} else {
	  return null;
	}
};
  
const StyledModalBody = styled.div`
	padding: 24px;
	padding-top: 0px;
  `;

export const StyledBorder = styled.div`
	border: 1px solid #C8C8C8;
  `;
  
const StyledModal = styled.div`
	background: black;
	width: 99%;
	height: 100%;
	border-radius: 0px;
	*{
		-ms-overflow-style: none;
	  }
	  ::-webkit-scrollbar {
		display: none;
	  }
  `;
const StyledModalOverlay = styled.div`
	position: fixed;
	z-index: 4;
	top: 0;
	left: 0;
	min-width: 100vw;
	max-width: 100vw;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);

		&::-webkit-scrollbar {
			display: none;
		}
  `;
  
export default TvModal;