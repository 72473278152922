import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledButton = styled(motion.div)`
    max-height: ${({theme}) => theme?.heights[3] ?? "48px"};
    /* max-width: ${({theme}) => theme?.widths[1] ?? "192px"}; */
    min-width: 120px;
    padding: 16px 24px;
    height: 40px;
    font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
    font-style: normal;
    font-weight: 700;
    font-size: ${({theme}) => theme?.size?.f3 ?? "12px"};
    line-height: 14px;
    display: flex;
    flex-direction: ${({isFullscreen}) => !isFullscreen ? "row" : "column"};
    margin-bottom: ${({isFullscreen}) => isFullscreen ? "24px" : "0"};
    align-items: center;
    text-transform: uppercase;
    justify-content: center;
    text-align: center;
    background-color: ${({isOutline}) => isOutline ? "transparent" : "#FFCB05"};
    border: 1px solid;
    border-radius: 50px;
    color: ${({isOutline}) => isOutline ? "#FFCB05" : "#000"};
    text-transform: uppercase;
    text-decoration: none !important;
`;



