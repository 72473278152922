import styled from "styled-components";
import {motion} from "framer-motion";

export const StyledView = styled(motion.div)`
 margin: 24px;

 .title {
     
 }
 .content {
     flex: 1;
     display: flex;
     justify-content: space-between;
     margin-left: 24px;
 }
.menu-item {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    font-family: MTNBrighterSansRegular;
    min-height: 96px;
}

.title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
}

.price {
    font-size: 12px;
    font-weight: 700;
}

.expiry {
    font-style: italic;
    font-size: 10px;
}

.menu-icon {
   background-image: url('/images/icons/right_chevron.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  
}

.sub-icon {
   background-image: ${props => props.imageUrl ? `url(${props.imageUrl})` : "url(/images/icons/right_chevron.svg)"}
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid blue;
    height: 48px;
    width: 48px;
}

.icon {
    /* margin: ${({theme}) => theme?.spacing?.f4 ?? "12px"}; */
    height: 20px;
    width: 20px;
}

.menu-details {
    display: flex;
    flex-direction: column;
}
	  
.description {
  font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

`;

export default StyledView;