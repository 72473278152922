import { StyledClosestSearchResultCard } from "./ClosestSearchResultCard.styles";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import HelperFunctions from "../../../utils/HelperFunctions";
import EllipsisText from "react-ellipsis-text/lib/components/EllipsisText";

//TODO:
const ClosestSearchResultCard = ({
  item,
  onClick,
  isVertical,
  isSearchResult,
}) => {
  const { language } = useSelector((state) => state);

  let genre = "";

  const icon = new HelperFunctions().sslIcon(item?.vendor?.logo);

  if (
    item?.genre !== null &&
    item?.genre !== undefined &&
    item?.genre.length !== 0
  ) {
    let index = 0;
    item?.genre?.forEach((mGenre) => {
      if (index === 0) {
        genre = mGenre;
      } else {
        genre = genre + " · " + mGenre;
      }

      index++;
    });
  }

  const data = {
    title: item?.title,
    desc: item?.description,
    age: item?.age,
    genre: `${genre}`,
  };

  const location = useLocation();

  return (
    <StyledClosestSearchResultCard
      isVertical={isVertical}
      isSearchResult={isSearchResult}
      id={`key${item?.itemType}${item?.title}`}
      item={item}
      className="item"
      onClick={onClick}>
      <div id="item@home" style={{ width: 150, position: "relative" }}>
        <div className="thumbnail">
          {item.subscriptionType === "subscription" &&
            item?.subscribed !== "Pending" &&
            item?.subscribed !== "Active" &&
            location.pathname !== "/myplay" && (
              <div className="tag-description-sub">
                {
                  language?.chosenPack?.language?.COMPONENTS?.content_cards
                    ?.subscription_type_subscribe
                }
              </div>
            )}
          {item.subscriptionType === "free" &&
            location.pathname !== "/myplay" && (
              <div className="tag-hdescription-free">FREE</div>
            )}
          {item.subscriptionType === "once-off" &&
            item.subscribed !== "Pending" &&
            item.subscribed !== "Active" &&
            location.pathname !== "/myplay" && (
              <div className="tag-description-once">BUY</div>
            )}
          {(item.subscriptionType === "subscription" ||
            item.subscriptionType === "once-off" ||
            item.subscriptionType === "free") &&
            item.subscribed === "Pending" &&
            location.pathname !== "/myplay" && (
              <div className="tag-description-pending">ACTIVATING</div>
            )}

          {(item.subscriptionType === "subscription" ||
            item.subscriptionType === "once-off" ||
            item.subscriptionType === "free") &&
            item.subscribed === "Active" &&
            location.pathname !== "/myplay" && <></>}
        </div>
        <div className="tag">
          <img alt="" height="11" src={icon} />
        </div>
      </div>
      <div style={{ display: "flex", gap: 12, flexDirection: "column" }}>
        <p className="title">{data?.title}</p>
        <div className="desc">
          <EllipsisText text={data?.desc} length={155} />
        </div>
        <div className="genre">
          {`${
            data?.genre === "Drama"
              ? language?.chosenPack?.language?.list_of_genres?.genre_drama
              : data?.genre
          }`}
        </div>
      </div>
    </StyledClosestSearchResultCard>
  );
};

export default ClosestSearchResultCard;
