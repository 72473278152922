import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledCategoryBar = styled(motion.div)`
  height: ${(props) => (props.isheighted ? heighted : "44px")};
  background-color: #ffcb05;
  padding-left: 12px;
  display: flex;
  align-items: center;
  overflow-x: auto;
  position: sticky;
  width: 100%;
  top: auto;
  color: #000;
  font-family: "MTN Brighter Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  .sub-cat-parent {
    display: inline-flex;
    align-items: center;
  }

  .sub-cat-body {
    width: 100%;
    display: contents;
  }

  .sub-cat-tail {
    position: absolute;
    background-color: #ffcb05;
    right: -1px;
    width: 43px;
    width: 50px;
    height: auto;
    padding: 0px;
    justify-content: center;
    height: 43px;
    align-items: center;
    display: flex;
  }

  .background {
    background-color: #00000000;
    font-family: MTN;
    text-transform: capitalize;
    font-style: normal;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    display: inline-flex;
  }

  .category {
    align-content: center;
    min-width: max-content;
    font-family: MTNBrighterSansRegular;
    padding-left: 8px;
    padding-right: 16px;

    p {
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .bar-with-icon {
    display: flex;
  }

  .sub-icon {
    background-image: url("/images/icons/new_arr_down.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 13.33px;
    height: 8px;
    margin-top: 1px;
  }

  .cancel-sub-icon {
    background-image: url("/images/icons/new_cancle_btn.svg");
    background-position: center;
    background-repeat: no-repeat;
    right: 20px;
    width: 11px;
    height: 24px;
  }

  .icon {
    /* margin: ${({ theme }) => theme?.spacing?.f4 ?? "12px"}; */
    height: 20px;
    width: 20px;
  }
`;