import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {PWA_ROUTES} from "../../constants/appConstants";
import {Page, SeriesItemInfo} from "../../shared/components";
import {ItemSelected} from "../../HOC";
import { StyledContentTopNav } from "./StyledSeriesContentScreen.styles";
import { useNavigate } from "react-router-dom";
import { subscriptions as subAction} from "../../utils/redux/actions";

const SeriesContentScreen = () => {
  
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {subscriptions} = useSelector((state) => state);
	const selectedItem = subscriptions?.selectedItem;
	const [seriesData, setSeriesData] = useState(null);

	useEffect(()=>{
		const data = {s:0,e:[], info:{}};
		data.s = selectedItem.Collection.length;
		data.info = selectedItem;
		selectedItem.Collection.forEach((season)=>{
			data.e.push(season.listing);
		});
		setSeriesData(data);
	}, []);

	const closeScreen = () => {
		dispatch(subAction.selectItem(null));

		navigate(PWA_ROUTES.HOME);
	};

	const ContentTopNav = () =>{
		let genre = "";
		seriesData?.info.genre.map((item, index)=>{
			if(index==0){
				genre= item;
			}else{
				genre = genre+" · "+genre;
			}
		});

		return <StyledContentTopNav image={seriesData?.info?.imageUrl}>
			<div className="parent_box">

				<div className="box">
					<div className="header">
						<p className="title">{`${seriesData?.info?.title}`}</p>
						<p className="info">{`${genre}`}</p>
					</div>

					<div className="close" onClick={()=>closeScreen()}/>
				</div>

			</div>
		</StyledContentTopNav>;
	};

	return (
		<ItemSelected probation={true}>
			<Page isBottomBar={true}>
				<ContentTopNav />
				{seriesData!=undefined?<SeriesItemInfo seriesData={seriesData} />:null}
			</Page>
		</ItemSelected>
	);
};

export default SeriesContentScreen;