// import React from "react";
import { StyledRadioButton } from "./RadioButton.styles";

const RadioButton = ({
  clickHandler,
  selected,
  body,
  isActive = true,
  style = {},
}) => {
  return (
    <StyledRadioButton
      isActive={isActive}
      isChecked={selected}
      onClick={clickHandler}
      style={style}>
      <div id="icon">
        <div id="check" />
      </div>
      <div id="title_parent">
        <span id="title">{`${body}`}</span>
      </div>
    </StyledRadioButton>
  );
};

export default RadioButton;
