import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledItemInfo = styled(motion.div)`
  min-height: 333px;
  display: flex;
  font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
  flex-direction: column;
  padding: 0px 24px;
  margin-bottom: 64px;
  width: 100%;
  .metadata {
	  display: inline;
	  flex-direction: column;
      margin-bottom: 24px;
	  
	  .description {
		font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		margin-bottom: 24px;
	  }

	  .read-or-hide{
		color: #FFCB05;
	  }
}
`;