import {useState} from "react";
import { StyledItemInfo } from "./SeriesItemInfo.styles";
import SeasonTab from "./SeasonTab";

const SeriesItemInfo = ({seriesData}) => {

	const [selectedSeason, setSelectedSeason] = useState(1);
	const [isReadMore, setIsReadMore] = useState(true);

	const handleSelectedTab = (season) =>{
		setSelectedSeason(season);
	};

	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};

	const ReadMore = ({text}) => {		
		const MoreOrLess=()=>{
			return<span style={{color:"#FFCB05", position: "absolute", display: "contents"}} onClick={toggleReadMore} className="read-or-hide">
				{(text.length>90) ? (isReadMore ? " ... more" : " ... less") :  <></>}
			</span>;
		};
	
		return (
			<p className="metadata">
				{isReadMore ? text.slice(0, 90) : text}
				<MoreOrLess/>
			</p>
		);
	};

	return (
		<StyledItemInfo>
			<ReadMore text={seriesData?.info?.description}/>
			<SeasonTab seriesData={seriesData} onSelectedTab={(season)=>{handleSelectedTab(season);}} selectedSeason={selectedSeason}/>
		</StyledItemInfo>
	);
};

export default SeriesItemInfo;