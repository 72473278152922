/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useSelector } from "react-redux";
// import { PartnerInfo } from "..";
import { Service } from "..";
import { StyledItemInfo } from "./ItemInfo.styles";
import { SocialButtons } from "../SocialButtons";


const ItemInfo = () => {
  const selectedItem = useSelector(
    (state) => state?.subscriptions?.selectedItem,
  );
  const { language } = useSelector((state) => state);
  // const [isReadMore, setIsReadMore] = useState(true);
  // const toggleReadMore = () => {
  // 	setIsReadMore(!isReadMore);
  // };

  const service = selectedItem?.service; 
  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="metadata">
        {isReadMore ? text?.slice(0, 100) : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {text?.length > 100 ? isReadMore ? language?.chosenPack?.language?.COPY?.SCREENS?.contentInfo?.more : language?.chosenPack?.language?.COPY?.SCREENS?.contentInfo?.less : <></>}
        </span>
      </p>
    );
  };

  return (
    <StyledItemInfo>
      <ReadMore>{selectedItem?.detailedDescription}</ReadMore>
      <SocialButtons />

      {/* <PartnerInfo item={selectedItem} /> */}
      <Service
        id={service?.id}
        logo={service?.logo}
        name={service?.name}
        description={service?.description}
      />
    </StyledItemInfo>
  );
};

export default ItemInfo;


