// import React from "react";
import { useSelector } from "react-redux";
import { ExistingSubscriptionInfo, Banner, Border, LandingCategory, Page, TopNav } from "../../shared/components";

const SubscriptionFailScreen = () => {

	// const location = useLocation();

	const {items} = useSelector(state => state?.items);
	// const {selectedItem, selectedCharge} = useSelector(state => state.subscriptions);
	const mightLike = items?.filter(item => item.name === "might-like")[0];
	
	return (
		<Page isBottomBar={true}>
			<TopNav title="payment unsuccessfull" />
			<Banner />
			{/* <BalanceInfo isFromTopUp={true} /> */}
			<ExistingSubscriptionInfo info={"There was an error completing your transaction."} />
		
			{/* <PartnerSubscriptionButtons backRoute={PWA_ROUTES.PAYMENTS} confirmRoute={PWA_ROUTES.TOPUP} backButtonText="payment options" acknowledgeButtonText="top up" /> */}
			<Border />
			<LandingCategory
				viewMoreAction={mightLike?.viewMoreAction}
				title={mightLike?.name}
				items={mightLike?.data}
			/>
		</Page>
	);
};

export default SubscriptionFailScreen;
