import { PUSH_NOTIFICATION } from "../constants/appConstants";

export default function InitialiseNotification(msisdn, call){
	let _msisdn = msisdn;
	let _call = call;
	let extractedNotificationPayload = sessionStorage.getItem(PUSH_NOTIFICATION.STORAGE_KEY);

	const clean = ()=>{
		_call = undefined;
		_msisdn = undefined;
		extractedNotificationPayload = undefined;
	};

	const subscribe = () => {
		const payload = {
      ...JSON.parse(extractedNotificationPayload),
      msisdn: _msisdn,
    };
		_call?.subscribePush(payload).then(res=>{
			console.log("Subscribe Notification Response: ", res);
		}).catch(exception=>{
			console.log("Subscribe Notification Exception: ", exception);
		}).finally(()=>{
			clean();
		});
	};

	return subscribe();
}