import { language } from "../types";

import  englishLan  from "../../../languagePack/english.json";
import  frenchLan  from "../../../languagePack/french.json";
import arabicLan from "../../../languagePack/arabic.json";

const initialState = {
  chosenLanguage: {
    code: "",
    name: "",
  },
  chosenPack: englishLan,
  isLanguageModalOpen: false,
};

const languageReducer = (state = initialState, action) => {
  let packHolder = action.payload;
  if (action.payload && action.payload.name === "english") {
    packHolder = englishLan;
  }
  if (action.payload && action.payload.name === "french") {
    packHolder = frenchLan;
  }
  if (action.payload && action.payload.name === "arabic") {
    packHolder = arabicLan;
  }
  switch (action.type) {
    case language.SET_LANGUAGE_SUCCESS:
      return {
        ...state,
        chosenLanguage: action.payload,
        chosenPack: packHolder,
      };
    case language.TOGGLE_LANGUAGE_MODAL:
      return {
        ...state,
        isLanguageModalOpen: action.payload,
      };

    default:
      return state;
  }
};

export default languageReducer;