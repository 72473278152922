import { Link } from "react-router-dom";
import { PWA_ROUTES } from "../../../constants/appConstants";
import { StyledPartnerSubscriptionButtons } from "./PartnerSubscriptionButtons.styles";

const PartnerSubscriptionButtons = ({acknowledgeButtonText,confirmRouteHandler, backButtonText, confirmRoute, backRoute}) => {
	return (
    <StyledPartnerSubscriptionButtons>
      <Link
        to={backRoute ? backRoute : PWA_ROUTES.CONTENT_INFO}
        style={{ textDecoration: "none", color: "#ffff" }}>
        <div className="btn">{backButtonText || "Back"}</div>
      </Link>
      <Link
        onClick={confirmRouteHandler}
        to={confirmRoute ? confirmRoute : "#"}
        style={{ textDecoration: "none", color: "#000" }}>
        <div className="outline-btn">
          {acknowledgeButtonText || "Subscribe"}
        </div>
      </Link>
    </StyledPartnerSubscriptionButtons>
  );
};

export default PartnerSubscriptionButtons;
