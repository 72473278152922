import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledActiveSubscriptionInfo = styled(motion.div)`

 padding: 24px;

 .info {
	font-family: MTNBrighterSansRegular;
font-style: normal;
font-weight: normal;
font-size: 10px;
line-height: 12px;
display: flex;
align-items: center;
 }
`;