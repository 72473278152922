import {useRef, useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
	Page,
} from "../../shared/components";
import {ItemSelected} from "../../HOC";
import { useNavigate } from "react-router-dom";
import { PlayPlayer } from "../PlayPlayer";
import { MyPlayScreen as events} from "../../analytics/events";
import { getRedirect} from "../../utils/redux/actions/items.actions";

// import ShakaPlayer from 'shaka-player-react';
// import 'shaka-player/dist/controls.css';
import logo from "../../assets/images/MTNPlayLogo.png";
import styled from "styled-components";

const ContentPlayer = () => {

	const {
		redirectUrlObj
	} = useSelector((state) => state?.items);

	const ref = useRef(null);
	// const devicePlatformType = window.navigator.userAgent;

	const {network} = useSelector((state) => state);

	const {subscriptions, appState} = useSelector((state) => state);
	const [setWatchFreeVideo] = useState(false);
	const [setIsPlaying] = useState(false);
	const [setRedirectUrl] = useState("");
  

	const selectedItem = subscriptions?.selectedItem;

  
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// const mediaTypeIsAudio = false;

	const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

	const getMobileOperatingSystem = ()=>{
		var userAgent = navigator.userAgent || navigator.vendor || window.opera;

		// Windows Phone must come first because its UA also contains "Android"
		if (/windows phone/i.test(userAgent)) {
			return "Windows Phone";
		}

		if (/android/i.test(userAgent)) {
			return "Android";
		}

		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return "iOS";
		}

		return "unknown";
	};

	const closeVideoPlayer = () =>{
		setIsPlaying(false);
		setWatchFreeVideo(false);
		navigate(-1);
	};

	useEffect(()=>{
		if(!selectedItem.internalPlay && selectedItem.subscribed != "Inactive" && selectedItem.subscribed != "0000") {
			dispatch(getRedirect(network?.call, selectedItem?.billingTypes ? selectedItem?.billingTypes[0] : selectedItem?.billingType ));
		}

		dispatch({
    
			type: "ANALYTICS/TRACK_EVENT",
			event: events.content_player_screen(
				network?.call?.headers?.isoRegion,
				network?.call?.headers.environment,
				selectedItem?.itemType,
				selectedItem?.title,
				selectedItem?.contentType,
				selectedItem?.vendorId,
			),
		});
	},[]);

	useEffect(()=>{
		if(redirectUrlObj !== null) {

			setRedirectUrl(redirectUrlObj.redirect_to);
		}

	},[redirectUrlObj]);



	useEffect(()=>{
		if(ref.current==null) return;
	},[ref]);

	return (
		<ItemSelected probation={true}>




			<Page isBottomBar={false}>
				{getMobileOperatingSystem()=="iOS" && !isChrome ?<p>{"Browser not supported, kindly follow link to get a supported browser."}<br/> <a href="https://apps.apple.com/us/app/google-chrome/id535886823">Proceed</a></p>:
					<PlayPlayer deviceType={getMobileOperatingSystem()} 
						source={appState?.selectedMedia?.url}
						title={appState?.selectedMedia?.episodeTitle ? appState?.selectedMedia?.episodeTitle : appState.selectedMedia?.title}
						contentType={appState?.selectedMedia?.contentType}
						imageUrl={appState?.selectedMedia?.imageUrl}
						onClose={()=>closeVideoPlayer()}/>}
			</Page>

			{!selectedItem.internalPlay &&

<div >

	<Loader>

		<div style={{ width: "40"}}>
			<img src={logo} alt=""  height="40" />
			<img src={selectedItem?.vendor?.logo} alt="" height="40" />
		</div>
	</Loader>

	<div style={{ display:"flex", justifyItems:"center", justifyContent:"center", marginTop:30}}>

		<p style={{ color:"white", justifyItems:"center"}}> Press to redirect &nbsp;  to  <span style ={{color:"#FFCB05", fontWeight:"bolder"}}>{selectedItem?.vendor?.title}</span></p>

	</div>



</div>

			}


		</ItemSelected>
	);

};

const Loader = styled.div`
display: flex;
padding: 14px;
justify-content: center;
padding-top: 40%;
`;

export default ContentPlayer;