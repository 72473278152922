import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledItemInfo = styled(motion.div)`
    .metadata{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px 24px 12px;
        font-family: 'MTNBrighterSansRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;        
        letter-spacing: 0.444444px;
        
        color: rgba(255, 255, 255, 0.75);
        p{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 10px;
            
        }
    }
}
`;