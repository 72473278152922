import { filteredSections } from "../types";

export const fetchItemsFilterSectionByCategory = (query, isSubscription, subscriptionsList=[], purchaseList=[]) => async(dispatch) => {
	try {
		const mQuery = `${query}`.trim();
		const list = isSubscription ? subscriptionsList.Active : purchaseList.Active;

		if(mQuery.length === 0 || list.length===0) 
			return dispatch({type: filteredSections.FILTERED_ITEMS_SECTION_SUCCESS, payload: null});

		const filterFunctionStartsWith = (item)=>{
			return `${item.title}`.toLocaleLowerCase().startsWith(`${mQuery}`.toLocaleLowerCase());
		};

		const filterFunctionContains = (item)=>{
			return `${item.title}`.toLocaleLowerCase().includes(`${mQuery}`.toLocaleLowerCase());
		};

		const filterItems = (_item, level)=>{
			const subItems = {..._item};
            
			if(!subItems.data || subItems.data.length===0) return [];

			subItems.data=_item.data.filter(level === 1 ? filterFunctionStartsWith : filterFunctionContains);
			return subItems;
		};

		let searchResult = list.map(item=>filterItems(item, 1)).filter(_item=> _item.length === undefined); //1 stands for search level 1, also _item without length is an object else filter out empty array.

		const isNotEmpty = searchResult.some((item)=> item.data.length > 0);

		//Here is another later of search incase no result was found with the first critaria
		if(!isNotEmpty) searchResult = list.map(item=>filterItems(item, 2)).filter(_item=> _item.length === undefined); //2 stands for search level 2 when 1 could not get any searchResult
		//Here Ends another layer of filter pattern, it can be removed if not neccessary.

		const result = {subscriptionObj:[], onceOffObj:[]};
		if(isSubscription)
			result.subscriptionObj = searchResult;
		else
			result.onceOffObj = searchResult;

		dispatch({
			type: filteredSections.FILTERED_ITEMS_SECTION_SUCCESS,
			payload: result
		});

	} catch (error) {
		dispatch({
			type: filteredSections.FILTERED_ITEMS_SECTION_ERROR,
			payload: {
				message: error?.message,
				error
			}
		});
	}
};