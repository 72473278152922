import { recommendations } from "../types";

const initialState = {
	loading: false,
	recommendations: {},
	error: null,
	fetchSucess: false,
};

const recommendationsReducer = (state = initialState, action) => {
	switch (action.type) {
	case recommendations.FETCH_RECOMMENDATIONS_REQUEST:
		return {
			...state,
			loading: true,
			error: null,
			fetchSucess: false
		};
	case recommendations.FETCH_RECOMMENDATIONS_SUCCESS:
		return {
			...state,
			loading: false,
			error: null,
			recommendations: action.payload.response,
			fetchSucess: true
		};
	case recommendations.FETCH_RECOMMENDATIONS_ERROR:
		return {
			...state,
			loading: false,
			error: action.payload,
			fetchSucess: false
		};
	default:
		return state;
	}
};

export default recommendationsReducer;