import styled from "styled-components";
import {motion} from "framer-motion";

export const StyledContentTopNav = styled(motion.div)`
   background-color:  #111111;
   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
   height: auto;
   display: flex;
   position: relative;
   top: 0;
   z-index: 2;
   margin-bottom: 24px;
   
   .box{
      width: 100%;
      height: 100%;            
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
      background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.75) 29.69%, rgba(0, 0, 0, 0) 45.83%, rgba(0, 0, 0, 0) 90.62%, rgba(0, 0, 0, 0.5) 99.99%, #000 100%);
      border-radius: 0px 0px 8px 8px;
  }

   #title {
	   font-size: ${({theme}) => theme?.size?.f6 ?? "18px"};
	   font-style: normal;
	   font-weight: 700px;
	   line-height: 23.4px;
	   text-transform: capitalize;
   }

   .icons {
	   display: flex;
   }

   .icon {
	   margin: ${({theme}) => theme?.spacing?.f4 ?? "12px"};
   }

   .brand-icon {
	 min-height: 30px;
    max-height: 30px;

    min-width: 38px;
    max-width: 38px;
   }

   .play-logo {
	   background-image: url('/images/icons/play.png');
	   background-position: center;
       background-repeat: no-repeat;
       background-size: contain;
   }

   .brand {
	   display: flex;
	   align-items: center;
   }

   .search {
      background-image: url('/images/icons/search.svg');
	   background-position: center;
       background-repeat: no-repeat;
       background-size: cover;
   }

   .settings {
      background-image: url('/images/icons/settings.svg');
	   background-position: center;
       background-repeat: no-repeat;
       background-size: cover;
   }

   .search-input {
      background: none;
      border: none;
      font-family: MTN;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
      display: flex;
      align-items: center;

      color: #FFFFFF;
   }

   .close {
		background-image: url('/images/icons/close.svg');
		background-position: top left;
		background-repeat: no-repeat;
		background-size: cover;
		align-self: flex-start;
		justify-self: flex-start;
	}
`;