import { AppBar } from "../../shared/components/ComingSoon/AppBar";
import { Body } from "../../shared/components/ComingSoon/Body";
import { Footer } from "../../shared/components/ComingSoon/Footer";
import { Wrapper } from "../../shared/components/ComingSoon/Wrapper";
import {
  ButtonLink,
  CallToActionText,
  HeadLine,
  SupportingText,
} from "../../shared/components/ComingSoon/Text";

const ComingSoonScreen = () => {
  return (
    <Wrapper>
      <AppBar />
      <Body>
        <HeadLine>Coming Soon</HeadLine>
        <SupportingText>It’s going to be amazing!</SupportingText>
        <CallToActionText>Already signed up?</CallToActionText>
        <ButtonLink> Click here to sign in.</ButtonLink>
      </Body>
      <Footer />
    </Wrapper>
  );
};

export default ComingSoonScreen;
