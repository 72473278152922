import {useEffect, useState} from "react";

import {useSelector, useDispatch} from "react-redux";
import {
	Page,
	TopNav,
	LandingCategory,
} from "../../shared/components";
import {fetchAllSubscriptions,fetchAllPurchases} from "../../utils/redux/actions/subscription.action";
import {fetchItemsFilterSectionByCategory} from "../../utils/redux/actions/filteredSections.actions";
import { ProgressLoader } from "../../shared/components/ProgressLoader";
import styled from "styled-components";
import { StyledBottomPopup } from "../../shared/components/BottomBar/popup";
import close from "../../assets/images/close.png";
import {MyPlayScreen as events} from "../../analytics/events";
// import DirectionProvider, { DIRECTIONS } from "react-with-direction/dist/DirectionProvider";
import {MyplayTabs} from "../../shared/components/TabbedList/MyplayTabs";
import {StyledTabbedList} from "../../shared/components/TabbedListPlay/TabbedListPlay.styles";

const MyPlayScreen = () => {
	const dispatch = useDispatch();

	const [chosenTab, setChosenTab] = useState("SUBSCRIPTIONS");
	const { network,language} = useSelector((state) => state);
	const {items, appState} = useSelector((state) => state);
	const {filteredSections} = useSelector((state) => state);
	const [showMessageModal, setShowMessageModal] = useState(false);

	const [modalPending, setModalPending] = useState(false);
	// const [setModalActive] = useState(false);

	const {
		subscriptionsList,

		purchaseList,
		loadingAllsubcription,
		pendingItem
	} = useSelector((state) => state?.subscriptions);

	const [isSubscriptionCaterogy] = useState(true);
	const [query, setQuery] = useState("");
	// const [setModal] = useState(false);

	const emptyFilteredSections = (Object.keys(filteredSections).length === 0);
  
	// const modalSwich = () =>{
	// 	setModal(false);
	// 	localStorage.setItem("user-closed", true);
	// };
  
	const switchPendingState = () =>{
		localStorage.setItem("pending-state-closed", true);
		setModalPending(false);
	};

	useEffect(() => {
		if((network?.call?.headers?.region !== null  || network?.call?.headers?.region !== undefined || network?.call?.headers?.region !== "")) {
			dispatch(fetchAllSubscriptions(network?.call));
			dispatch(fetchAllPurchases(network?.call));
		}
	}, [network]);

	useEffect(() => {
    dispatch({
      type: "ANALYTICS/TRACK_EVENT",
      event: events.myplay_screen(
        network?.call?.headers?.isoRegion,
        network?.call?.headers.environment,
      ),
    });

    items?.items?.map((res) => {
      if (res?.name === pendingItem?.itemType) {
        res?.data?.map((response) => {
          response?.billingTypes?.map((ress) => {
            if (ress?.subscriptionId === pendingItem?.subscriptionId) {
              if (response?.subscribed === "Active") {
                // setModalActive(true);
                setModalPending(false);
              } else if (response?.subscribed === "Pending") {
                if (localStorage?.getItem("pending-state-closed") !== null) {
                  setModalPending(false);
                } else {
                  setModalPending(true);
                }
                // setModalActive(false);
              } else if (response?.subscribed === "Inactive") {
                //setModalPending(false)
              }
            }
          });
        });
      }
    });
  }, []);

  useEffect(() => {
    doSearch();
  }, [query]);

  useEffect(() => {
    items?.items?.map((res) => {
      if (res?.name === pendingItem?.itemType) {
        res?.data?.map((response) => {
          response?.billingTypes?.map((ress) => {
            if (ress?.subscriptionId === pendingItem?.subscriptionId) {
              if (response?.subscribed === "Active") {
                // setModalActive(true);
                setModalPending(false);
              } else if (response?.subscribed === "Pending") {
                if (localStorage.getItem("pending-state-closed") !== null) {
                  setModalPending(false);
                } else {
                  setModalPending(true);
                }
                // setModalActive(false);
              } else if (response?.subscribed === "Inactive") {
                //setModalPending(false)
              }
            }
          });
        });
      }
    });
  }, [pendingItem]);


	useEffect( () => {
		//filteredSections
	}, [subscriptionsList]);



	const doSearch =()=> {
		dispatch(fetchItemsFilterSectionByCategory(query, isSubscriptionCaterogy, subscriptionsList, purchaseList));
	};

	const renderCategoriesSub = (offers) => {
		const _offers = offers?.filter(_item=> _item?.data !== null && _item?.data?.length >0);

		if(emptyFilteredSections && _offers?.length === 0 ) return <EmptyPage><div style={{paddingLeft:"20px", paddingRight:"20px", textAlign:"center"}}>{language?.chosenPack?.language?.COPY?.SCREENS?.play?.no_subscrition_alert}</div></EmptyPage>;

		if(_offers?.length === 0) return <EmptyPage>{"Oops! Search Result not Found..."}</EmptyPage>;

		if(loadingAllsubcription) {
			return (
				<ProgressLoader title={"Message"} showMessageModal={showMessageModal} errorMessage={items?.error?.message} timedOut={!items?.loading} altMessage={"Loading Content ..."} shouldClose={()=>{setShowMessageModal(false);}}/>
			);
		}

		return _offers?.map((offer) => {
			if(offer?.data) {
				return (
					<LandingCategory
						key={offer?.name}
						viewMoreAction={offer?.viewMoreAction}
						title = {offer?.name === "movies"? language?.chosenPack?.language?.COPY?.SCREENS?.home?.category_description_movie
							: offer?.name === "videos"? language?.chosenPack?.language?.COPY?.SCREENS?.home?.category_description_video : offer?.name }
						items={offer?.data}
						appPreState={appState}
					/>
				);
			}

		});
	};

	return (
    <>
      {/* <DirectionProvider direction={language?.chosenLanguage === "arabic" ? DIRECTIONS.RTL:DIRECTIONS.LTR}> */}

      <Page isBottomBar={true}>
        {modalPending && (
          <StyledBottomPopup>
            <p style={{ fontSize: 14 }}>{language?.chosenPack?.language?.COMPONENTS.success_alert}.</p>
            <img
              onClick={() => switchPendingState()}
              alt=""
              width="19"
              height="19"
              src={close}
            />
          </StyledBottomPopup>
        )}
        <TopNav
          title={
            language?.chosenPack?.language?.COPY?.SCREENS?.play?.screen_title
          }
          enableSearch={false}
          searchInputCallBack={setQuery}
        />

        <StyledTabbedList>
          <MyplayTabs
            toggleTabs={setChosenTab}
            tabs={[
              language?.chosenPack?.language?.COPY?.SCREENS?.play?.tab_sections
                .subscription,
            ]}
            //tabs={[language?.chosenPack?.language?.COPY?.SCREENS?.play?.tab_sections.subscription, language?.chosenPack?.language?.COPY?.SCREENS?.play?.tab_sections.purchases]}

            //  events={tab_events}
          />

          <p style={{ padding: 22 }}>{language?.chosenPack?.language?.COPY?.SCREENS?.play?.screen_heading}</p>
          <div>
            {chosenTab === "SUBSCRIPTIONS"
              ? renderCategoriesSub(
                  emptyFilteredSections
                    ? subscriptionsList?.Active
                    : filteredSections?.subscriptionObj,
                  "SUBSCRIPTIONS",
                )
              : chosenTab === "PURCHASES"
              ? renderCategoriesSub(
                  emptyFilteredSections
                    ? purchaseList?.Active
                    : filteredSections?.onceOffObj,
                  "PURCHASES",
                )
              : ""}
          </div>
        </StyledTabbedList>
      </Page>
      {/* </DirectionProvider> */}
    </>
  );
};

const EmptyPage = styled.div`
height: 200px;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
`;

export default MyPlayScreen;