import styled from "styled-components";
import {motion} from "framer-motion";

export const StyledTopNav = styled(motion.div)`
  background-color: #000;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 3;

  #title {
    font-size: ${({ theme }) => theme?.size?.f6 ?? "18px"};
    font-style: normal;
    font-weight: 700px;
    line-height: 23.4px;
    text-transform: capitalize;
  }

  .icons {
    display: flex;
  }

  .icon {
    margin: ${({ theme }) => theme?.spacing?.f4 ?? "12px"};
  }

  .brand-icon {
    min-height: 30px;
    max-height: 30px;

    min-width: 38px;
    max-width: 38px;
  }

  .play-logo {
    background-image: url("/images/icons/play.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .back {
		background-image: url('/images/icons/back.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
	}


  .brand {
    display: flex;
    align-items: center;
  }

  .search {
    background-image: url("/images/icons/search.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .settings {
    background-image: url("/images/icons/settings.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .search-input {
    background: none;
    border: none;
    font-family: MTNBrighterSansRegular;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;

    color: #ffffff;
  }

  .close {
    background-image: url("/images/icons/close.svg");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
    justify-self: flex-start;
  }
`;