// import React from "react";
import { Border } from "../..";
import { PWA_ROUTES } from "../../../../constants/appConstants";
import { StyledSubMenuItem } from "./SubMenuItem.styles";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectItem } from "../../../../utils/redux/actions/subscription.action";
import HelperFunctions from "../../../../utils/HelperFunctions";
import { SELECTED_SOURCE } from "../../../../appConstants";

const SubMenuItem = ({ item, chosenTab, disabled = false }) => {
  const { language } = useSelector((state) => state);
  const dispatch = useDispatch();
  const doSetSelectedItem = (selectedItem) => {
    dispatch(selectItem(selectedItem, SELECTED_SOURCE.CONTENT));
  };
  const ROUTE =
    chosenTab === "Active"
      ? PWA_ROUTES.ACTIVE_SUBSCRIPTIONS
      : chosenTab === "InActive"
      ? PWA_ROUTES.INACTIVE_SUBSCRIPTIONS
      : PWA_ROUTES.PENDING_SUBSCRIPTIONS;
  const isDaily = `${item.subscription}`.toLowerCase() === "day";
  const icon = new HelperFunctions().sslIcon(item?.service?.logo);

  return (
    <StyledSubMenuItem item={item} icon={icon}>
      <Link
        onClick={() => {
          disabled === false ? doSetSelectedItem(item) : null;
        }}
        key={item?.package}
        to={ROUTE}>
        <div className="menu-item">
          <div className="sub-icon"></div>
          <div className="content">
            <div className="menu-details">
              <div className="title">{item?.service?.name}</div>
              <div className="price">{`GH₵ ${item?.amount} per ${item?.subscription}`}</div>
              {item?.billingType?.status === "Pending" && (
                <span style={{ fontSize: 10 }}>
                  <i>Pending Subscription</i>
                </span>
              )}
              {item?.billingType?.status !== "Pending" &&
                item?.billingType?.status === "Active" && (
                  <span className="expiry">
                    {item?.vendor?.selfManage?.length > 1
                      ? item?.renews?.length > 1
                        ? `Manage on ${item?.service?.name}`
                        : `Manage on ${item?.service?.name}`
                      : item?.renews?.length > 1
                      ? isDaily
                        ? `${language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections_content?.subscriptions?.renews} ${item?.renews}`
                        : `${language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections_content?.subscriptions?.renews} ${item?.renews}`
                      : `Paused on ${item?.paused}`}
                  </span>
                )}
            </div>
          </div>
        </div>
        <Border />
      </Link>
    </StyledSubMenuItem>
  );
};

export default SubMenuItem;
