import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledRadioButton = styled(motion.div)`
  width: 100%;
  margin-bottom: 16px;
  font-family: ${({ theme }) => theme?.fonts[0] ?? "MTN"};
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme?.size?.f3 ?? "12px"};
  line-height: 14px;
  display: flex;
  gap: 8px;
  text-transform: uppercase;
  background-color: #424242;
  border-radius: 8px;
  color: #b3b3b3;
  text-transform: uppercase;
  text-decoration: none !important;
  letter-spacing: 0.25px;
  padding-left: 8px;

  #title_parent {
    display: flex;
    align-items: center;

    #title {
      text-transform: capitalize;
      font-family: "MTN";
      font-size: 14px;
    }
  }

  #icon {
    align-items: center;
    display: flex;
    #check {
      background-image: ${({ isChecked }) =>
        isChecked
          ? "url(/images/icons/radio_button_active.svg)"
          : "url(/images/icons/radio_button_inactive.svg)"};
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 16px;
      height: 16px;
    }
  }
`;
