import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledExistingSubscriptionInfo= styled(motion.div)`

 padding: 32px;

 .info {
	font-family: MTNBrighterSansRegular;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 12px;
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
 }
`;