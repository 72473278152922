import { musicItems } from "../../mock";
import { subscriptions } from "../types";

const initialState = {
	loading: false,
	items: musicItems,
	isConfirmed: false,
	selectedItem: null,
	selectedCharge: null,
	subscriptions: [],
	subscriptionsList: [],
	purchaseList: [],
	purchases: [],
	msisdn: null,
	selectedPaymentMethod: null,
	paymentStatusInfo: null,
	paymentMethods: [],
	voucherTopupStatus: null,
	topupOptions: [],
	balances: null,
	error: null,
	loadingAllsubcription: false,
	loadingAllPurchases: false,
	buyloading: false,
	unsubscribe: "INITIAL_STATE",
	unsubRequest: false,
	pendingItem: null,
  consent: null,
};


const subscriptionsReducer = (state = initialState, action) => {
	switch (action.type) {
    case subscriptions.UN_SUBSCRIBE_ITEM_REQUEST:
      return {
        ...state,
        unsubRequest: true,
      };

    case subscriptions.UN_SUBSCRIBE_ITEM_SUCCESS:
      return {
        ...state,
        unsubscribe: "UNSUB_SUCCESS",
        unsubRequest: false,
      };
    case subscriptions.RESET_SUBSCRIPTION:
      return {
        ...state,
        unsubscribe: "INITIAL_STATE",
      };
    case subscriptions.UN_SUBSCRIBE_ITEM_ERROR:
      return {
        ...state,
        unsubscribe: "INITIAL_STATE",
        unsubRequest: false,
        error: action.payload,
      };
    case subscriptions.CONFIRM_SUBSCRIPTION:
      return {
        ...state,
        loading: false,
        error: null,
        isConfirmed: true,
      };
    case subscriptions.FETCH_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading: true,
        isConfirmed: false,
        error: null,
      };
    case subscriptions.FETCH_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        isConfirmed: false,
        error: null,
        selectedItem: action.payload,
      };
    case subscriptions.FETCH_SUBSCRIPTION_ERROR:
      return {
        ...state,
        loading: false,
        isConfirmed: false,
        error: action.payload,
      };
    case subscriptions.SELECT_SERVICE_REQUEST:
      return {
        ...state,
        loading: true,
        isConfirmed: false,
        error: null,
      };
    case subscriptions.SELECT_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        isConfirmed: false,
        error: null,
        selectedService: action.payload,
      };
    case subscriptions.SELECT_SERVICE_ERROR:
      return {
        ...state,
        loading: false,
        isConfirmed: false,
        error: action.payload,
      };
    case subscriptions.SELECT_CONSENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case subscriptions.SELECT_CONSENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        consent: action.payload,
      };
    case subscriptions.SELECT_CONSENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case subscriptions.CLEAR_CONSENT:
        return {
          ...state,
          loading: false,
          error: null,
          consent: null,
        };
    case subscriptions.SELECT_SUBSCRIPTION_CHARGE_SUCCESS:
      return {
        ...state,
        loading: false,
        isConfirmed: false,
        error: null,
        voucherTopupStatus: false,
        selectedCharge: action.payload,
      };
    case subscriptions.CLEAR_SUBSCRIPTION:
      return {
        ...state,
        loading: false,
        isConfirmed: false,
        selectedCharge: null,
        voucherTopupStatus: false,
        selectedPaymentMethod: null,
        selectedItem: null,
        paymentStatusInfo: null,
        error: null,
      };
    case subscriptions.SELECT_SUBSCRIPTION_CHARGE_REQUEST:
      return {
        ...state,
        loading: true,
        isConfirmed: false,
        error: null,
      };
    case subscriptions.SELECT_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
        isConfirmed: false,
        voucherTopupStatus: false,
        error: null,
      };
    case subscriptions.SELECT_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        isConfirmed: false,
        voucherTopupStatus: false,
        error: null,
        selectedPaymentMethod: action.payload,
      };
    case subscriptions.SELECT_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        loading: false,
        isConfirmed: false,
        error: action.payload,
        voucherTopupStatus: false,
        selectedPaymentMethod: null,
      };
    case subscriptions.BUY_ITEM_REQUEST:
      return {
        ...state,
        buyloading: true,
        loading: true,
        isConfirmed: false,
        paymentStatusInfo: null,
        error: null,
      };
    case subscriptions.BUY_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        buyloading: false,
        isConfirmed: true,
        paymentStatusInfo: action.payload,
        pendingItem: action.selectedModel,
        error: null,
      };
    case subscriptions.BUY_ITEM_ERROR:
      return {
        ...state,
        loading: false,
        buyloading: false,
        isConfirmed: false,
        error: action.payload,
      };
    case subscriptions.FETCH_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentMethods: action.payload,
        error: null,
      };
    case subscriptions.FETCH_PAYMENT_METHODS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case subscriptions.FETCH_PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        loading: true,
        isConfirmed: false,
        paymentMethods: null,
        error: null,
      };
    case subscriptions.FETCH_TOPUP_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        topupOptions: action.payload,
        error: null,
      };
    case subscriptions.FETCH_TOPUP_OPTIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case subscriptions.FETCH_TOPUP_OPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        isConfirmed: false,
        topupOptions: null,
        error: null,
      };
    case subscriptions.FETCH_BALANCES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case subscriptions.FETCH_BALANCES_SUCCESS:
      return {
        ...state,
        loading: false,
        balances: action.payload,
        error: null,
      };
    case subscriptions.FETCH_BALANCES_ERROR:
      return {
        ...state,
        loading: false,
        balances: null,
        error: action.payload,
      };
    //user subscriptions start
    case subscriptions.FETCH_USER_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case subscriptions.FETCH_SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionsList: action.payload,
        error: null,
        loadingAllsubcription: false,
      };

    case subscriptions.FETCH_PURCHASE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        purchaseList: action.payload,
        error: null,
      };

    case subscriptions.FETCH_PURCHASE_LIST_REQUEST:
      return {
        ...state,
        loadingAllPurchases: action.payload,
      };

    case subscriptions.FETCH_SUBSCRIPTION_LIST_REQUEST:
      return {
        ...state,
        loading: false,
        loadingAllsubcription: action.payload,
        error: null,
      };

    case subscriptions.FETCH_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptions: action.payload,
        error: null,
      };
    case subscriptions.FETCH_USER_SUBSCRIPTION_ERROR:
      return {
        ...state,
        loading: false,
        subscriptions: null,
        error: action.payload,
        loadingAllsubcription: false,
      };
    //user subscriptions end
    //user purchases start
    case subscriptions.FETCH_USER_PURCHASE_REQUEST:
      return {
        ...state,
        loading: true,
        purchases: null,
        error: null,
      };
    case subscriptions.FETCH_USER_PURCHASE_SUCCESS:
      return {
        ...state,
        loading: false,
        purchases: action.payload,
        error: null,
      };
    case subscriptions.FETCH_USER_PURCHASE_ERROR:
      return {
        ...state,
        loading: false,
        purchases: null,
        error: action.payload,
      };
    //user purchases end
    // started her
    case subscriptions.VOUCHER_TOPUP_SUCCESS:
      return {
        ...state,
        loading: false,
        voucherTopupStatus: action.payload,
        error: null,
      };
    case subscriptions.VOUCHER_TOPUP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case subscriptions.VOUCHER_TOPUP_REQUEST:
      return {
        ...state,
        loading: true,
        isConfirmed: false,
        voucherTopupStatus: false,
        error: null,
      };
    default:
      return state;
  }
};

export default subscriptionsReducer;