import { network } from "../types";

const initialState = {
	call: null,
	error: null
};

const networkReducer = (state = initialState, action) => {
	switch (action.type) {
	case network.NETWORK_INIT:
		return {
			...state,
			error: null,
			call: null
		};
	case network.NETWORK_INIT_SUCCESS:
		return {
			...state,
			call: action.payload,
			error: null,
		};
	case network.NETWORK_INIT_ERROR:
		return {
			...state,
			call: null,
			error: null
		};
	default:
		return state;
	}
};

export default networkReducer;