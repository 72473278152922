import { StyledButton } from "./Button.styles";
import { StyledButtonLoading, StyledButtonLoadingPlain } from "../SubscribeSection/SubscribeSection.styles";

const Button = ({caption, clickHandler, isOutline, isFullscreen, loading}) => {

	
	return (
		<div>
			{
				loading &&
				<StyledButtonLoadingPlain isOutline={isOutline}  isFullscreen={isFullscreen} onClick={clickHandler}>
					<StyledButtonLoading/>
				</StyledButtonLoadingPlain>
			}
			{
				!loading &&
				<StyledButton isOutline={isOutline}  isFullscreen={isFullscreen} onClick={clickHandler}>
					{caption}
				</StyledButton>
			}
		</div>
		

	);
};

export default Button;
