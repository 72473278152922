// import ReactDOM from "react-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import close from "../../../assets/images/close.png";
// import backgroundImage from "../../../assets/images/play2.png";
import backgroundImage2 from "../../../assets/images/play2eng.png";

// const innerHeightOnlyPx = window.innerHeight + 200 + "px";
const innerHeightPx = window.innerHeight - 272 + "px";
// const innerWidthHome =  window.innerHeight + "px";
const innerWidthHomePx = window.innerWidth;
const ModalTutorial = ({ show, children, title, titleBorder, subtitle, context , onClose }) => {
	// const [ setIsBrowser] = useState(false);

	const {
		language,
	  } = useSelector((state) => state);

	 const OneContext = () => {
		return show && context === 5 ? (
			<StyledModalOverlayInteractHomeTop>
			<>
				<StyledModalHomeTop>
				{title && <StyledModalTitle>
					<span>
					{title}
					</span>
					<span>
					<img
						onClick={() => {
							onClose();
						}}
						
						alt="Close"
						width="14"
						height="14"
						src={close}
						
                	/>
					</span>
				
					</StyledModalTitle>}
				{subtitle && <StyledSubTitle>{subtitle}</StyledSubTitle>}
				{titleBorder && <StyledBorder></StyledBorder>}
				<StyledModalBody>{children}</StyledModalBody>
				</StyledModalHomeTop>
						{
							context === 5? <ArrowUpToggle /> : ""
						}
			</>
			</StyledModalOverlayInteractHomeTop>
		) : null;
	  };

	  const TwoContext = () => {
		return show && context === 1 ? (
			<StyledModalOverlayInteractHomeOverPower>
			{
				<StyledModalHomeCenter>
								{title && <StyledModalTitle>
					<span>
					{title}
					</span>
					<span>
					<img
						onClick={() => {
							onClose();
						}}
						
						alt="Close"
						width="14"
						height="14"
						src={close}
						
                	/>
					</span>
				
					</StyledModalTitle>}
				{subtitle && <StyledSubTitle>{subtitle}</StyledSubTitle>}
				{titleBorder && <StyledBorder></StyledBorder>}
				<StyledModalBody>{children}</StyledModalBody>
				  </StyledModalHomeCenter>
		
			}
		
		  </StyledModalOverlayInteractHomeOverPower>
		) : null;
	  };

	  const ThreeContext = () => {
		return show && (context === 3) && language?.chosenLanguage?.name !== "english" ? (
			<StyledModalOverlayInteractPlay>
			{
	
	<StyledModalHome>
					{title && <StyledModalTitle>
					<span>
					{title}
					</span>
					<span>
					<img
						onClick={() => {
							onClose();
						}}
						
						alt="Close"
						width="14"
						height="14"
						src={close}
						
                	/>
					</span>
				
					</StyledModalTitle>}
	{subtitle && <StyledSubTitle>{subtitle}</StyledSubTitle>}
	{titleBorder && <StyledBorder></StyledBorder>}
	<StyledModalBody>{children}</StyledModalBody>
	</StyledModalHome>
		

			}
		
		  </StyledModalOverlayInteractPlay>
		) : 
		null
		;
	  };


	  const FiveContext = () => {
		return show && ( context === 3 ) && language?.chosenLanguage.name === "english" ? (
<>
			<Container>
			<Image src={backgroundImage2} />

		  </Container>

		  <div style={{zIndex:5, position: "fixed", width: "100%"}}>

		  <StyledModalHome>
					{title && <StyledModalTitle>
					<span>
					{title}
					</span>
					<span>
					<img
						onClick={() => {
							onClose();
						}}
						
						alt="Close"
						width="14"
						height="14"
						src={close}
						
                	/>
					</span>
				
					</StyledModalTitle>}
				{subtitle && <StyledSubTitle>{subtitle}</StyledSubTitle>}
				{titleBorder && <StyledBorder></StyledBorder>}
				<StyledModalBody>{children}</StyledModalBody>
			</StyledModalHome>
		  </div>
</>



		) : 
			null
		;
	  };

	  const FourContext = () => {
		return show && (context === 2) ? (
			<StyledModalOverlayInteractHome>
			{
	
	<StyledModalHome>
					{title && <StyledModalTitle>
					<span>
					{title}
					</span>
					<span>
					<img
						onClick={() => {
							onClose();
						}}
						
						alt="Close"
						width="14"
						height="14"
						src={close}
						
                	/>
					</span>
				
					</StyledModalTitle>}
	{subtitle && <StyledSubTitle>{subtitle}</StyledSubTitle>}
	{titleBorder && <StyledBorder></StyledBorder>}
	<StyledModalBody>{children}</StyledModalBody>
	</StyledModalHome>
		

			}
		
		  </StyledModalOverlayInteractHome>
		) : null;
	  };


	  const SixContext = () => {
		return show && (context === 4) ? (
			<StyledModalOverlayInteractHome>
			{
	
	<StyledModalHome>
					{title && <StyledModalTitle>
					<span>
					{title}
					</span>
					<span>
					<img
						onClick={() => {
							onClose();
						}}
						
						alt="Close"
						width="14"
						height="14"
						src={close}
						
                	/>
					</span>
				
					</StyledModalTitle>}
	{subtitle && <StyledSubTitle>{subtitle}</StyledSubTitle>}
	{titleBorder && <StyledBorder></StyledBorder>}
	<StyledModalBody>{children}</StyledModalBody>
	</StyledModalHome>
		

			}
		
		  </StyledModalOverlayInteractHome>
		) : null;
	  };



	return(
		<>
		  <OneContext />
		  <TwoContext />
		  <ThreeContext/>
		  <FourContext/>
		  <FiveContext/>
		  <SixContext/>
		</>
	  );
	

};
  
const StyledModalBody = styled.div`
  padding: 16px;
  padding-top: 0px;
`;

const StyledSubTitle = styled.div`
   color: #787878; 
   font-family: Poppins;
   font-size: 18px;
   line-height: 25px;
   font-weight: normal;
   margin-bottom: 6px;
  `;

const StyledModalTitle = styled.div`
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 21px;
	padding: 24px;
	display: flex;
	align-items: center;
	margin-bottom: 3px;
	justify-content: space-between;
  `;
export const StyledBorder = styled.div`
	border: 1px solid #C8C8C8;
  `;
  
// const StyledModalHeader = styled.div`
// 	display: flex;
// 	justify-content: flex-end;
// 	font-size: 25px;

// 	a {
// 		color: red;

// 	margin: 24px 24px 0px 0px;
// 	}
//   `;
  
const StyledModalHome = styled.div`
	background: #424242;
	width: 95%;
	height: 188px;
	border-radius: 4px;
	color: white;
	margin-top: ${innerHeightPx};
	justify-self: center;
  `;

  const StyledModalHomeTop = styled.div`
	background: #424242;
	width: 95%;
	height: 188px;
	border-radius: 4px;
	color: white;
	margin-top: 16px;
	justify-self: center;
  `;

  const StyledModalHomeCenter = styled.div`
  background: #424242;
  width: 95%;
  height: 100%;
  border-radius: 4px;
  color: white;
  margin-top: 250px;
  justify-self: center;
`;
//   const StyledModalPlay = styled.div`
// 	background: #424242;
// 	width: 272px;
// 	height: 100%;
// 	border-radius: 4px;
// 	color: white;
// 	margin-top: ${innerHeightPx};
//   `;
//   const StyledModalManage = styled.div`
// 	background: #424242;
// 	width: 272px;
// 	height: 100%;
// 	border-radius: 4px;
// 	color: white;
// 	margin-top: ${innerHeightPx};
//   `;
// const StyledModalOverlay = styled.div`
// 	position: fixed;
// 	z-index: 4;
// 	top: 0;
// 	left: 0;
// 	min-width: 100vw;
// 	max-width: 100vw;
// 	min-height: 100vh;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	background-color: rgba(0, 0, 0, 0.5);
//   `;

const StyledModalOverlayInteractPlay = styled.div`
position: fixed;
z-index: 4;
width: 100vw;
min-height: 100vh;
background-image: url('/images/play2.png');
background-repeat: no-repeat;
background-size: 90;

`;

// const StyledModalOverlayInteractPlayEng = styled.div`
// position: fixed;
// z-index: 4;
// top: 0;
// left: 0;
// background-image: url('/images/play2eng.png');
// background-repeat: no-repeat;
// background-size: contain;
// height: ${innerWidthHome};


// `;


  const StyledModalOverlayInteractHome = styled.div`
	position: fixed;
	z-index: 3;
	top: 0;
	left: 0;
	width: 100vw;
	min-height:92vh;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.9);
  `;

  const StyledModalOverlayInteractHomeOverPower = styled.div`
	position: fixed;
	z-index: 4;
	top: 0;
	left: 0;
	width: 100vw;
	min-height:100vh;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.9);
  `;

  


  const StyledModalOverlayInteractHomeTop = styled.div`
  position: fixed;
  z-index: 4;
  bottom: 0px;
  left: 0;
  width: 100vw;
  min-height:93vh;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
`;

  

  // 	position: fixed;
// 	z-index: 4;
// 	top: 0;
// 	left: 0;
// 	min-width: 100vw;
// 	max-width: 100vw;
// 	min-height: 100vh;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	background-color: rgba(0, 0, 0, 0.5);

//   const StyledModalOverlayInteractPlay = styled.div`
//   position: fixed;
//   z-index: 4;
//   top: 0;

//   min-width: 100vw;
//   max-width: 100vw;
//   min-height: 100vh;

//   justify-content: center;
//   align-items: center;
//   background-color: rgba(0, 0, 0, 0.5);
// `;

// const StyledModalOverlayInteractManage = styled.div`
// position: fixed;
// z-index: 4;
// top: 0;

// min-width: 100vw;
// max-width: 100vw;
// min-height: 100vh;

// justify-content: center;
// align-items: center;
// background-color: rgba(0, 0, 0, 0.5);
// `;




// const ArrowDownPlay = styled.div`
// width: 0; 
// height: 0; 
// border-left: 20px solid transparent;
// border-right: 20px solid transparent;
// margin-left: 44px;
// border-top: 20px solid #404040;
// ;
// `;

// const ArrowDownManage = styled.div`
// width: 0; 
// height: 0; 
// border-left: 20px solid transparent;
// border-right: 20px solid transparent;
// margin-left: 320px;
// border-top: 20px solid #404040;
// ;
// `;

// const ArrowDownHome = styled.div`
// width: 0; 
// height: 0; 
// border-left: 20px solid transparent;
// border-right: 20px solid transparent;
// margin-left:  ${"10%"};
// border-top: 20px solid #404040;
// ;
// `;

const ArrowUpToggle = styled.div`
  width: 0; 
  height: 0; 
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #404040;
  margin-left: ${innerWidthHomePx - 100}px;
  margin-top: -206px;
;
`;


const Container = styled.div`
        width: 100vw;
        position: fixed;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
  		z-index: 4;
`;

const Image = styled.img`
        width: 100vw; 
        height: 100vh;
`;


// const StyledModalOverlayInteractPlayEng = styled.div`
// position: fixed;
// z-index: 4;
// top: 0;
// left: 0;
// background-image: url('/images/play2eng.png');
// background-repeat: no-repeat;
// background-size: contain;
// height: ${innerWidthHome};


// `;



// const ArrowDownPlay = styled.div`
// width: 0; 
// height: 0; 
// border-left: 20px solid transparent;
// border-right: 20px solid transparent;

// border-top: 20px solid #404040;
// ;
// `;

// const ArrowDownManage = styled.div`
// width: 0; 
// height: 0; 
// border-left: 20px solid transparent;
// border-right: 20px solid transparent;

// border-top: 20px solid #404040;
// ;
// `;



// const StyledModaltransparent = styled.div`
//   position: fixed;
//   z-index: 4;
//   top: 0;
//   left: 0;
//   min-width: 100vw;
//   max-width: 100vw;
//   min-height: 100vh;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;
  
export default ModalTutorial;