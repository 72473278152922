import { filtered } from "../types";

export const fetchItemsFilterAllByCategory = (query, list=[]) => async(dispatch) => {
	try {
		const mQuery = `${query}`.trim();
		if(mQuery.length === 0 || list.length===0 || (!list.items || list.items.length===0)) 
			return dispatch({type: filtered.FILTERED_ITEMS_SUCCESS, payload: []});

		const filterFunctionStartsWith = (item)=>{
			return `${item.title}`.toLocaleLowerCase().startsWith(`${mQuery}`.toLocaleLowerCase());
		};

		const filterFunctionContains = (item)=>{
			return `${item.title}`.toLocaleLowerCase().includes(`${mQuery}`.toLocaleLowerCase());
		};
    
		const filter = (item, level) => {
      const subItems = { ...item };
      if (subItems.data == null || !subItems.data || subItems.data.length === 0)
        return [];

      const data = item?.data?.filter(
        level === 1 ? filterFunctionStartsWith : filterFunctionContains,
      );
      return data;
    };

    let result = list?.items?.map(filter, 1);

    //Here Begins another layer of filter pattern, it can be removed if not neccessary.
    let empty = true;
    result.forEach((element) => {
      if (element?.length !== 0) return (empty = false);
    });

    if (empty) result = list.items.map(filter, 2);
    //Here Ends another layer of filter pattern, it can be removed if not neccessary.

    dispatch({
      type: filtered.FILTERED_ITEMS_SUCCESS,
      payload: result.flat(),
    });

	} catch (error) {
		dispatch({
			type: filtered.FILTERED_ITEMS_ERROR,
			payload: {
				message: error?.message,
				error
			}
		});
	}
};