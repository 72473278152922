import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledBottomBar = styled(motion.div)`
 height: 64px;
 min-width: 100vw;
 position: fixed;
 margin-top: auto;
 z-index: 3;
 bottom: 0;
 background-color:#000;
 display: flex;
 align-items: center;
 justify-content: space-around;
 padding-bottom: 10px;
`;

export const StyledBottomBarItem = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

`;
export const StyledBottomBarItemName = styled(motion.span)`
  text-transform: uppercase;
  font-family:  'MTN';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  margin-top: 8px;
  align-items: center;
  text-align: center;
  color: ${({active}) => active? "#FBC800" : "#fff"}
`;
export const StyledBottomBarItemIcon = styled(motion.div)`
  color: #FBC800;
  max-height: 20px;
  max-width: 20px;
  background-image: ${({icon,noIcon}) => icon ? `url(${icon})` : noIcon ? `url(${noIcon})` : `url(${icon})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;
