import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledFaqs= styled(motion.div)`
padding: 24px;
  .topic-name {
	  font-family: MTN;
	  font-size: 24px;
	  line-height: 31.2px;
	  font-weight: 700;
	  margin-bottom: 24px;
	
  }

  .question {

	  margin-bottom: 24px;

	  .first-row {
		  display: flex;
		  justify-content: space-between;
		  margin-bottom:24px;
	  }

	  .value {
		 font-family: MTNBrighterSansRegular;
	  font-size: 16px;
	  font-style: bold;
	  font-weight: 700;
	  line-height: 18.75px;
	  }

	  .sort-icon {
			 background-image: url('/images/icons/chevron.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
            width: 24px;
			backface-visibility: hidden !important;
			outline: none !important;
			height: 24px;
			margin-left: 24px;
			-webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out; 
			&:hover {
				cursor:pointer;

			}

			&.show {
				transform: rotate(90deg);
				backface-visibility: hidden !important;
			    outline: none !important;
			}
		 }
		 .sort-icon-faq {
			background-image: url(/images/icons/right_chevron.svg);
		   background-position: center;
		   background-repeat: no-repeat;
		   background-size: contain;
		   width: 24px;
		   backface-visibility: hidden !important;
		   outline: none !important;
		   height: 24px;
		   margin-left: 24px;
		   -webkit-transition: 0.3s ease-in-out;
   -moz-transition: 0.3s ease-in-out;
   -o-transition: 0.3s ease-in-out;
   transition: 0.3s ease-in-out; 
		   &:hover {
			   cursor:pointer;

		   }

		   &.show {
			   transform: rotate(90deg);
			   backface-visibility: hidden !important;
			   outline: none !important;
		   }
		}

	  .answer {
		  font-style: normal;
		  font-family: MTNBrighterSansRegular;
			font-size: 13px;
			line-height: 15.23px;
			font-weight: 400 !important;
			margin-bottom: 24px;
			margin-right: 24px;
	  }
  }
`;