import { motion } from "framer-motion";
import styled from "styled-components";


export const StyledTopUpVoucher = styled(motion.div)`
  padding: 24px;

  .title {
    font-family: MTNBrighterSansRegular;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }

  .input-wrapper {
    min-height: 56px;
    margin-top: 24px;
  }

  .input-before-text {
    font-family: MTNBrighterSansRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 9px;
    margin-bottom: 8px;
  }

  .voucher-input {
     padding: 12px;
     border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
     background: transparent;
     min-width: 272px;
     width: 100%;
     color: #FFFFFF;
     margin-bottom: 12px;
     text-transform: uppercase;
     font-family: MTNBrighterSansRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #BDBDBD;

  }

  .input-error-text {
    font-family: MTNBrighterSansRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 9px;
    color: #DC6428;

  }

  .buttons {
    margin: 24px -24px -24px;
  }

`;