import {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";

import {PWA_ROUTES} from "../../constants/appConstants";
import {Menu, Page, TopNav} from "../../shared/components";

import {CategoriesScreen as events} from "../../analytics/events";

const CategoriesScreen = () => {
	const dispatch = useDispatch();
	const {network} = useSelector((state) => state);

	useEffect(() => {
		dispatch({
			type: "ANALYTICS/TRACK_EVENT",
			event: events.categories_screen(network?.call?.headers?.isoRegion, network?.call?.headers.environment),
		});
	}, []);

	return (
		<Page isBottomBar={true}>
			<TopNav title="categories" />
			<Menu
				title="video"
				clickRoute={PWA_ROUTES.HOME}
				description="Movies, TV series and videos"
			/>
			<Menu
				title="music"
				clickRoute={PWA_ROUTES.HOME}
				description="The latest music"
			/>
			<Menu
				title="games"
				clickRoute={PWA_ROUTES.HOME}
				description="Action, sports and kids games"
			/>
			<Menu
				title="sports"
				clickRoute={PWA_ROUTES.HOME}
				description="Watch the latest sports events"
			/>
			<Menu
				title="lifestyle"
				clickRoute={PWA_ROUTES.HOME}
				description="Food, travel and news"
			/>
		</Page>
	);
};

export default CategoriesScreen;
