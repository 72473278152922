const HOST = window.location.host;


//This should indeed be an environment varibales
//uat endpoint "https://mtngh-uat.mtn-dev.auth0.com/authorize"
//uat token endpoint "https://mtngh-uat.mtn-dev.auth0.com/oauth/token"
//uat id "LujhOlAapFSnZmMpvwVIlMTYPYssa4nB"
//uat secret "l2yVCRH3M5d7QeYgf-admjQgerfIAW4Vodczpo50l6L4JssQcAR4kv4PWwhAMonW"
//uat domain "mtngh-uat.mtn-dev.auth0.com"
//generic shared domain https://authuat.mtn.com.gh/

//prod: auth.mtn.com.gh
export const CRED = {
  CLIENT: {
    AUTHORISATION_ENDPOINT: HOST.includes("app.uat")
      ? "https://mtngh-uat.mtn-dev.auth0.com/authorize"
      : HOST.includes("dev") || HOST.includes("localhost")
      ? "https://mtngh-dev.mtn-dev.auth0.com/authorize"
      : // ? "https://mtngh-dev.mtn-dev.auth0.com/authorize"
      HOST.includes("sit")
      ? "https://mtngh-dev.mtn-dev.auth0.com/authorize"
      : "https://mtngh-prod.mtn.auth0.com/authorize",
    TOKEN_ENDPOINT: HOST.includes("uat")
      ? "https://mtngh-uat.mtn-dev.auth0.com/oauth/token"
      : HOST.includes("dev") || HOST.includes("localhost")
      ? "https://mtngh-dev.mtn-dev.auth0.com/oauth/token"
      : // ? "https://mtngh-dev.mtn-dev.auth0.com/oauth/token"
      HOST.includes("sit")
      ? "https://mtngh-dev.mtn-dev.auth0.com/oauth/token"
      : "https://mtngh-prod.mtn.auth0.com/oauth/token",
    ID: HOST.includes("uat")
      ? "LujhOlAapFSnZmMpvwVIlMTYPYssa4nB"
      : HOST.includes("dev") || HOST.includes("localhost")
      ? "u2xEQ8Edn5mR6FZkiwfucRe9eKIuCKkY"
      : // ? "u2xEQ8Edn5mR6FZkiwfucRe9eKIuCKkY"
      HOST.includes("sit")
      ? "u2xEQ8Edn5mR6FZkiwfucRe9eKIuCKkY"
      : "vIXQ9G7VutA7XhuSAyymOi0Lkx8nbHYW",
    SECRET: HOST.includes("uat")
      ? "l2yVCRH3M5d7QeYgf-admjQgerfIAW4Vodczpo50l6L4JssQcAR4kv4PWwhAMonW"
      : HOST.includes("dev") || HOST.includes("localhost")
      ? "vv9xjWSJDqPkC4AV0L5EszKndiBQH4l1yxUZGU845NeKICggoGu6IUNygltBMdrk"
      : // ? "vv9xjWSJDqPkC4AV0L5EszKndiBQH4l1yxUZGU845NeKICggoGu6IUNygltBMdrk"
      HOST.includes("sit")
      ? "vv9xjWSJDqPkC4AV0L5EszKndiBQH4l1yxUZGU845NeKICggoGu6IUNygltBMdrk"
      : "l8KqOurVZ33Qe_MUPJPLjrMzLMX8KVbykj_c5694vc2USNDE71-9b5M1Wi2WWztY",
    DOMAIN: HOST.includes("uat")
      ? "mtngh-uat.mtn-dev.auth0.com"
      : HOST.includes("dev") || HOST.includes("localhost")
      ? "mtngh-dev.mtn-dev.auth0.com"
      : // ?  "mtngh-dev.mtn-dev.auth0.com"
      HOST.includes("sit")
      ? "mtngh-dev.mtn-dev.auth0.com"
      : "mtngh-prod.mtn.auth0.com",
    AUDIENCE: "https://mtnplay.mtn.com",
  },
};
 
export const SESSION_STORAGE_CACHE = {
  get: function (key) {
    return JSON.parse(sessionStorage.getItem(key));
  },
  
  set: function (key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  },
  
  remove: function (key) {
    sessionStorage.removeItem(key);
  },
  
  // Optional
  allKeys: function () {
    return Object.keys(sessionStorage);
  }
};