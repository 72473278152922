import { ITEM_TYPES, PWA_ROUTES } from "../../constants/appConstants";

export const musicItems = [{
	id: 1,
	itemType: ITEM_TYPES.MUSIC,
	subscriptionInfo: {
		provider: {
			logo: "",
			slogan: "Provided by Mymtn play"
		},
		payments: {
			title: "confirm your subscription",
			caption: "<p>You will be charged R5/day from your airtime. <br/> You can cancel at any time from your Profile.</p>",
			status: {
				title: "Insufficient funds",
				caption: "<p>You do not have enough airtime to complete your subscription.</p>",
				success: false,
				buttonText: "top up",
				route: PWA_ROUTES.HOME
			}
		},
		button: {
			route: "",
			caption: "Subscribe to play"
		}
	},
	title: "Foo Fighters",
	image: "/images/Foo-Fighters-600x600.png",
	description: "medicine at midnight",
	tag: {
		value: "subscription",
		backgroundColor: "#FBC800",
		color: "#FFFFFF"
	}
},
{
	id: 2,
	itemType: ITEM_TYPES.MUSIC,
	subscriptionInfo: {
		provider: {
			logo: "",
			slogan: "Provided by Spotify"
		},
		payments: {
			title: "confirm your purchase",
			caption: "<p>R 200.00 will be deducted from your airtime.</p>",
			status: {
				title: "Insufficient funds",
				caption: "<p>You do not have enough airtime to complete your subscription.</p>",
				success: false,
				buttonText: "top up",
				route: PWA_ROUTES.HOME
			}
		},
		button: {
			route: "",
			caption: "Purchase for R xxx. xx"
		}
	},
	title: "topaz jones",
	description: "Don't go telling your momma",
	image: "/images/Topaz-Jones-600x600.png",
	tag: {
		value: "in-app purchase",
		backgroundColor: "#2196F3",
		color: "#FFFFFF"
	}
},
{
	id: 3,
	subscriptionInfo: {
		provider: {
			logo: "",
			slogan: "Provided by Spotify Music"
		},
		button: {
			route: "",
			caption: "Play for free"
		}
	},
	itemType: ITEM_TYPES.MUSIC,
	title: "weezer",
	description: "OK human",
	image: "/images/WeezerOKHuman-600x600.png",
	tag: {
		value: "free",
		backgroundColor: "#8BC34A",
		color: "#FFFFFF"
	}
},
{
	id: 4,
	itemType: ITEM_TYPES.MUSIC,
	subscriptionInfo: {
		provider: {
			logo: "",
			slogan: "Provided by Mymtn play"
		},
		payments: {
			title: "confirm your purchase",
			caption: "<p>R 200.00 will be deducted from your airtime.</p>",
			status: {
				title: "Insufficient funds",
				caption: "<p>You do not have enough airtime to complete your subscription.</p>",
				success: false,
				buttonText: "top up",
				route: PWA_ROUTES.HOME
			}
		},
		button: {
			route: "",
			caption: "Purchase for R x. xx"
		}
	},
	title: "topaz jones",
	description: "Don't go telling your momma (The Uncle Remix)",
	image: "/images/Topaz-Jones-600x600.png",
	tag: {
		value: "in-app purchase",
		backgroundColor: "#2196F3",
		color: "#FFFFFF"
	}
}
];

export const offerItems = [
	{
		id: 4,
		itemType: ITEM_TYPES.MUSIC,
		isOffer: true,
		title: "topaz jones",
		subscriptionInfo: {
			provider: {
				logo: "",
				slogan: "Provided by Mymtn play"
			},
			payments: {
				title: "confirm your purchase",
				caption: "<p>R 200.00 will be deducted from your airtime.</p>",
				status: {
					title: "Insufficient funds",
					caption: "<p>You do not have enough airtime to complete your subscription.</p>",
					success: false,
					buttonText: "top up",
					route: PWA_ROUTES.HOME
				}
			},
			button: {
				route: "",
				caption: "Purchase for R x. xx"
			}
		},
		description: "Don't go telling your momma (The Uncle Remix)",
		image: "/images/Topaz-Jones-600x600.png",
		tag: {
			value: "in-app purchase",
			backgroundColor: "#2196F3",
			color: "#FFFFFF"
		}
	},

	{
		id: 6,
		itemType: ITEM_TYPES.VIDEO,
		subscriptionInfo: {
			provider: {
				logo: "",
				slogan: "Provided by Youtube"
			},
			payments: {
				title: "confirm your subscription",
				caption: "<p>You will be charged R5/day from your airtime. <br/> You can cancel at any time from your Profile.</p>",
				status: {
					title: "Insufficient funds",
					caption: "<p>You do not have enough airtime to complete your subscription.</p>",
					success: false,
					buttonText: "top up",
					route: PWA_ROUTES.HOME
				}
			},
			button: {
				route: "",
				caption: "Subscribe to play"
			}
		},
		title: "Ted Lasso",
		image: "/images/tedlasso01-1.png",
		description: "medicine at midnight",
		isOffer: true,
		tag: {
			value: "subscription",
			backgroundColor: "#FBC800",
			color: "#FFFFFF"
		}
	},
	{
		id: 1,
		itemType: ITEM_TYPES.MUSIC,
		subscriptionInfo: {
			provider: {
				logo: "",
				slogan: "Provided by Mymtn play"
			},
			payments: {
				title: "confirm your subscription",
				caption: "<p>You will be charged R5/day from your airtime. <br/> You can cancel at any time from your Profile.</p>",
				status: {
					title: "Insufficient funds",
					caption: "<p>You do not have enough airtime to complete your subscription.</p>",
					success: false,
					buttonText: "top up",
					route: PWA_ROUTES.HOME
				}
			},
			button: {
				route: "",
				caption: "Subscribe to play"
			}
		},
		title: "Foo Fighters",
		image: "/images/Foo-Fighters-600x600.png",
		isOffer: true,
		description: "medicine at midnight",
		tag: {
			value: "subscription",
			backgroundColor: "#FBC800",
			color: "#FFFFFF"
		}
	},
	{
		id: 2,
		itemType: ITEM_TYPES.MOVIE,
		title: "Eternals",
		subscriptionInfo: {
			provider: {
				logo: "",
				slogan: "Provided by Mymtn play"
			},
			payments: {
				title: "confirm your purchase",
				caption: "<p>R 200.00 will be deducted from your airtime.</p>",
				status: {
					title: "Insufficient funds",
					caption: "<p>You do not have enough airtime to complete your subscription.</p>",
					success: false,
					buttonText: "top up",
					route: PWA_ROUTES.HOME
				}
			},
			button: {
				route: "",
				caption: "Purchase for R x. xx"
			}
		},
		isOffer: true,
		description: "pg-13",
		image: "/images/Eternals.png",
		tag: {
			value: "in-app purchase",
			backgroundColor: "#2196F3",
			color: "#FFFFFF"
		}
	},
	{
		id: 3,
		itemType: ITEM_TYPES.MUSIC,
		subscriptionInfo: {
			provider: {
				logo: "",
				slogan: "Provided by Mymtn play"
			},
			button: {
				route: "",
				caption: "Play for free"
			}
		},
		title: "weezer",
		description: "OK human",
		isOffer: true,
		image: "/images/WeezerOKHuman-600x600.png",
		tag: {
			value: "free",
			backgroundColor: "#8BC34A",
			color: "#FFFFFF"
		}
	},

];
export const trendingItems = [{
	id: 1,
	itemType: ITEM_TYPES.MUSIC,
	subscriptionInfo: {
		provider: {
			logo: "",
			slogan: "Provided by Mymtn play"
		},
		payments: {
			title: "confirm your subscription",
			caption: "<p>You will be charged R5/day from your airtime. <br/> You can cancel at any time from your Profile.</p>",
			status: {
				title: "Insufficient funds",
				caption: "<p>You do not have enough airtime to complete your subscription.</p>",
				success: false,
				buttonText: "top up",
				route: PWA_ROUTES.HOME
			}
		},
		button: {
			route: "",
			caption: "Subscribe to play"
		}
	},
	title: "Foo Fighters",
	image: "/images/Foo-Fighters-600x600.png",
	isTrending: true,
	description: "medicine at midnight",
	tag: {
		value: "subscription",
		backgroundColor: "#FBC800",
		color: "#FFFFFF"
	}
},
{
	id: 2,
	itemType: ITEM_TYPES.MOVIE,
	subscriptionInfo: {
		provider: {
			logo: "",
			slogan: "Provided by Mymtn play"
		},
		payments: {
			title: "confirm your purchase",
			caption: "<p>R 200.00 will be deducted from your airtime.</p>",
			status: {
				title: "Insufficient funds",
				caption: "<p>You do not have enough airtime to complete your subscription.</p>",
				success: false,
				buttonText: "top up",
				route: PWA_ROUTES.HOME
			}
		},
		button: {
			route: "",
			caption: "Purchase for R x. xx"
		}
	},
	title: "Eternals",
	isTrending: true,
	description: "pg-13",
	image: "/images/Eternals.png",
	tag: {
		value: "in-app purchase",
		backgroundColor: "#2196F3",
		color: "#FFFFFF"
	}
},
{
	id: 3,
	itemType: ITEM_TYPES.MUSIC,
	subscriptionInfo: {
		provider: {
			logo: "",
			slogan: "Provided by Mymtn play"
		},
		button: {
			route: "",
			caption: "Play for free"
		}
	},
	title: "weezer",
	description: "OK human",
	isTrending: true,
	image: "/images/WeezerOKHuman-600x600.png",
	tag: {
		value: "free",
		backgroundColor: "#8BC34A",
		color: "#FFFFFF"
	}
},
{
	id: 4,
	itemType: ITEM_TYPES.MUSIC,
	subscriptionInfo: {
		provider: {
			logo: "",
			slogan: "Provided by Mymtn play"
		},
		payments: {
			title: "confirm your purchase",
			caption: "<p>R 200.00 will be deducted from your airtime.</p>",
			status: {
				title: "Insufficient funds",
				caption: "<p>You do not have enough airtime to complete your subscription.</p>",
				success: false,
				buttonText: "top up",
				route: PWA_ROUTES.HOME
			}
		},
		button: {
			route: "",
			caption: "Purchase for R x. xx"
		}
	},
	isTrending: true,
	title: "topaz jones",
	description: "Don't go telling your momma (The Uncle Remix)",
	image: "/images/Topaz-Jones-600x600.png",
	tag: {
		value: "in-app purchase",
		backgroundColor: "#2196F3",
		color: "#FFFFFF"
	}
},

{
	id: 6,
	itemType: ITEM_TYPES.VIDEO,
	subscriptionInfo: {
		provider: {
			logo: "",
			slogan: "Provided by Mymtn play"
		},
		payments: {
			title: "confirm your subscription",
			caption: "<p>You will be charged R5/day from your airtime. <br/> You can cancel at any time from your Profile.</p>",
			status: {
				title: "Insufficient funds",
				caption: "<p>You do not have enough airtime to complete your subscription.</p>",
				success: false,
				buttonText: "top up",
				route: PWA_ROUTES.HOME
			}
		},
		button: {
			route: "",
			caption: "Subscribe to play"
		}
	},
	title: "Ted Lasso",
	image: "/images/tedlasso01-1.png",
	description: "medicine at midnight",
	isTrending: true,
	tag: {
		value: "subscription",
		backgroundColor: "#FBC800",
		color: "#FFFFFF"
	}
},
];
export const movieItems = [{
	id: 1,
	itemType: ITEM_TYPES.MOVIE,
	subscriptionInfo: {
		provider: {
			logo: "",
			slogan: "Provided by HBO Max"
		},
		payments: {
			title: "confirm your subscription",
			caption: "<p>You will be charged R5/day from your airtime. <br/> You can cancel at any time from your Profile.</p>",
			status: {
				title: "Insufficient funds",
				caption: "<p>You do not have enough airtime to complete your subscription.</p>",
				success: false,
				buttonText: "top up",
				route: PWA_ROUTES.HOME
			}
		},
		button: {
			route: "",
			caption: "Subscribe to play"
		}
	},
	title: "Dune",
	image: "/images/Dune.png",
	description: "pg-13",
	tag: {
		value: "subscription",
		backgroundColor: "#FBC800",
		color: "#FFFFFF"
	}
},
{
	id: 2,
	itemType: ITEM_TYPES.MOVIE,
	title: "Eternals",
	description:"pg-16",
	subscriptionInfo: {
		provider: {
			logo: "",
			slogan: "Provided by HBO Max"
		},
		payments: {
			title: "confirm your purchase",
			caption: "<p>R 200.00 will be deducted from your airtime.</p>",
			status: {
				title: "Purchase successful",
				caption: "<p>Transferring you to your service provider.</p>",
				success: true,
				buttonText: "top up",
				route: PWA_ROUTES.HOME
			}
		},
		button: {
			route: "",
			caption: "Purchase for R x. xx"
		}
	},
	image: "/images/Eternals.png",
	tag: {
		value: "in-app purchase",
		backgroundColor: "#2196F3",
		color: "#FFFFFF"
	}
},
{
	id: 3,
	itemType: ITEM_TYPES.MOVIE,
	title: "Antlers",
	description: "pg-16",
	subscriptionInfo: {
		provider: {
			logo: "",
			slogan: "Provided by Sony"
		},
		button: {
			route: "",
			caption: "Play for free"
		}
	},
	image: "/images/Antlers.png",
	tag: {
		value: "free",
		backgroundColor: "#8BC34A",
		color: "#FFFFFF"
	}
}
];

export const gamesItems = [{
	id: 1,
	itemType: ITEM_TYPES.GAME,
	title: "PES",
	image: "sports",
	subscriptionInfo: {
		provider: {
			logo: "",
			slogan: "Provided by Steam"
		},
		payments: {
			title: "confirm your subscription",
			caption: "<p>You will be charged R5/day from your airtime. <br/> You can cancel at any time from your Profile.</p>",
			status: {
				title: "Purchase successful",
				caption: "<p>Transferring you to your service provider.</p>",
				success: true,
				buttonText: "top up",
				route: PWA_ROUTES.HOME
			}
		},
		button: {
			route: "",
			caption: "Subscribe to play"
		}
	},
	description: "medicine at midnight",
	tag: {
		value: "subscription",
		backgroundColor: "#FBC800",
		color: "#FFFFFF"
	}
},
{
	id: 2,
	itemType: ITEM_TYPES.GAME,
	title: "Gran Turismo",
	description: "auto",
	subscriptionInfo: {
		provider: {
			logo: "",
			slogan: "Provided by EA Sports"
		},
		payments: {
			title: "confirm your purchase",
			caption: "<p>R 200.00 will be deducted from your airtime.</p>",
			status: {
				title: "Purchase successful",
				caption: "<p>Transferring you to your service provider.</p>",
				success: true,
				buttonText: "top up",
				route: PWA_ROUTES.HOME
			}
		},
		button: {
			route: "",
			caption: "Purchase for R x. xx"
		}
	},
	image: "/images/Topaz-Jones-600x600.png",
	tag: {
		value: "in-app purchase",
		backgroundColor: "#2196F3",
		color: "#FFFFFF"
	}
},
{
	id: 3,
	itemType: ITEM_TYPES.GAME,
	subscriptionInfo: {
		provider: {
			logo: "",
			slogan: "Provided by EA Sports"
		},
		button: {
			route: "",
			caption: "Play for free"
		}
	},
	title: "Tekken",
	description: "kungfu",
	image: "/images/WeezerOKHuman-600x600.png",
	tag: {
		value: "free",
		backgroundColor: "#8BC34A",
		color: "#FFFFFF"
	}
},
{
	id: 4,
	itemType: ITEM_TYPES.GAME,
	title: "NBA",
	description: "sports",
	subscriptionInfo: {
		provider: {
			logo: "",
			slogan: "Provided by EA Sports"
		},
		payments: {
			title: "confirm your purchase",
			caption: "<p>R 200.00 will be deducted from your airtime.</p>",
			status: {
				title: "Purchase successful",
				caption: "<p>Transferring you to your service provider.</p>",
				success: true,
				buttonText: "top up",
				route: PWA_ROUTES.HOME
			}
		},
		button: {
			route: "",
			caption: "Purchase for R x. xx"
		}
	},
	image: "/images/Topaz-Jones-600x600.png",
	tag: {
		value: "in-app purchase",
		backgroundColor: "#2196F3",
		color: "#FFFFFF"
	}
}
];
export const videosItems = [{
	id: 1,
	itemType: ITEM_TYPES.VIDEO,
	subscriptionInfo: {
		provider: {
			logo: "",
			slogan: "Provided by Youtube"
		},
		payments: {
			title: "confirm your subscription",
			caption: "<p>You will be charged R5/day from your airtime. <br/> You can cancel at any time from your Profile.</p>",
			status: {
				title: "Insufficient funds",
				caption: "<p>You do not have enough airtime to complete your subscription.</p>",
				success: false,
				buttonText: "top up",
				route: PWA_ROUTES.HOME
			}
		},
		button: {
			route: "",
			caption: "Subscribe to play"
		}
	},
	title: "Ted Lasso",
	image: "/images/tedlasso01-1.png",
	description: "medicine at midnight",
	tag: {
		value: "subscription",
		backgroundColor: "#FBC800",
		color: "#FFFFFF"
	},
        
},
{
	id: 2,
	itemType: ITEM_TYPES.VIDEO,
	subscriptionInfo: {
		provider: {
			logo: "",
			slogan: "Provided by Youtube"
		},
		payments: {
			title: "confirm your purchase",
			caption: "<p>R 200.00 will be deducted from your airtime.</p>",
			status: {
				title: "Insufficient funds",
				caption: "<p>You do not have enough airtime to complete your subscription.</p>",
				success: false,
				buttonText: "top up",
				route: PWA_ROUTES.HOME
			}
		},
		button: {
			route: "",
			caption: "Purchase for R x. xx"
		}
	},
	title: "Cobra kai",
	description: "Don't go telling your momma",
	image: "/images/Cobra-Kai.png",
	tag: {
		value: "in-app purchase",
		backgroundColor: "#2196F3",
		color: "#FFFFFF"
	}
},
{
	id: 3,
	itemType: ITEM_TYPES.VIDEO,
	subscriptionInfo: {
		button: {
			route: "",
			caption: "Play for free"
		},
		payments: {
			title: "confirm your subscription",
			caption: "<p>You will be charged R5/day from your airtime. <br/> You can cancel at any time from your Profile.</p>",
			status: {
				title: "Insufficient funds",
				caption: "<p>You do not have enough airtime to complete your subscription.</p>",
				success: false,
				buttonText: "top up",
				route: PWA_ROUTES.HOME
			}
		}
	},
	title: "Midnight Mass",
	description: "OK human",
	image: "/images/Midnight-Mass.png",
	tag: {
		value: "free",
		backgroundColor: "#8BC34A",
		color: "#FFFFFF"
	}
}
];