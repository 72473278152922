import { profile } from "../types";

const initialState = {
	data: null,
	error: null
};

const profileReducer = (state = initialState, action) => {
   
	switch (action.type) {
	case profile.UPDATED_PROFILE:
		return {
			...state,
			error: null,
			data: action.payload
		};
	case profile.UPDATED_PROFILE_SUCCESS:
		return {
			...state,
			data: action.payload,
			error: null,
		};
	case profile.UPDATED_PROFILE_ERROR:
		return {
			...state,
			data: null,
			error: action.payload,
		};
	case profile.RESET_PROFILE:
		return {
			...state,
			error: null,
			data: null
		};
	default:
		return state;
	}
};

export default profileReducer;