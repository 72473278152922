/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect, useMemo, memo } from "react";
import { StyledPagination } from "./Pagination.styles";
import { Row as InternalRow } from "./Row/";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList as List } from "react-window";
import { useSelector } from "react-redux";

// const LOADING = 1;
const LOADED = 2;
const TRICK_SHADOW_MARK = 2;
const ZERO = 0;

const Pagination = ({ title, dataSet = [], onClick, isVertical }) => {
  let listRef = useRef();

  const { network } = useSelector((state) => state);
  const [preDataSet, setPreDataSet] = useState([...dataSet]);
  const [fetching, setFetching] = useState(false);

  const [mDataSet, setMdataSet] = useState([...preDataSet.slice(ZERO, 10)]);
  const [halt] = useState(false);
  const [lastLoadedIndex, setLastLoadedIndex] = useState(ZERO);

  useEffect(() => {
    if (!fetching) {
      listRef.current.scrollTo(
        listRef.current.scrollLeft - TRICK_SHADOW_MARK,
        0,
      );
    }
  }, [fetching]);

  const apiCall = network?.call;
  let itemStatusMap = {};

  const isItemLoaded = (index) => !!itemStatusMap[index];

  const loadMoreItems = (startIndex, stopIndex) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        return resolve();
      }, 0);
    });
  };

  const Row = ({ index, style }) => {
    return (
      <InternalRow
        index={index}
        style={style}
        itemStatusMap={itemStatusMap}
        dataSet={mDataSet}
        onClick={onClick}
        isVertical={isVertical}
      />
    );
  };

  const appendToListFromLocal = () => {
    const sliced = [...preDataSet.slice(mDataSet.length, mDataSet.length + 20)];
    if (sliced.length > ZERO) {
      const tobeAdded = [...mDataSet, ...sliced];
      setMdataSet(tobeAdded);
    }
  };

  const appendToLocalListFromApi = () => {
    if (
      !fetching &&
      visibleStopIndex > lastLoadedIndex - 2 &&
      lastLoadedIndex != ZERO &&
      !halt
    ) {
      setFetching(true);
      apiCall
        .fetchAllContentByType(
          preDataSet[0].itemType,
          preDataSet.length,
          preDataSet.length + 20,
        )
        .then((res) => {
          setPreDataSet(preDataSet.concat(res.response));
          setFetching(false);
        });
    }
  };

  const itemSize = useMemo(() => {
    if (window.innerWidth <= 320) return window.innerWidth / 2 - 5;
    if (window.innerWidth > 320 && window.innerWidth < 390)
      return window.innerWidth / 3 - 2;
    if (window.innerWidth >= 390 && window.innerWidth < 414)
      return window.innerWidth / 3.8 + 14;
    if (window.innerWidth >= 414 && window.innerWidth < 500)
      return window.innerWidth / 3.9 + 45;
    if (window.innerWidth >= 500) return window.innerWidth / 5;
  }, []);

  return (
    <StyledPagination>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={mDataSet.length}
        minimumBatchSize={10}
        loadMoreItems={loadMoreItems}>
        {({ ref }) => (
          <List
            key={title}
            className="List"
            style={{ overflowY: "hidden" }}
            height={180}
            outerRef={listRef}
            itemCount={mDataSet.length}
            itemSize={itemSize}
            // itemSize={((window.innerWidth  / 3 ) -17 )}
            onItemsRendered={(prop) => {
              if (prop.visibleStopIndex === mDataSet.length - 1) {
                appendToListFromLocal();
              }
            }}
            ref={ref}
            direction="horizontal"
            width={window.innerWidth}>
            {memo(Row)}
          </List>
        )}
      </InfiniteLoader>
    </StyledPagination>
  );
};

export default memo(Pagination);
