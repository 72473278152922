import styled from "styled-components";
import {motion} from "framer-motion";

export const StyledProfile = styled(motion.div)`

padding-bottom: 64px;

.profile-header {
  margin: 24px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}
.dropdown-span:after {
  content: url('/images/Vector-4.png');
  color: black;
  /* background: url('/images/Vector-4.png'); */
  display: inline-block;
  transform: scale(0.5);
  height: 5px;
    width: 5px;
    position: relative;
    left: 89%;
    bottom: 30px;
}

.intro {
    margin: 24px;
    text-align: center;

    h1 {
      font-size: 24px;
      margin-bottom: 24px;
    }

    span {
      font-family: MTNBrighterSansRegular;
    }
}

.input-wrapper {
  margin: 24px;
}

.input-before-text {
  font-family: MTNBrighterSansRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 9px;
  margin-bottom: 8px;
}

.input {
  font-family: MTNBrighterSansRegular;
  padding: 12px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 4px;
  background: transparent;
  min-width: 272px;
  width: 100%;
  color: #FFFFFF;
  margin-bottom: 12px;
  font-family: MTNBrighterSansRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #BDBDBD;

}

.select-input {
  font-family: MTNBrighterSansRegular;
  padding: 12px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 4px;
  background: #FFFFFF;
  -webkit-appearance: none;
  -moz-appearance: none;
  min-width: 272px;
  width: 100%;
}

.input-error-text {
  font-family: MTNBrighterSansRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 9px;
  color: #DC6428;

}

.buttons {
  margin: 24px -24px -24px;
}

`;

export default StyledProfile;