import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledCloseBtn = styled(motion.div)`
background-image: ${({isCheckable}) => isCheckable===true ? "url('/images/icons/check_green.svg')" : "url('/images/close_modal_btn.svg')"};	  
position: fixed;
color: wheat;
bottom: 100px;
height: 48px;
width: 48px;
`;

export const StyledSubTitle = styled(motion.div)`
color: #FFFFFF; 
font-family: MTNBrighterSansRegular;
font-weight: 900;
font-size: 20px;
line-height: 25px;
width: 100%;
justify-content: center;
display: flex;
align-items: center;
margin-bottom: 26px;
`;

export const StyledModalTitle = styled(motion.div)`
  font-family: MTNBrighterSansRegular;
  font-size: 20px;
  height: 40px;
  width: 100%;
  justify-content: center;
  color: ${({isSelected}) => isSelected ? "black" : "white"};	  
  font-weight: normal;
  line-height: 21px;
  display: flex;
  align-items: center;
  background-color: ${({isSelected}) => isSelected ? "white" : "transparent"};	  
`;

export const StyledModal = styled(motion.div)`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  ${StyledModalTitle}:hover & {
  display: none;
  };
`;

export const StyledModalOverlay = styled(motion.div)`
  align-items: center;
  align-self: center;
  align-content: center;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 99999999;
 
  left: 0;
  min-width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  top:0;
  bottom: 0;
  overflow: scroll;
  background-color: rgba(0, 0, 0, 0.87);
`;