// import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { TopNav, Page, SubMenuItem, ActiveSubscriptionInfo } from "../../shared/components";

const PendingSubscriptionScreen = () => {

	const {subscriptions} = useSelector((state) => state);
	const selectedItem = subscriptions?.selectedItem;
	// const {network} = useSelector((state) => state);

	return (
    <Page isBottomBar={false}>
      <TopNav enableSearch={false} title={`${selectedItem?.vendor?.title}`} />
      <SubMenuItem disabled={true} item={selectedItem} />
      <ActiveSubscriptionInfo info={`${selectedItem?.vendor?.slogan}`} />
    </Page>
  );
};

export default PendingSubscriptionScreen;
