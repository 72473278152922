import {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import { StyledBottomPopup } from "../../shared/components/BottomBar/popup";
import close from "../../assets/images/close.png";

const SubScriptionStatePopUpScreen = () => {
	const [modalPending, setModalPending] = useState(false);

	const {language} = useSelector((state) => state);
	const {
		isConfirmed,
		paymentStatusInfo,
	} = useSelector((state) => state?.subscriptions);

	useEffect(() => {
		if(paymentStatusInfo?.Message?.statusCode == "0000") {
			setModalPending(true);
		}

		if(paymentStatusInfo?.Message?.statusCode == "2032" || paymentStatusInfo?.Message?.statusCode == "2084" || paymentStatusInfo?.Message?.statusCode == "1012") {
			setModalPending(false);
		}
	}, [paymentStatusInfo, isConfirmed]);


	const switchPendingState = () =>{
		setModalPending(false);
		localStorage.setItem("pending-state-closed", true);
	};

	return (
		modalPending ? <StyledBottomPopup>
			<p style={{fontSize:14}}>{language?.chosenPack?.language?.COMPONENTS.success_alert}.</p>
			<img  onClick={() => switchPendingState()}  alt="" width="19" height="19" src={close}/> 
		</StyledBottomPopup> : <></>
	);
};

export default SubScriptionStatePopUpScreen;
