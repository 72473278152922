import styled from "styled-components";
import {motion} from "framer-motion";

export const StyledMenu = styled(motion.div)`

.menu-item {
    margin: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
}

.title {
   
}

.menu-icon {
   background-image: url('/images/icons/right_chevron.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.icon {
    height: 24px;
    width: 24px;
}

.menu-details {
    display: flex;
    flex-direction: column;
}
	  
.description {
  font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

`;

export default StyledMenu;