import { filtered } from "../types";

const initialState = {
	loading: false,
	items: [],
	error: null
};

const filteredReducer = (state = initialState, action) => {
	switch (action.type) {
	case filtered.FILTERED_ITEMS_REQUEST:
		return {
			...state,
			loading: true,
			error: null
		};
	case filtered.FILTERED_ITEMS_SUCCESS:
		return {
			...state,
			loading: false,
			error: null,
			items: action.payload
		};
	case filtered.FILTERED_ITEMS_ERROR:
		return {
			...state,
			loading: false,
			error: action.payload
		};
	default:
		return state;
	}
};

export default filteredReducer;