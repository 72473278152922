import { StyledItemImageView } from "./ItemImageView.styles";

const ItemImageView = ({image}) => {
	return (
		<StyledItemImageView image={image}>
			
		</StyledItemImageView>
	);
};

export default ItemImageView;
