import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledPartnerSubscriptionInfo = styled(motion.div)`
  padding: 0 24px;
  min-height: 256px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const StyledPartnerSubscriptionInfoNotes = styled(motion.div)`
  font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  line-height: 28.13px;
  display: flex;
  align-items: center;
  text-align: center;
`;