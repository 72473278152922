import { useMemo } from "react";
import { LandingCategoryItem } from "../../LandingCategoryItem";

const Row = ({ index, style, dataSet = [], onClick, isVertical }) => {
  const mStyle = { ...style, width: style.width - 12 };
  // const LOADED = 2;
  // const LOADING = 1;

  // let label = undefined;
  // if (itemStatusMap[index] === LOADING) {
  // 	label = "Loading...";
  // }
  const item = useMemo(
    () => (
      <div style={mStyle}>
        <LandingCategoryItem
          item={dataSet[index]}
          onClick={() => onClick(dataSet[index])}
          isVertical={isVertical}
        />
      </div>
    ),
    [],
  );

  return item;

  // return (
  // 	<div style={mStyle}>
  // 		{label ?
  // 			<div className="ListItem" style={{display: "inline-grid",
  // 				justifyContent: "center",
  // 				alignItems: "center",
  // 				width: "100%",
  // 				height: "100%"}}>
  // 				{label}
  // 			</div>
  // 			: <LandingCategoryItem item={dataSet[index]} onClick={()=>onClick(dataSet[index])} isVertical={isVertical}/>}
  // 	</div>
  // );
};

export default Row;
