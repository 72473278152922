import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledPartnerBranding = styled(motion.div)`
 margin: 32px 0px;
 width: 100%;
 display: flex;
 align-items: center;


 .logo {
	 height: 40px;
	 width: 40px;
	 background-image: ${({ image }) => image ? `url(${image})` : "url(/images/6061412-2.png)"};
	 background-position: center;
     background-repeat: no-repeat;
     background-size: contain;
	 margin-right: 12px;
 }

 .info {
	font-size: 12px;
	 font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
	 font-weight: 700;
	 font-style: normal;
	 line-height: 14.06px;
	 display: flex;
	 flex-direction: column;

	 .welcome-action{
		font-size: 16px;
		margin-bottom: 5px;
	 }

	 .sub-info {
		font-size: 14px;
		 font-weight: 400;
		 opacity: 0.6;
	 }
 }

`;
