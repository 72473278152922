import { authentication } from "../types";

const initialState = {
	loading:false,
	msg:"",
	msgResend:"",
	error: false,
	otpError: ""
};

const authenticationReducer = (state = initialState, action) => {
	switch (action.type) {
	case authentication.ON_API_CALL:
		return {
			...state,
			loading: true,
			msg: action.payload,
			otpError: "",
			error: false
		};
	case authentication.ON_API_CALL_ERROR:
		return {
			...state,
			loading: false,
			error: true,
			msg: action.payload
		};
	case authentication.ON_RESEND_API_CALL_ERROR:
		return {
			...state,
			loading: false,
			error: true,
			msgResend: action.payload
		};
	case authentication.REQUEST_OTP:
		return {
			...state,
			loading: false,
			msg: action.payload
		};
	case authentication.RE_REQUEST_OTP:
		return {
			...state,
			loading: false,
			msg: "",
			msgResend: action.payload
		};
	case authentication.CONFIRM_OTP:
		return {
			...state,
			loading: false,
			msg: action.payload
		};
	case authentication.CLEAR_AUTH:
		return {
			...state,
			loading:false,
			msg:"",
			error: false,
			otpError: "",
			msgResend: "",
		};

	case authentication.ON_COMFIRM_OTP_API_CALL_ERROR:
		return {
			...state,
			loading: false,
			otpError: action.payload,
			error: true
		};

	default:
		return state;
	}
};

export default authenticationReducer;