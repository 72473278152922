import {useRef, useEffect, useState} from "react";
import ReactPlayer from "react-player";
import {PlayZone, StyledModalMusicHeaderController, StyledModalMusicBodyController, StyledModalMusicFooterController, StyledModalMusicOverlay, StyledPlayControlBar, StyledThreeController, StyledModalOverlay, ProgressController} from "./PlayControlBar.styles";
import "react-spring-bottom-sheet/dist/style.css";

const PlayPlayer =({deviceType, source, contentType, title, imageUrl, onClose})=>{
	/**We're making overflow hiden as soon as we enter/mount in here, this prevents video player and it's controller arrangement disturtion*/
	document.body.style.overflow = "hidden";

	const samples = [source, "https://download.samplelib.com/mp3/sample-12s.mp3",
		"https://www.w3schools.com/html/mov_bbb.mp4",
		"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4",
		"https://media.istockphoto.com/videos/chickens-on-the-grass-video-id473151545",
		"https://media.istockphoto.com/videos/young-family-having-fun-in-the-kitchen-video-id852481048"];
  
	const ref = useRef(null);
	// const wrapper = useRef(null);
	const [playing, setPlaying] =  useState(false);
	const [duration, setDuration] =  useState(0);
	const [seekInterval, setSeekInterval] = useState(0);
	// const [seek, setSeek] = useState(0);
	const [playState, setPlayState] = useState(null);
	const [setIsPlaying] = useState(false);
	const [played, setPlayed] = useState(0);
	const [seeking, setSeeking] = useState(false);
	const [fadeOut, setFadeOut] = useState(false);
	const [repeat, setRepeat] = useState(false);
	const isMusic = contentType == "music" || `${source}`.endsWith(".mp3");
	const [media, setMedia] = useState({src:samples[0], index:0});
	const [openPlayList, setOpenPlayList] = useState(false);
	const [isShuffle, setIsShuffle] = useState(false);

	useEffect(() => {
		/**We're making overflow visible again for other screens as soon as we exit/unmount from here.*/
		return () => {
			document.body.style.overflow = "visible";
		};
	},[]);

	useEffect(() => {
		try {
			if(isMusic == false && deviceType!="iOS") toggleScreenLandscapeFullScreen(true);
		} catch (error) {
			console.log(error);
		}
	}, []);

	useEffect(()=>{
		setSeekInterval((0.999999 / duration));
	}, [duration]);

	const toggleScreenLandscapeFullScreen = async (toggle) => {
    if (toggle) {
      await document.body.requestFullscreen();
      await screen.orientation.lock("landscape");
    } else {
      await screen.orientation.lock("portrait");
      await document.exitFullscreen();
    }
  };

	const handleClose = ()=>{
		toggleScreenLandscapeFullScreen(false);
		onClose();
	};

	const handleOnReady = () => {};

	// const handleClickFullscreen = () => {
	// 	screenfull.request(ref.current?.wrapper);
	// };

	const handleClickTogglePlay = () => {
		setPlaying(!playing);
	};

	const handleClickRepeat = () => {
		setRepeat(!repeat);
		setIsShuffle(false);
	};

	const handleClickRewind = () => { winder(false); };

	const winder = (forward) =>{
		let nextSeek = forward ? ((seekInterval*10)) : (-(seekInterval*10));
		nextSeek+=playState?.played;

		ref.current?.player.seekTo(nextSeek, "fraction");
	};

	const handleClickForward = () => { winder(true); };

	const handleSeekMouseDown = () => {
		setSeeking(true);
	};

	const handleSeekChange = (e) => {
		setPlayed(parseFloat(e.target.value));
	};

	const handleSeekMouseUp = (e) => {
		setSeeking(false);
		ref.current?.player.seekTo(parseFloat(e.target.value));
	};

	const handleProgress = (state) => {
		if(state.playedSeconds >= state.loadedSeconds && isShuffle){
			const nextIndex = randomInteger(0, (samples.length-1));
			setMedia({src:samples[nextIndex], index:nextIndex});
		}

		// if(playing && (!fadeOut)){
		//   setTimeout(()=>{
		//     setFadeOut(true);
		//   }, 2000);
		// }else if(!playing){
		//   setFadeOut(false);
		// }

		setTimeout(()=>{
			if(playing && (!fadeOut)){
				setFadeOut(true);
			}else if(!playing){
				setFadeOut(false);
			}
		}, 2000);

		if(!seeking){
			setPlayState(state);
			setPlayed(state.played);
		}

	};

	const randomInteger = (min, max) =>{
		return Math.floor(Math.random() * (max - min + 1)) + min;
	};

	const handleClickNext = () => {
		let _media = {...media};

		let index = _media.index;
		if(index<(samples.length-1))
			index+=1;
		else
			index=0;

		_media = {src:samples[index], index:index};
		setMedia({..._media});
	};

	const handleClickPrevious = () => {
		let _media = {...media};

		let index = _media.index;
		if((index-1)>0)
			index=index-1;
		else
			index=0;

		_media = {src:samples[index], index:index};
		setMedia({..._media});
	};

	const handleClickShuffle = () => {
		setIsShuffle(!isShuffle);
		setRepeat(false);
	};

	const handleOpenPlayList = () => {
		setOpenPlayList(!openPlayList);
	};

	const Controller = () =>{
		return isMusic ? <StyledModalMusicOverlay>

			<StyledModalMusicHeaderController>
				<div onClick={(e)=>{ e.preventDefault(); console.log("???");}} className="icon more"/>
				<div onClick={onClose} className="icon down"/>
			</StyledModalMusicHeaderController>

			<StyledModalMusicBodyController src={imageUrl}>

				<div className="thumbnail"/>

				<div className="title">
					<p>{title}</p>
					<p>Music, Pop</p>
				</div>

			</StyledModalMusicBodyController>

			<StyledModalMusicFooterController isShuffle={isShuffle} isRepeat={repeat} isPlaying={ref.current?.player?.isPlaying}>
				<input
					type="range" min={0} max={0.999999} step="any"
					value={played}
					onMouseDown={handleSeekMouseDown}
					onChange={handleSeekChange}
					onMouseUp={handleSeekMouseUp}
					style={{width:"100%"}}
				/>

				<div className="timeStamp">
					<Duration className={"timeElapsed"} seconds={duration * played}/>
					<Duration className={"timeLeft"} seconds={duration-(playState?.playedSeconds)}/>
				</div>

				<div className="controls">
					<div className="shuffle" onClick={handleClickShuffle}/>
					<div className="previous" onClick={handleClickPrevious}/>
					<div className="playPause" onClick={handleClickTogglePlay}/>
					<div className="next" onClick={handleClickNext}/>
					<div className="repeat" onClick={handleClickRepeat}/>
				</div>

				<div className="playListParent">
					<div onClick={handleOpenPlayList} className="playList"/>
				</div>

				{/* <BottomSheet open={openPlayList} onDismiss={handleOpenPlayList}>
          <MusicPlayList/>
        </BottomSheet> */}

			</StyledModalMusicFooterController>

		</StyledModalMusicOverlay> 
      
			: 

			<StyledModalOverlay onTouchStart={()=>setFadeOut(false)} fadeOut={fadeOut}>
				<StyledPlayControlBar>
					<div className="titles_parent">
						<div onClick={handleClose} className="icon close"/>

						<div className="titles">
							<span className="title"><p>{title}</p></span>
						</div>
					</div>

					<StyledThreeController isPlaying={playing}>
						<div onClick={handleClickRewind} className="icon rewind"/>
						<div onClick={handleClickTogglePlay} className="icon play"/>
						<div onClick={handleClickForward} className="icon forward"/>
					</StyledThreeController>

        
					<ProgressController>
						<input
							type="range" min={0} max={0.999999} step="any"
							value={played}
							onMouseDown={handleSeekMouseDown}
							onChange={handleSeekChange}
							onMouseUp={handleSeekMouseUp}
							style={{width:"100%"}}
						/>

						<Duration seconds={duration * played}/>
						{/* <div onClick={handleClickFullscreen} className="icon fullscreen"/> */}
        
					</ProgressController>

				</StyledPlayControlBar>

        
			</StyledModalOverlay>;
	};

	return (
		<PlayZone>
			{(deviceType=="iOS" && !isMusic) ? null : <Controller/>}
			<ReactPlayer
				loop={repeat}
				onPlay={setIsPlaying} 
				onProgress={handleProgress} 
				onDuration={setDuration} 
				onReady={handleOnReady} 
				playing={playing} 
				ref={ref}
				url={media.src} 
				muted={false} 
				controls={deviceType=="iOS" && !isMusic}
			/>
		</PlayZone>
	);
};

export default PlayPlayer;

export function Duration ({ className, seconds }) {
	return (
		<time dateTime={`P${Math.round(seconds)}S`} className={className}>
			{format(seconds)}
		</time>
	);
}

function format (seconds) {
	const date = new Date(seconds * 1000);
	const hh = date.getUTCHours();
	const mm = date.getUTCMinutes();
	const ss = pad(date.getUTCSeconds());
	if (hh) {
		return `${hh}:${pad(mm)}:${ss}`;
	}
	return `${mm}:${ss}`;
}

function pad (string) {
	return ("0" + string).slice(-2);
}