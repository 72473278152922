// import React from "react";
import { StyledBanner, StyledBannerMbks } from "./Ads.styles";

const Ads = ({data,form}) => {
	if(!data) return(<></>);

	const onClickOpen = () => {
		window.open(data.data.link, "_blank");
	};

	return (
		form?
		<StyledBannerMbks isOpacity={data?.data?.enableOverlay} image={data?.imgUrl} onClick={onClickOpen}>
	
		</StyledBannerMbks>
		:
		<StyledBanner isOpacity={data?.data?.enableOverlay} image={data?.imgUrl} onClick={onClickOpen}>
	
		</StyledBanner>
	);
};

export default Ads;
