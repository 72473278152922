import styled from "styled-components";
import {motion} from "framer-motion";

export const StyledHeaderTabs = styled(motion.div)`
 min-height: 32px; 
 background-color:#000;
 display: flex;

 .tab {
	 flex: 1;
	 text-align: center;
	 text-transform: uppercase;
	 font-family: MTNBrighterSansRegular;
	 font-size: 8px;
	 

	 &.active {
		 border-bottom: 1px solid #FBC800;
		 color: #FBC800;

	 }
 }

`;