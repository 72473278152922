import { StyledPartnerInfo } from "./PartnerInfo.styles";
import {PartnerBranding, SubscriptionDetails} from "..";
// import React, {} from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import HelperFunctions from "../../../utils/HelperFunctions";

const PartnerInfo = ({item}) => {
  const { selectedItem } = useSelector((state) => state.subscriptions);

  const icon = new HelperFunctions().sslIcon(selectedItem?.vendor?.logo);

  return (
    <StyledPartnerInfo>
      <PartnerBranding
        image={icon}
        providerSlogan={
          item?.vendor?.slogan !== undefined
            ? item?.vendor?.slogan
            : "Provided by MTN Play"
        }
      />

      {(item?.subscriptionType === "subscription" ||
        item?.subscriptionType === "once-off") && (
        <>
          {selectedItem?.subscribed === "Pending" && (
            <PendingState>
              <div>{"This subscription is currently activating"}</div>
              {/* <div onClick={() =>{
							dispatch(onSubscriptionStatusPositionState(2))
							navigate(PWA_ROUTES.SUBSCRIPTIONS)
						}
						}>
						{"VIEW"}
						</div> */}
            </PendingState>
          )}

          {selectedItem?.subscribed !== "Pending" && (
            <div>
              <SubscriptionDetails />
            </div>
          )}
        </>
      )}
    </StyledPartnerInfo>
  );
};

const PendingState = styled.div`
	display: flex;
	justify-content: space-between;

	color: #DC6428;
	padding: 20px;


	height: 50px; 
	width:100%;

	align-self: center;


	align-items: center;
	margin-top: auto;
	// position: fixed;
	bottom: 80px;
	z-index: 999;
	border-radius: 10px;
	top: 0;
	left: 0;
`;

export default PartnerInfo;