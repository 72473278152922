import  {useEffect} from "react";
import {Page, TopNav} from "../../shared/components";
import {Profile} from "../../shared/components/Profile";
import {ProfileScreen as events} from "../../analytics/events";
import {updateProfile} from "../../utils/redux/actions/profile.actions";
import { useSelector, useDispatch } from "react-redux";

const ProfileScreen = () => {
	const dispatch = useDispatch();
	const { language } = useSelector((state) => state);
	const {network} = useSelector((state) => state);

	useEffect(() => {
		dispatch({
			type: "ANALYTICS/TRACK_EVENT",
			event: events.profile_screen(
				network?.call?.headers?.isoRegion,
				network?.call?.headers.environment
			),
		});
	}, []);

	const doUpdateProfile = (userData) =>{
		dispatch(updateProfile(userData));
	};

	return (
    <Page isBottomBar={false}>
      <TopNav title={ language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections?.my_profile} centerTitle={true} enableSearch={false} />
      <Profile doUpdate={doUpdateProfile} />
    </Page>
  );
};

export default ProfileScreen;
