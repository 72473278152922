import { StyledLandingCategoryItem } from "./LandingCategoryItem.styles";
import { useLocation } from "react-router-dom";
import EllipsisText from "react-ellipsis-text";
import { useSelector } from "react-redux";
// import HelperFunctions from "../../../utils/HelperFunctions";
import { useRef } from "react";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";

const LandingCategoryItem = ({ item, onClick, isVertical, isSearchResult }) => {
  const { language } = useSelector((state) => state);
  const ref = useRef();

  const entry = useIntersectionObserver(ref, {
    freezeOnceVisible: true,
    threshold: 0,
  });
  const isVisible = !!entry?.isIntersecting;

  let genre = "";

  // const icon = new HelperFunctions().sslIcon(item?.vendor?.logo);

  if (
    item?.genre !== null &&
    item?.genre !== undefined &&
    item?.genre.length !== 0
  ) {
    let index = 0;
    item?.genre?.forEach((mGenre) => {
      if (index === 0) {
        genre = mGenre;
      } else {
        genre = genre + " · " + mGenre;
      }

      index++;
    });
  }

  const data = { title: item?.title, age: item?.age, genre: `${genre}` };

  const location = useLocation();

  return (
    <StyledLandingCategoryItem
      ref={ref}
      isVertical={isVertical}
      isSearchResult={isSearchResult}
      id={`key${item?.itemType}${item?.title}`}
      item={item}
      isVisible={isVisible}
      className={`item ${isSearchResult ? "is-search-result" : ""}`}>
      <div id="item@home" onClick={onClick}>
        <div className="thumbnail">
          {/* {!isVisible && (
            <span
              style={{
                fontSize: "12px",
                position: "absolute",
                top: "34%",
                justifySelf: "center",
                margin: "auto 0",
                display: "block",
                textAlign: "center",
                width: "100%",
              }}>
              loading...
            </span>
          )} */}
          {item.subscriptionType === "subscription" &&
            item?.subscribed !== "Pending" &&
            item?.subscribed !== "Active" &&
            location.pathname !== "/myplay" && (
              <div className="tag-description-sub">
                {
                  language?.chosenPack?.language?.COMPONENTS?.content_cards
                    ?.subscription_type_subscribe
                }
              </div>
            )}
          {item.subscriptionType === "free" &&
            location.pathname !== "/myplay" && (
              <div className="tag-description-free">FREE</div>
            )}
          {item.subscriptionType === "once-off" &&
            item.subscribed !== "Pending" &&
            item.subscribed !== "Active" &&
            location.pathname !== "/myplay" && (
              <div className="tag-description-once">BUY</div>
            )}
          {(item.subscriptionType === "subscription" ||
            item.subscriptionType === "once-off" ||
            item.subscriptionType === "free") &&
            item.subscribed === "Pending" &&
            location.pathname !== "/myplay" && (
              <div className="tag-description-pending">ACTIVATING</div>
            )}

          {(item.subscriptionType === "subscription" ||
            item.subscriptionType === "once-off" ||
            item.subscriptionType === "free") &&
            item.subscribed === "Active" &&
            location.pathname !== "/myplay" && <></>}
        </div>
        <div className="tag">

          {/* <div style={{ marginTop: 2, display: "flex", justifyContent: "flex-end" }}>
            <img alt="" height="11" src={isVisible ? icon : ""} />
          </div> */}

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p className="tag-desc">
                <EllipsisText text={data?.title} length={12} />
              </p>
              <div className="tag-sub-desc">
                <EllipsisText
                  text={`${data?.genre === "Drama"
                    ? language?.chosenPack?.language?.list_of_genres
                      ?.genre_drama
                    : data?.genre
                    }`}
                  length={15}
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </StyledLandingCategoryItem>
  );
};

export default LandingCategoryItem;
