import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";
import {rootReducer} from "./reducers";
import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage/session";

const persistConfig = {
  key: "mtnplay-pwa",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
	persistedReducer,
	composeWithDevTools(applyMiddleware(thunk)),
);

export const persistor = persistStore(store);

const StoreExports = () => {
	return {
		store,
		persistor,
	};
};

export default StoreExports;
