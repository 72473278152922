import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TopNav, Page,Border, SubMenuItem, ActiveSubscriptionInfo, ModalButtons, ModalText, Modal } from "../../shared/components";
import { ActiveSubscriptionActions } from "../../shared/components/ActiveSubscriptionActions";
import { useNavigate } from "react-router-dom";
import { PWA_ROUTES } from "../../constants/appConstants";

import styled from "styled-components";
import {motion} from "framer-motion";
import { resetUnsubscription} from "../../utils/redux/actions/subscription.action";
import {CheckoutScreen as events} from "../../analytics/events";
import { ContentInfoScreen as eventsCombine } from "../../analytics/events";
// import { FETCH_BALANCES_SUCCESS } from '../../utils/redux/types/subscriptions.types';

const ActiveSubscriptionScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// const [showUnScubscribeModal, setShowUnScubscribeModal] = useState(false);

	 const {
		error,
	  } = useSelector((state) => state?.items);

	const {
		selectedCharge,
	} = useSelector((state) => state?.subscriptions);
  const { language } = useSelector((state) => state);

	const { subscriptions } = useSelector((state) => state);
  const { error: subsError } = subscriptions;

  const [redirectUrl, setRedirectUrl] = useState(undefined);
  const [showModal, setShowModal] = useState(false);

  const [redirectUrlPass, setRedirectUrlPass] = useState(undefined);

  const selectedItem = subscriptions?.selectedItem;
  const unsubscribeObj = subscriptions?.unsubscribe;

  const {network} = useSelector((state) => state);
  const { profile } = useSelector((state) => state);

  useEffect(() => {
    if (subscriptions?.unsubRequest) {
      setShowModal(false);
    }

    if (unsubscribeObj !== "INITIAL_STATE") {
      // setShowModal(false);
      // setShowUnScubscribeModal(true);


      dispatch({
        type: "ANALYTICS/TRACK_EVENT",
        event: events.unsubscribe(
          network?.call?.headers?.isoRegion,
          network?.call?.headers?.environment,
          selectedCharge?.price,
          selectedItem?.vendor?.title,
        ),
      });
    } else {
      //setShowUnScubscribeModal(false);
    }
  }, [unsubscribeObj]);

  useEffect(() => {
    if (error !== null || subsError !== null) {
      // dispatch(clearSubscription());
      navigate(PWA_ROUTES.GENERIC_ERROR);
    }
  }, [error, subsError]);

  useEffect(() => {
    //setShowUnScubscribeModal(false);
    setShowModal(false);
    if (
      !selectedItem.internalPlay &&
      selectedItem.subscribed != "Inactive" &&
      selectedItem.subscribed != "0000" &&
      !redirectUrl
    ) {
      //	dispatch(getRedirect(network?.call, selectedItem?.billingTypes ? selectedItem?.billingTypes[0] : selectedItem?.billingType ));
    //   let a = new URL(selectedItem.url);
    //   a.searchParams.set("msisdn", profile?.data?.msisdn);
    //   setRedirectUrlPass(a.toString());

	  	if (selectedItem.homeUrl.includes("?")) {
        setRedirectUrlPass(
          selectedItem?.homeUrl +
            "&msisdn=" +
            String(profile?.data?.msisdn).replace("+", "").trim(),
        );
				} else {
					try {
            let a = new URL(selectedItem?.homeUrl);
            a.searchParams.set(
              "msisdn",
              String(profile?.data?.msisdn).replace("+", "").trim(),
            );
            setRedirectUrlPass(a.toString());
          } catch (e) {
            setRedirectUrlPass("");
          }
      }

    }

    if (selectedItem == null) {
      navigate(PWA_ROUTES.HOME);
      return;
    }

    setShowModal(false);
  }, []);

  useEffect(() => {
    if (redirectUrlPass !== undefined) {
      setRedirectUrl(redirectUrlPass);
    }
  }, [redirectUrlPass]);

  const InfoModal = () => {
    return (
      <div>
        <Modal
          onClose={() => {}}
          show={unsubscribeObj !== "INITIAL_STATE" ? true : false}
          title={"Unsubscribe"}>
          <ModalText
            firstSentence={
              "You have unsubscribed from this package.\nYou can resume your subscription later from the Inactive tab on the Subscription Management screen."
            }
          />
          <ModalButtons
            okButtonText={"Ok"}
            onClose={() => {
              //setShowUnScubscribeModal(false);
              setShowModal(false);
              dispatch(resetUnsubscription());
              navigate(PWA_ROUTES.HOME);
            }}
          />
        </Modal>
      </div>
    );
  };

	const InfoModalLoading = () => {
		return <div>
			<Modal
				onClose={() => {}}
				show={subscriptions?.unsubRequest}
				title={<StyledButtonLoading></StyledButtonLoading>}>
				{/* <ModalText
        firstSentence={"unsubscribing..."}
      /> */}
				<div>
					<ModalButtons
						okButtonText={""}
            position={""}
						onClose={() => {
							setShowModal(false);
						}}
					/>
				</div>
			</Modal>
		</div>;
	};

	const analyticsWatch = () => {
		dispatch({
			type: "ANALYTICS/TRACK_EVENT",
			event: eventsCombine.play_content_manage(
        network?.call?.headers?.isoRegion,
        network?.call?.headers?.environment,
				selectedItem?.vendor?.id,
				selectedItem?.vendor?.title,
			),
		});
	};


	const SubscribeSection = ({text, link, activate=false}) =>{
		
		return (
      <StyledSubscribeSection>
        <div
          style={{
            textDecoration: "none",
            color: "#FFFFFF",
            width: "100%",
            marginLeft: "24px",
            marginRight: "24px",
          }}>
          <a
            onClick={() => {
              analyticsWatch();
            }}
            style={{
              borderRadius: 40,
              height: 40,
              backgroundColor: `${link && activate ? "#FFCB05" : "grey"}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#000",
            }}
            disabled={link ? false : true}
            href={link}
            target="_blank"
            rel="noopener noreferrer">
            {text}
          </a>
        </div>
      </StyledSubscribeSection>
    );
	};

	return (
    <Page isBottomBar={false}>
      <TopNav enableSearch={false} title={`${selectedItem?.vendor?.title}`} />
      <SubMenuItem item={selectedItem} />
      <ActiveSubscriptionInfo info={`${selectedItem?.vendor?.slogan}`} />
      <Border />
      <InfoModal />

      <InfoModalLoading />
      <div>
        <Modal
          onClose={() => setShowModal(false)}
          show={showModal}
          title={"Are you sure you want to unsubscribe?"}>
          <ModalText
            firstSentence={"You can resume later on the subscription screen."}
          />
          <ModalButtons
            confirmIn={() => {
              setShowModal(false);
            }}
            onClose={() => {
              setShowModal(false);
            }}
            position={""}
          />
        </Modal>
      </div>

      {
        redirectUrl !== undefined && redirectUrl !== null ? (
          <div style={{ marginTop: 5, marginBottom: 5 }}>
            <p
              style={{
                color: "#ffff",
                width: "100%",
                textAlign: "center",
                padding: 10,
              }}>
                {language?.chosenPack?.language?.COPY?.SCREENS?.contentInfo?.new_window_open}
            </p>

            {selectedItem?.billingType?.punsub === true && (
              <p
                style={{
                  color: "#DC6428",
                  width: "100%",
                  textAlign: "center",
                  padding: 10,
                }}>
                Subscription ends on the{" "}
                {selectedItem?.billingType?.subscriptionEndDate}
              </p>
            )}

            <SubscribeSection
              text={"PLAY NOW"}
              link={redirectUrl}
              activate={true}
            />
          </div>
        ) : (
          <StyledSubscribeSection>
            <div
              style={{
                textDecoration: "none",
                color: "#ffff",
                width: "100%",
                marginLeft: "24px",
                marginRight: "24px",
              }}>
              <a
                style={{
                  borderRadius: 40,
                  height: 40,
                  backgroundColor: "grey",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#000",
                }}
                href={redirectUrl}
                target="_blank"
                rel="noopener noreferrer">
                <StyledButtonLoading></StyledButtonLoading>
              </a>
            </div>
          </StyledSubscribeSection>
        )
        // <SubscribeSection>
        //   <div  style={{textDecoration: 'none', color: '#ffff', width: '100%', marginLeft: '24px', marginRight:'24px'}}>
        //     <a  style={{ borderRadius: 40, height: 40, backgroundColor: 'grey', display: 'flex', justifyContent: 'center',  alignItems:'center', color:'#000'}} href={redirectUrlObj?.redirect_to} target="_blank" rel="noopener noreferrer"> <StyledButtonLoading></StyledButtonLoading></a>
        //     </div>
        // </SubscribeSection>
      }

      <Border />
      {selectedItem?.billingType?.punsub === false && (
        <ActiveSubscriptionActions
          isOutline={true}
          caption="unsubscribe"
          clickHandler={() => {
            setShowModal(true);
          }}
        />
      )}

      {/* <ActiveSubscriptionActions caption={`Watch On ${selectedItem?.vendor.title}`} clickHandler={() => {
				
			}} /> */}
    </Page>
  );
};

export const StyledSubscribeSection = styled(motion.div)`
	min-height: 64px;
	width: 100vw;
	background: #000;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: auto;
`;

const StyledButtonLoading = styled.div`
width: 30px;
height: 30px;
border: 6px solid ${({ primary }) => (primary ? "#fff" : "#FFCB05")};
border-bottom-color: transparent;
border-radius: 45px;
display: flex;
box-sizing: border-box;
justify-content: center;
align-items: center;
align-self: center;

animation: rotation 1s linear infinite;
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`;

export default ActiveSubscriptionScreen;
