import styled from "styled-components";
import {motion} from "framer-motion";

export const StyledPage = styled(motion.div)`
  /* min-height: 100vh; */
 
  background-color: ${(props) => props?.theme?.greyColor ?? "#000"};
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  margin-bottom: 100px;
`;

export default StyledPage;