import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

const ModalSortOptions = ({ show, children, title, titleBorder, subtitle }) => {
	const [isBrowser, setIsBrowser] = useState(false);
  
	useEffect(() => {
	  setIsBrowser(true);
	}, []);
  
	const modalContent = show ? (
	  <StyledModalOverlay>
			<StyledModal>
		  {title && <StyledModalTitle>{title}</StyledModalTitle>}
		  {subtitle && <StyledSubTitle>{subtitle}</StyledSubTitle>}
		  {titleBorder && <StyledBorder></StyledBorder>}
		  <StyledModalBody>{children}</StyledModalBody>
			</StyledModal>
	  </StyledModalOverlay>
	) : null;
  
	if (isBrowser) {
	  return ReactDOM.createPortal(
			modalContent,
			document.getElementById("modal-root")
	  );
	} else {
	  return null;
	}
};
  
const StyledModalBody = styled.div`
  padding: 24px;
  padding-top: 15px;
`;

const StyledSubTitle = styled.div`
 color: #787878; 
 font-family: Poppins;
 font-size: 18px;
 line-height: 25px;
 font-weight: normal;
 margin-bottom: 6px;
`;

const StyledModalTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  padding: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
`;
export const StyledBorder = styled.div`
  border: 1px solid #C8C8C8;
`;

// const StyledModalHeader = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   font-size: 25px;

//   a {
// 	  color: red;

//   margin: 24px 24px 0px 0px;
//   }
// `;

const StyledModal = styled.div`
  background: #424242;
  width: 272px;
  height: 100%;
  border-radius: 4px;
  color: white;
`;
const StyledModalOverlay = styled.div`
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

// const StyledModaltransparent = styled.div`
// position: fixed;
// z-index: 4;
// top: 0;
// left: 0;
// min-width: 100vw;
// max-width: 100vw;
// min-height: 100vh;
// display: flex;
// justify-content: center;
// align-items: center;
// `;
  
export default ModalSortOptions;