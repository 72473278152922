import { motion } from "framer-motion";
import styled from "styled-components";


export const PlayZone = styled(motion.div)`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:  #111111;
	background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.75) 29.69%, rgba(0, 0, 0, 0) 45.83%, rgba(0, 0, 0, 0) 90.62%, rgba(0, 0, 0, 0.5) 99.99%, #000 100%);
	border-radius: 0px 0px 8px 8px;
`;

export const StyledPlayControlBar = styled(motion.div)`
display: block;
align-items: center;
padding: 20px;
margin-bottom: 0px;
background: linear-gradient(180deg,#000 10%,rgba(0,0,0,0.70) 20%,rgba(0,0,0,0) 45.83%,rgba(0,0,0,0) 90.62%,rgba(0,0,0,0.5) 99.99%,#000 100%);
border-radius: 0px 0px 8px 8px;

	@media only screen and (max-width:420px) {
		width: 100%;
	}

	@media only screen and (min-width:420px) {
		width: 100%;
	}

	@media only screen and (min-width:421px) {
		width: 100%;
	}

	@media only screen and (min-width:519px) {
		width: 100%;
	}

	@media only screen and (min-width:520px) {
		width: 100%;
	}

	@media only screen and (min-width: 700px) {
    	width: 93%;
	}

	@media only screen and (min-width:720px) {
		width: 90%;
	}

	@media only screen and (min-width:740px) {
		width: 88%;
	}

	@media only screen and (min-width:767px) {
		width: 85%;
	}

	@media only screen and (min-width:820px) {
		width: 80%;
	}

	@media only screen and (min-width:844px) {
		width: 77%;
	}

	@media only screen and (min-width:896px) {
		width: 73%;
	}

	@media only screen and (min-width:912px) {
		width: 72%;
	}

	@media only screen and (min-width:1024px) {
		width: 64%;
	}

	@media only screen and (min-width:1180px) {
		width: 55%;
	}

	@media only screen and (min-width:1280px) {
		width: 51%;
	}

	@media only screen and (min-width:1368px) {
		width: 47%;
	}

	.three_controller_frag{
		display: flex;
		justify-content: center;
	}

	.titles_parent{
		width: 100%;
		justify-content: center;
		display: flex;
	}

	& .titles {
	display: flex;
	width: 100%;
    margin-right: auto;
    order: 2;

		.title {
			font-family: 'MTN';
			font-style: normal;
			font-weight: 300;
			font-size: 1rem;
			color: white;
			display: inline-grid;
			width: 100%;
			p{
				text-align: center;
    			width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.icon {
		cursor: pointer;
		margin-left: 0px;
	}

	.close {
		background-image: url('/images/icons/arr_back.svg');
		background-position: top left;
		background-repeat: no-repeat;
		background-size: contain;
		align-self: center;
		min-width: 12.93px;
    	min-height: 20px;
		margin-left: auto;
    	order: 2;
}
	}
`;

export const StyledThreeController = styled.div`
	display: flex;
	justify-content: center;

	@media only screen and (max-width:420px) {
		margin-top: 80px;
	}
	
	@media only screen and (min-width:421px) {
		margin-top: 130px;
	}

	.icon {
		cursor: pointer;
		margin-left: 0px;
	}

	.rewind {
		background-image: url('/images/icons/ic_back.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		margin-right: 100px;
		min-height: 20px;
		min-width: 16px;
		width: 25px;
	}

	.play {
		background-image: ${({isPlaying}) => isPlaying ? "url('/images/icons/ic_pause.svg')" : "url('/images/icons/ic_play.svg')"};
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		align-self: center;
		min-height: 40px;
		min-width: 40px;
	}

	.forward {
		background-image: url('/images/icons/ic_forward.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		margin-left: 100px;
		min-height: 20px;
		min-width: 16px;
		width: 25px;
	}
`;

export const StyledModalOverlay = styled.div`
	position: fixed;
	height: inherit;
    width: 100%;
	z-index:9;
	padding: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	&::-webkit-scrollbar {
		display: none;
	};
	opacity: ${({fadeOut}) => fadeOut ? 0 : 1};
`;

export const ProgressController = styled.div`
	display: flex;

	@media only screen and (max-width:420px) {
		margin-top: 70px;
    	margin-bottom: 20px;
	}
	
	@media only screen and (min-width:421px) {
		margin-top: 130px;
		margin-bottom: 0px;
	}

	time{
		margin-left: 5px;
	}

	.icon {
		cursor: pointer;
		margin-left: 0px;
	}

	.fullscreen {
		margin-left: 10px;
		margin-right: -10px;
		background-image: url('/images/icons/full_screen.png');
		background-position: top left;
		background-repeat: no-repeat;
		background-size: contain;
		align-self: center;
		justify-self: flex-start;
	}

	input[type=range]::-webkit-slider-thumb {
		margin-block: auto;
		height: 5px;
		border-radius: 100px;	
		accent-color: #FFCB05;
	}
	
	input[type=range] {
		margin-block: auto;
		height: 5px;
		border-radius: 100px;	
		accent-color: #FFCB05;
	}
`;

export const StyledModalMusicOverlay = styled.div`
	position: fixed;
    width: 100%;
	z-index:9;
	padding: 20px;
	display: block;
	// background-color: transparent;
	background-color: black;
	&::-webkit-scrollbar {
		display: none;
	};
`;

export const StyledModalMusicHeaderController = styled.div`
	display: flex;
	justify-content: space-between;
	height: fit-content;
	width: 100%;
	background: black;
	margin-bottom: 16px;

	.icon {
		cursor: pointer;
		margin-left: 0px;
	}

	.down {
		background-image: url('/images/icons/close.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		margin-right: 10px;
		min-height: 20px;
		min-width: 16px;
	}

	.more {
		background-image: url('/images/icons/ic_options.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		margin-left: 10px;
		min-height: 20px;
		min-width: 16px;
	}
`;

export const StyledModalMusicBodyController = styled.div`
	display: block;
	justify-content: center;
	height: fit-content;
	width: 100%;
	background: black;

	.thumbnail{
		width: 100%;
		height: 312px;
		background-image: ${({src}) => src ? `url('${src}')` : "url('/images/icons/sample_music_thumbnail.png')"};
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
	}

	.title{
		width: 100%;
		padding: 5px;
		margin-top: 16px;
		margin-bottom: 16px;
		justify-content: center;
		display: grid;

		p{
			justify-self: center;
		}
	}

`;

export const StyledModalMusicFooterController = styled.div`
	display: block;
	height: fit-content;
	width: 100%;
	background: black;

	input[type=range]::-webkit-slider-thumb {
		margin-block: auto;
		height: 5px;
		border-radius: 100px;	
		accent-color: #FFCB05;
	}
	
	input[type=range] {
		margin-block: auto;
		height: 5px;
		border-radius: 100px;	
		accent-color: #FFCB05;
	}

	.timeStamp{
		width: 100%;
		display: inline-flex;
		justify-content: space-between;

		.timeElapsed{
			font-size: 12px;
		}
		.timeLeft{
			font-size: 12px;
		}
	}

	.controls{
		height: fit-content;
		width: 100%;
		padding: 5px;
		margin-top: 16px;
		margin-bottom: 8px;
		background: black;
		display: inline-flex;
		align-items: center;
		justify-content: space-between;

		.shuffle{
			width: 18px;
			height: 18px;
			background-image: ${({isShuffle}) => isShuffle ? "url('/images/icons/shuffle_active.svg')" : "url('/images/icons/shuffle_rounded.svg')"};
			background-repeat: round;
			background-size: cover;
			background-position: inherit;
		}

		.previous{
			width: 32px;
			height: 32px;
			background-image: url('/images/icons/skip_previous_rounded.svg');
			background-repeat: round;
			background-size: cover;
			background-position: inherit;
		}

		.playPause{
			width: 48px;
			height: 48px;
			background-image: ${({isPlaying}) => isPlaying ? "url('/images/icons/ic_pause.svg')" : "url('/images/icons/ic_play.svg')"};
			background-repeat: round;
			background-size: cover;
			background-position: inherit;
		}

		.next{
			width: 32px;
			height: 32px;
			background-image: url('/images/icons/skip_next_rounded.svg');
			background-repeat: round;
			background-size: cover;
			background-position: inherit;
		}

		.repeat{
			width: 18px;
			height: 18px;
			background-image: ${({isRepeat}) => isRepeat ? "url('/images/icons/repeat_active.svg')" : "url('/images/icons/repeat_rounded.svg')"};
			background-repeat: round;
			background-size: cover;
			background-position: inherit;
		}

	}

	.playListParent{
		height: fit-content;
		width: 100%;
		padding: 5px;
		margin-top: 0px;
		margin-bottom: 24px;
		background: black;
		
		.playList{
			width: 18px;
			height: 18px;
			background-image:url('/images/icons/play_list.svg');
			background-repeat: round;
			background-size: cover;
			background-position: inherit;
			float: right;
		}
	}

`;

export const StyledModalMusicPlayList = styled.div`
	display: block;
	height: fit-content;
	width: 100%;
	background: black;
	[data-rsbs-overlay]{
		z-index: 10;
	}
	:root {
		z-index: 10;
		--rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
		--rsbs-bg: #fff;
		--rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
		--rsbs-max-w: auto;
		--rsbs-ml: env(safe-area-inset-left);
		--rsbs-mr: env(safe-area-inset-right);
		--rsbs-overlay-rounded: 16px;
	  }
`;