import {useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import {
	Page,
} from "../../shared/components";
import {clearSubscription} from "../../utils/redux/actions/subscription.action";
import styled from "styled-components";
import frown from "../../assets/images/frown.png";
import { Button } from "../../shared/components/Button";
import { useNavigate } from "react-router-dom";
import { PWA_ROUTES } from "../../constants/appConstants";
import { GenericError as events} from "../../analytics/events";


const GenericErrorScreen = () => {

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const {network} = useSelector((state) => state);

	// const {items} = useSelector((state) => state);
	// const {filteredSections} = useSelector((state) => state);
	// const [showMessageModal, setShowMessageModal] = useState(false);

	// const {
	// 	subscriptionsList,
	// 	purchaseList,
	// } = useSelector((state) => state?.subscriptions);


	// const [isSubscriptionCaterogy] = useState(true);
	// const [query] = useState("");
	// const emptyFilteredSections = (Object.keys(filteredSections).length === 0);

	useEffect(() => {
		dispatch({
			type: "ANALYTICS/TRACK_EVENT",
			event: events.error_view_screen(
        network?.call?.headers?.isoRegion,
        network?.call?.headers.environment,
      ),
		});
		dispatch(clearSubscription());
	}, []);




	// const doSearch =()=> {
	// 	dispatch(fetchItemsFilterSectionByCategory(query, isSubscriptionCaterogy, subscriptionsList, purchaseList));
	// };


	return (
    <Page isBottomBar={false}>
      {/* <TopNav title="Error"/> */}
      <div style={{ marginTop: 120 }}>
        <GenericOverall>
          <img alt="" width="100" height="100" src={frown} />
        </GenericOverall>
        <div
          style={{
            textAlign: "center",
            marginTop: 80,
            fontWeight: 400,
            fontSize: 34,
          }}>
          We're sorry
        </div>
        <div style={{ marginTop: 50, marginLeft: 40, marginRight: 40 }}>
          It looks like something went wrong. Please try again later.
        </div>
        <div style={{ marginTop: 70 }}>
          <div style={{ marginLeft: 50, marginRight: 50, marginTop: 10 }}>
            <Button
              caption={"BACK TO HOMESCREEN"}
              clickHandler={() => {
                navigate(PWA_ROUTES.HOME);
              }}
            />
          </div>

          {/* <div style={{marginLeft: 50, marginRight: 50, marginTop: 20, fontSize: 14}}>
						<Button isOutline={true} caption={"BACK"} clickHandler={()=>{ navigate(PWA_ROUTES.HOME);}} />
					</div> */}
        </div>
      </div>
    </Page>
  );
};

const GenericOverall = styled.div`

display: flex;
justify-content: center;
align-items: center;
`;

// const Loader = styled.div`
// display: flex;
// padding: 14px;
// justify-content: center;

// `;


export default GenericErrorScreen;