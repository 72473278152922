import {EVENT, PARAM} from "./helpers";

export const manage_screen = (opcoOrigin, opcoEnv) => {
	return {
		eventName: EVENT.VIEW_SCREEN,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "discovery",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "manage",
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			},
		],
	};
};

export const logout = (opcoOrigin, opcoEnv) => {
	return {
		eventName: EVENT.TAP,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "settings",
			},
			{
				key: PARAM.KEY.TAP_TARGET,
				value: "logout",
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			},
		],
	};
};

export const zigiClick = (opcoOrigin, opcoEnv) => {
	return {
		eventName: EVENT.TAP,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "settings",
			},
			{
				key: PARAM.KEY.TAP_TARGET,
				value: "zigi",
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			},
		],
	};
};


