import {EVENT, PARAM} from "./helpers";

export const home_screen = (opcoOrigin, opcoEnv) => {
	return {
		eventName: EVENT.VIEW_SCREEN,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "discovery",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "home",
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			},
		],
	};
};


export const banner_hit_home_screen = (opcoOrigin, opcoEnv, url) => {
	return {
		eventName: EVENT.TAP,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "banner_tap",
			},
			{ 
				key: PARAM.KEY.EXTERNAL_LINK,
				value: url
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			},
		],
	};
};

export const content_hit_tap = (opcoOrigin, opcoEnv, id,title, vendor_id, provider ) => {
	return {
		eventName: EVENT.TAP,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "content_tap",
			},
			{
				key: "content_id",
				value: id,
			},
			{
				key: "content_title",
				value: title,
			},
			{
				key: "content_vendor",
				value: vendor_id,
			},
			{
				key: "service_provider",
				value: provider,
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			},
  
		],
	};
};










