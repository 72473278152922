import { motion } from "framer-motion";
import styled from "styled-components";

export const CheckableStyleButton = styled(motion.div)`
    height: 44px;
    width: 100%;
    padding: 16px 14px;
    margin-bottom: 12px;
    font-family: ${({theme}) => theme?.fonts[0] ?? "MTN"};
    font-style: normal;
    font-weight: 700;
    font-size: ${({theme}) => theme?.size?.f3 ?? "12px"};
    line-height: 14px;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    background-color: ${({isChecked}) => isChecked ? "#FFCB05" : "#424242"};
    border-radius: 8px;
    color: ${({isChecked}) => isChecked ? "#000" : "#FFFFFF"};
    text-transform: uppercase;
    text-decoration: none !important;
    pointer-events: ${({isActive}) => isActive ? "auto" : "none"};

    #title_parent{
        display: flex;
        align-items: center;

        #title{
            text-transform: capitalize;
            font-family: 'MTN';
            font-size: 14px;
        }
    }

    #icon{
        align-items: center;
        display: flex;
        #check{
            background-image: ${({isChecked}) => isChecked ? "url(/images/icons/selected.svg)" : "url(/images/icons/un_selected.svg)"};
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 16px;
            height: 16px;
        }
    }
`;