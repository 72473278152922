import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledChannelCard = styled(motion.div)`
  height: "100%";
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;

  overflow-x: auto;
  position: sticky;
  width: 100%;
  top: auto;
  color: #000;
  font-family: "MTN Brighter Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;