// import React from "react";
import { useSelector } from "react-redux";
import { TopNav, Banner, Page, Border, LandingCategory, SubscriptionSuccessInfo } from "../../shared/components";

const SubscriptionSuccessScreen = () => {
	const {items} = useSelector(state => state?.items);
	const mightLike = items?.filter(item => item.name === "might-like")[0];

	return (
		<Page isBottomBar={true}>
			<TopNav title="payment success" />
			<Banner />
		 <SubscriptionSuccessInfo />
		 <Border />
		 <LandingCategory
				viewMoreAction={mightLike?.viewMoreAction}
				title={mightLike?.name}
				items={mightLike?.data}
			/>
		</Page>
	);
};

export default SubscriptionSuccessScreen;
