import useWindowSize from "../../../hooks/useWindowSize";
import { StyledSearchResultsWrapper } from "./SearchResultsWrapper.styles";

const SearchResultsWrapper = ({ children }) => {
  const { width } = useWindowSize();
  let c = Math.floor(width / 160);
  if (width <= 320) c = 1;
  return (
    <StyledSearchResultsWrapper c={c}>{children}</StyledSearchResultsWrapper>
  );
};

export default SearchResultsWrapper;
