import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import {store, persistor } from "./utils/redux/store";
import { ThemeProvider } from "styled-components";
import { generic } from "./shared/themes";
import { PersistGate } from "redux-persist/integration/react";
import { Auth0Provider } from "@auth0/auth0-react";
import { CRED, SESSION_STORAGE_CACHE} from "./utils/network/auth0Util";

ReactDOM.render(
	<React.StrictMode>
		<Auth0Provider
			audience={CRED.CLIENT.AUDIENCE}
			domain={CRED.CLIENT.DOMAIN}
			clientId={CRED.CLIENT.ID}
			cache={SESSION_STORAGE_CACHE}
			redirectUri={window.location.origin}>

			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<ThemeProvider theme={generic} >
						<App />
					</ThemeProvider>
				</PersistGate>
			</Provider>
		</Auth0Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();