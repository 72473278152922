import ApiCall from "../../network/apiCalls";
import { network } from "../types";

export const initialize = (data) => async(dispatch) => {
	try {

		if(data){
			dispatch({
				type: network.NETWORK_INIT_SUCCESS,
				payload: new ApiCall({...data})
			});

			return;
		}
    
		dispatch({
			type: network.NETWORK_INIT_SUCCESS,
			payload: new ApiCall()
		});

	} catch (error) {
		dispatch({
			type: network.NETWORK_INIT_ERROR,
			payload: {
				message: error?.message,
				error
			}
		});
		// createBrowserHistory.push('./generic')
	}
};