import {useEffect} from "react";
import {useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";
import { subscriptions, appState} from "../../utils/redux/actions";
import { PWA_ROUTES } from "../../constants/appConstants";
import storage from "redux-persist/lib/storage";
import loaderskel from "../../assets/images/loader-skeleton.png";


const AuthRedirect = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const height = window.innerHeight;
	// const [searchParams] = useSearchParams();

	const restoreSelectedItemState = (savedState) => {
		if(savedState===null||savedState===undefined||savedState==="") return navigate(PWA_ROUTES.HOME);

		const stateJson = JSON.parse(savedState);
        
		if (stateJson.extra !== undefined) {
      dispatch(subscriptions.selectCharge(stateJson?.extra?.selectedCharge));
      dispatch(subscriptions.selectItem(stateJson?.extra?.item));
      dispatch(appState.onMediaSelectedState(stateJson?.extra?.item));

      storage.removeItem("state");
    }

		navigate(stateJson.route);
	};

	useEffect(()=>{
		const savedState = localStorage.getItem("state");
		restoreSelectedItemState(savedState);
	}, []);
	
	return (
		<div style={{justifyContent: "center", alignItems: "center", display: "flex", marginTop:height/2}}>
                <img  alt="" width={100} height={100} src={loaderskel} />
	
		</div>
	);
};

export default AuthRedirect;