import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledItemImageView = styled(motion.div)`
  /* min-height: 272px;
  min-width: 272px; */
  /* image is always square start */
  position: relative;
  width: 100%;
  height: 260px;
  /* image is always square end */
  flex: 1;
  /* max-height: 300px;
  max-width: 300px; */
  max-height: 260px;
  border: 2px solid #424242;
  border-radius: 4px;
  background-image: ${({ image }) => (image ? `url(${image})` : "")};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;