import styled from "styled-components";
import {motion} from "framer-motion";

export const StyledTermsAndConditions = styled(motion.div)`

padding-bottom: 64px;
padding-right: 20px;
padding-left: 20px;

.terms {
    margin: 24px;

    .tncs {
      font-family: MTNBrighterSansRegular;
    }
}

.title {
  font-size: 24px;
  margin-bottom: 24px;
}

.tnc-title {
  margin-bottom: 8px;
}

.tnc,
.tnc-details {
  margin-bottom: 24px;
}
`;

export default StyledTermsAndConditions;