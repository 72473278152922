import { motion } from "framer-motion";
import styled from "styled-components";

export const TabBodyContainer = styled(motion.div)`
    display: flex;
    overflow-y: scroll;
    width: 100%;
    background-color: black;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 0px;

    .header{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 24px 12px;
        width: 100%;
        height: 36px;

        .p{
            font-family: 'MTNBrighterSansRegular';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            letter-spacing: 0.15px;
            color: #FFFFFF;
            flex: none;
            order: 0;
            flex-grow: 1;
        }
    }
`;

export const Episode = styled(motion.div)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 24px;
    width: 100%;
    height: 88px;

    .thumbnail{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        width: 56px;
        height: 56px;
        border-radius: 4px;
        background-image: url('/images/icons/asphalt.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .episode_info{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        margin-left: 12px;
        width: 208px;
        height: 64px;

        #title{
            width: 208px;
            height: 24px;
            font-family: 'MTNBrighterSansRegular';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;            
            letter-spacing: 0.15px;
        }

        #info{
            width: 208px;
            height: 20px;
            font-family: 'MTNBrighterSansRegular';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: #FFFFFF;
            opacity: 0.6;
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
        }

        #timestamp{
            width: 208px;
            height: 20px;
            font-family: 'MTNBrighterSansRegular';
            font-style: italic;
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: #FFFFFF;
            opacity: 0.6;
            flex: none;
            order: 2;
            align-self: stretch;
            flex-grow: 0;
        }
    }

    .action_layout{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 0px;
        width: 24px;
        height: 48px;
        position: absolute;
        right: 24px;

        #action_icon{
            width: 24px;
            height: 24px;            
            flex: none;
            order: 0;
            flex-grow: 0;
            background-image: url('/images/icons/ic_action_play.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    // p{
    //     width: 64px;
    //     height: 24px;
    //     font-family: 'MTNBrighterSansRegular';
    //     font-style: normal;
    //     font-weight: 700;
    //     font-size: 12px;
    //     line-height: 24px;
    //     text-align: center;
    //     letter-spacing: 1px;
    //     text-transform: uppercase;
    //     color: #FFCB05;
    // }
`;