import { useEffect, useState } from "react";
import { StyledProfile } from "./Profile.styles";
// import { Button } from "../Button";
import { useSelector } from "react-redux";

const Profile = ({ children }) => {
  //const Profile = ({children, doUpdate}) => {

  // const dispatch = useDispatch();
  const { profile } = useSelector((state) => state);
  const [inputs, setInputs] = useState([]);
  const {
    language,
  } = useSelector((state) => state);

  useEffect(() => {
    if (profile) {
      setInputs([
        { key: language?.chosenPack?.language?.COPY?.SCREENS?.manage?.profile_content?.phone_number, value: profile?.data?.msisdn },
        // { key: "Names", value: profile?.data?.name },
        // { key: "Email Address", value: profile?.data?.email },
        // { key: "Birthday", value: profile?.data?.birthday },
        // { key: "Location", value: profile?.data?.location },
        // { key: "Language", value: profile?.data?.language },
      ]);
    }
  }, [profile]);

  // const changeMsisdn = () => {
  // 	const profileData ={msisdn:inputs[0].value, birthday: inputs[3].value, name: inputs[1].value, location:inputs[4].value, email:inputs[2].value, language:inputs[5].value};
  // 	doUpdate(profileData);
  // 	// dispatch(updateProfileViaApi(network?.call, profileData))
  // };

  const handleChangeMsisdn = (event, itemKey) => {
    inputs.forEach((item) => {
      item.key == itemKey ? (item.value = event.target.value) : null;
    });
  };

  const InputComponent = () => {
    return inputs.map((item, index) => {
      const generatedName = `${item}`.split(" ")[0];
      const generatedId = `id$${generatedName}`;

      return (
        <div key={`${index}wrapper`} className="input-wrapper">
          <div
            key={`${index}input-before-text`}
            className="input-before-text">{`${item.key}`}</div>
          <input
            disabled
            readOnly={item.value}
            onChange={(event) => handleChangeMsisdn(event, item.key)}
            autoComplete="off"
            className="input"
            type="text"
            name={generatedName}
            id={generatedId}
            placeholder={item.value}
          />
        </div>
      );
    });
  };

  return (
    <StyledProfile>
      <p className="profile-header">
        <span style={{ fontWeight: "bold" }}>y'ello,</span>{" "}
        {profile?.data?.msisdn}
      </p>
      {children}
      <div className="profile">
        <div style={{ padding: "20px" }}>
          <InputComponent />
          {/* <Button clickHandler={() => changeMsisdn()} style={{width:"10px"}} caption="rofile" /> */}
        </div>
      </div>
    </StyledProfile>
  );
};

export default Profile;
