import { useNavigate ,useLocation} from "react-router-dom";
import { useDispatch, useSelector} from "react-redux";
import { subscriptions, appState} from "../../../utils/redux/actions";
import { StyledLandingCategory } from "./LandingCategory.styles";
import { PWA_ROUTES } from "../../../constants/appConstants";
import { Pagination } from "..";
// import { Ads } from "../Ads";
import {useEffect, useState} from "react";
import styled from "styled-components";
import {HomeScreen as events} from "../../../analytics/events";
import { SELECTED_SOURCE } from "../../../appConstants";



const LandingCategory = ({
  title,
  items,
  loading,
  isVertical,
  onScrollHorizontal,
}) => {
  // const LandingCategory = ({title, items, loading, adsProperties, isVertical, onScrollHorizontal}) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    language,
  } = useSelector((state) => state);
  const { network } = useSelector((state) => state);


  // const {
  // 	selectedItem,
  //   } = useSelector((state) => state?.subscriptions);

  const [presentLocation, setPresentLocation] = useState(false);

  useEffect(() => {
    if (location.pathname === "/myplay") return setPresentLocation(true);
  }, []);

  const DisplayItems = (categoryItems, clickHandler) => {
    if (categoryItems && categoryItems.length > 0) {
      return (
        <Pagination
          title={title}
          dataSet={categoryItems}
          onClick={(mItem) => {
            clickHandler(mItem);
          }}
          isVertical={isVertical}
        />
      );
    }

    return <EmptyPage>{"Oops!No offers yet...."}</EmptyPage>;
  };

  // const viewMore = (e) => {
  // 	navigate(`items/${title}`);
  // };

  // const confirm = (accessToken, parsedProfile) => {
  // 	setLoading(true);
  // 	dispatch(buyCurrentItem(network?.call, {...selectedItem, subscriptionRenew, accessToken:accessToken, msisdn:parsedProfile.msisdn}));

  // };

  const selectItem = (item) => {
    dispatch({
      type: "ANALYTICS/TRACK_EVENT",
      event: events.content_hit_tap(
        network?.call?.headers.region,
				network?.call?.headers.environment,
        item?.ID?.id,
        item?.title,
        item?.vendor?.id,
        item?.vendor?.title,
      ),
    });

    dispatch(subscriptions.selectItem(item, SELECTED_SOURCE.CONTENT));

    if (presentLocation) {
      dispatch(appState.onMediaSelectedState(item));

      if (item.internalPlay === false) {
        navigate(PWA_ROUTES.CONTENT_INFO);
        return;
      }

      if (item?.Collection?.length > 0) {
        navigate(PWA_ROUTES.SERIES);
        return;
      }

      navigate(PWA_ROUTES.CONTENT_INFO);
    }
    navigate(PWA_ROUTES.CONTENT_INFO);
  };

  return (
    <>
      <StyledLandingCategory key={title} isVertica={isVertical}>
        {title == null ? null : (
          <div className="top-bar">
            <span className="title">{title === "Recently added"? language?.chosenPack?.language?.COPY?.SCREENS?.home?.recent_added : title === "Popular on Play" ? language?.chosenPack?.language?.COPY?.SCREENS?.home?.popular_on_play : title === "Trending" ? language?.chosenPack?.language?.COPY?.SCREENS?.home?.Trending : title}</span>
          </div>
        )}

        <div
          className="content-load"
          onScroll={onScrollHorizontal ? onScrollHorizontal : () => {}}>
          {loading ? (
            <StyledButtonLoading></StyledButtonLoading>
          ) : (
            DisplayItems(items, selectItem)
          )}
        </div>
      </StyledLandingCategory>
      {/* {
				title === "Movies" &&
				<Ads data={adsProperties}/>
			} */}
    </>
  );
};

const EmptyPage = styled.div`
height: 200px;
width: 100%;
display: flex;
justify-content: center;
align-items: center;

`;

const StyledButtonLoading = styled.div`
  width: 40px;
  height: 40px;

  
  border: 6px solid ${({ primary }) => (primary ? "#fff" : "#FFCB05")};
  border-bottom-color: transparent;
  border-radius: 45px;
  display: flex;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default LandingCategory;