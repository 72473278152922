import styled  from "styled-components";
import {motion} from "framer-motion";

export const StyledBanner = styled(motion.div)`
     background-image: ${({image}) => image ? `url(${image})` : "url(/images/footer_banner_00.jpg)"};
	   background-position: center;
     background-repeat: no-repeat;
     background-size: cover;
	   min-height:  120px;
  	 width: 100%;
     margin-top: 0px;
     margin-bottom: 10px;
`;

export const StyledBannerMbks = styled(motion.div)`
     background-image: ${({image}) => image ? `url(${image})` : "url(/images/footer_banner_00.jpg)"};
     background-repeat: no-repeat;
     display: block;
     margin-left: auto;
     margin-right: auto;
     width: 320px;
     height:  250px;
`;

export const StyledOverlay = styled(motion.div)`
	   background: rgba(0, 0, 0, 0.75);
     min-height: 120px;
  	 width: 100vw;
		 position: absolute;
     padding: 5px;
     display: flex;
     flex-direction: column;
     justify-content: space-around;
     align-items: center;

	.banner-question {
		font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
    font-style: normal;
		font-weight: bold;
		font-size: ${({theme}) => theme?.size?.f7 ?? "20px"};
		line-height: 23px;
    text-transform: uppercase;
    text-align: center;
	}

  .banner-justification {
    font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
    font-style: normal;
    font-weight: 500;
    font-size: ${({theme}) => theme?.size?.f5 ?? "16px"};
    line-height: 19px;
    text-align: center;
    display: flex;
    align-items: center;
    margin: 12px;
  }

  .outline-btn {
    min-height: ${({theme}) => theme?.heights[3] ?? "48px"};
    min-width: ${({theme}) => theme?.widths[2] ?? "204px"};
    padding: 16px 24px;
    font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
    font-style: normal;
    font-weight: 700;
    font-size: ${({theme}) => theme?.size?.f3 ?? "12px"};
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 25px;
    color: #000;
    text-transform: uppercase;
 }
`;