import { useState } from "react";
import ReactDOM from "react-dom";
import {StyledModalOverlay, StyledModal, StyledCloseBtn, StyledModalTitle, StyledSubTitle} from "./MenuModal.styles";

const MenuModal = ({dataSet, previouslySelectedIndex, onSelectItem, onCloseModal}) => {
    
	const [isSelected, setIsSeleted] = useState({selectedIndex:previouslySelectedIndex, key:dataSet.key});

	const handleOnClose = () =>{
		onSelectItem(isSelected);
		onCloseModal();
	};

	const handleSetIsSelected = (index, key) =>{
		setIsSeleted({selectedIndex:index, key:key});
	};

	const handleSetDefault = () =>{
		onSelectItem({selectedIndex:undefined, key: dataSet.key});
		onCloseModal();
	};

	const modalContent = (
    <StyledModalOverlay>
      <div
        style={{
          overflow: "scroll",
          zIndex: "999999999",
          height: "300px",
          width: "100%",
        }}>
        <StyledModal>
          <StyledSubTitle onClick={handleSetDefault}>
            {dataSet.default ? dataSet?.default : []}
          </StyledSubTitle>
          {dataSet?.dataSet ? (
            dataSet?.dataSet.map((item, index) => (
              <StyledModalTitle
                isSelected={isSelected?.selectedIndex === index}
                onClick={() => handleSetIsSelected(index, dataSet?.key)}
                key={`$mi${index}`}>
                {item}
              </StyledModalTitle>
            ))
          ) : (
            <></>
          )}
        </StyledModal>
      </div>

      <StyledCloseBtn
        isCheckable={isSelected.selectedIndex !== undefined}
        onClick={handleOnClose}
      />
    </StyledModalOverlay>
  );
  
	return ReactDOM.createPortal(modalContent, document.getElementById("modal-root"));
};
  
export default MenuModal;