import {useEffect} from "react";
import {useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";

import { PWA_ROUTES } from "../../constants/appConstants";
import loaderskel from "../../assets/images/loader.gif";

import { profile } from "../../utils/redux/actions";

const Logout = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(()=>{
		dispatch(profile.resetProfile());
		navigate(PWA_ROUTES.HOME);
	}, []);
	
	return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        marginTop: "50%",
      }}>
      <img alt="" width={100} height={100} src={loaderskel} />
    </div>
  );
};

export default Logout;