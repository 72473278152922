// import React from "react";
import { CheckableStyleButton } from "./CheckableButton.styles";
import { useSelector } from "react-redux";

const CheckableButton = ({currency, price, clickHandler, selected, body, isActive=true}) => {
	const { language } = useSelector((state) => state);

	return (
		<CheckableStyleButton isActive={isActive} isChecked={selected} onClick={clickHandler}>
			{
				currency !== undefined && price !== undefined ? <div id="title_parent"><span id="title">{`${currency}${price} ${language?.chosenPack?.language?.COPY?.SCREENS?.payment?.charge_msg2} ${body}`}</span></div> : 
					<div id="title_parent"><span id="title">{`${body}`}</span></div>
			}
      
	  <div id="icon">
				<div id="check"/>
	  </div>
		</CheckableStyleButton>
	);
};

export default CheckableButton;
