import {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectCharge } from "../../../utils/redux/actions/subscription.action";
import { StyledSubscriptionDetails } from "./SubscriptionDetails.styles";
import { CheckableButton } from "../CheckableButton";

const SubscriptionDetails = ({padded}) => {

	const {selectedItem, selectedCharge} = useSelector(state => state.subscriptions);
	  // eslint-disable-next-line no-unused-vars
	  const { language } =
	  useSelector((state) => state);
	const dispatch = useDispatch();

	useEffect(() => {
		 if(selectedCharge!=null){
			dispatch(selectCharge(selectedCharge));
		 }else{
			dispatch(
        selectCharge(
          selectedItem?.billingTypes ? selectedItem?.billingTypes[0] : {},
        ),
      );
		 }
	}, []);

	const renderBillings = ( ) => {
		if(selectedItem==null || selectedItem.billingTypes==null) return;

		return selectedItem?.billingTypes?.map((bill) => (
			<CheckableButton currency={`${bill?.currency}`} price={`${bill?.price}`} selected={selectedCharge?.value === bill?.value} body={bill?.value} clickHandler={()=>{dispatch(selectCharge(bill));}} key={bill?.value} />
		));
	};

	return (
		<StyledSubscriptionDetails padded={padded}>
			{
				selectedItem?.subscribed === "Active" &&
				<>

				</>
			}

			{
				selectedItem?.subscribed !== "Active" &&
				<>
					<div className="benefits">
						<span className="heading">
						{language?.chosenPack?.language?.COPY?.SCREENS?.payment?.how_often}
						</span>
					</div>
					<div className="actions">
					
						{renderBillings()}

					</div>

				</>
			}


		</StyledSubscriptionDetails>
	);
};

export default SubscriptionDetails;
