import styled from "styled-components";
import {motion} from "framer-motion";

export const TermsOfServiceStyle = styled(motion.div)`
   color: white;
   position: absolute;
   width: 100%;
   height: auto;
   padding: 26px;
   margin-top: 96px;
   font-family: 'Roboto';
   font-style: normal;
   font-weight: 300;
   font-size: 12px;
   line-height: 20px;
   display: block;
   align-items: center;
   letter-spacing: 0.25px;

   span{
      text-align: center;
      .terms{
         color: #FFCB05;
      }
   }

   .action-buttons{
      width: 100%;
      display: block;
      margin-top: 34px;
   }
`;