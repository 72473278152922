import {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, PaymentMethods } from "..";
import { PWA_ROUTES } from "../../../constants/appConstants";
import { fetchTopUpOtions } from "../../../utils/redux/actions/subscription.action";
import { StyledTopUpMethods } from "./TopUpMethods.styles";

const TopUpMethods = () => {
	const dispatch = useDispatch();
	const {topupOptions} = useSelector(state => state.subscriptions);
	useEffect(() => {
		dispatch(fetchTopUpOtions());
	}, []);

	return (
		<StyledTopUpMethods>
			<PaymentMethods dummies={topupOptions} title={"How would you like to top up?"} />

			<div className="flex-contain">
		    <Link to={`${PWA_ROUTES.TOPUP}${PWA_ROUTES.TOPUP_VOUCHER}`}>			
			 <Button className="button" caption="next" />
				</Link>
			</div>
		</StyledTopUpMethods>
	);
};

export default TopUpMethods;
