import { filteredSections } from "../types";

const initialState = {
	loading: false,
	items: [],
	banners: [],
	error: null,
	onceOffObj:[],
	subscriptionObj:[],
	freeObj:[]

};

const filteredSectionsReducer = (state = initialState, action) => {   
	switch (action.type) {
	case filteredSections.FILTERED_ITEMS_SECTION_REQUEST:
		return {
			...state,
			loading: true,
			error: null
		};
	case filteredSections.FILTERED_ITEMS_SECTION_SUCCESS:
		return {
			...action.payload
		};
	case filteredSections.FILTERED_ITEMS_SECTION_ERROR:
		return {
			...state,
			loading: false,
			error: action.payload
		};
	default:
		return state;
	}
};

export default filteredSectionsReducer;