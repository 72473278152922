import { useDispatch, useSelector } from "react-redux";
import { StyledTopNavLanguageToggle } from "./TopNavLanguageToggle.styles";
import { toggleLanguageModal } from "../../../utils/redux/actions/language.action";

const TopNavLanguageToggle = () => {
  const { chosenLanguage, isLanguageModalOpen } = useSelector(
    (state) => state.language,
  );
  const dispatch = useDispatch();

  const onPressLanguageToggle = () => {
    dispatch(toggleLanguageModal());
  };
  return (
    <StyledTopNavLanguageToggle
      isOpen={isLanguageModalOpen}
      onClick={onPressLanguageToggle}>
      {chosenLanguage?.code}
    </StyledTopNavLanguageToggle>
  );
};

export default TopNavLanguageToggle;
