import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledPartnerSubscriptionButtons = styled(motion.div)`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;

  padding-bottom: 24px;
  margin: 0 24px;

 .btn {
     min-height: 48px;
	 min-width: 140px;
	 font-family:  ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #FFFFFF;
  border-radius: 25px;
  margin-bottom: 24px !important;
 }

 .outline-btn {
     min-height: 48px;
	 min-width: 140px;
	 font-family:  ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 14px;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
background-color: #FFFFFF;
border-radius: 25px;
color: #000;
text-transform: uppercase;
margin-bottom: 24px;
 }
`;