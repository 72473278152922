//  ***** Disclaimer *****
//  This file contains the style stettings for use in the project.
//  You can create more of these files and link them in the themeSetup.js
//  uses f => factor values that can change but is ussually set on
//  *********************

const generic = {
	color: {
		primary: {
			bg: "#0f6987", //blue
			fg: [ // font colors that contrast
				"#ffffff", //white
				"#cccccc", //middle_grey
			],
		},
		secondary: {
			bg: "#4C4C4C", //dark grey
			fg: [
				"#4C4C4C", //white
			],
		},
		accent: {
			bg: "#ffcc00", //yellow
			fg: [
				"#333333", //grey
				"#0f6987", //blue
				"#0F6987", //disabled
				"#cccccc", //middle_grey
				"#093A4A", //deep_blue
				"#0D6583", //light_blue
				"#053241", //bonus_blue
			],
		},
		light: {
			bg: "#ffffff", //white
			fg: [
				"#333333", //grey
				"#4C4C4C", //dark grey
			],
		},
		light_grey: {
			bg: "#E5E5E5", //light grey
			fg: [
				"#333333", //dark grey
			],
		},
		grey: {
			bg: "#EEEEEE", //grey
			fg: [
				"#333333", //dark grey
				"#DDDDDD", //component-grey
				"#F6F6F6", //curve-grey
			],
		},
		middle_grey: {
			bg: "#cccccc", //middle grey
			fg: [
				"#0f6987", //blue
				"#333333", //dark grey
			],
		},
		dark_grey: {
			bg: "#4C4C4C", //dark grey
			fg: [
				"#ffffff", //white
			],
		},
		error: {
			bg: "#ffffff", //white
			fg: [
				"#BE1E50", //red
			],
		},
	},
	size: {
		f0: "0px",
		f1: "8px",
		f2: "10px",
		f3: "12px",
		f4: "14px",
		f5: "16px",
		f6: "18px",
		f7: "20px",
		f8: "24px",
		f9: "28.72px",
		f10: "32px",
		f11: "40px",
		f12: "64px",
	},
	weight: {
		f0: 0,
		f1: 100,
		f2: 200,
		f3: 300,
		f4: 400,
		f5: 500,
		f6: 600,
		f7: 700,
		f8: 800,
		f9: 900,
		f10: 1000,
	},
	radius: {
		f0: "0px",
		f1: "2px",
		f2: "4px",
		f3: "6px",
		f4: "8px",
		f5: "10px",
		f6: "15px",
		f7: "20px",
		f8: "32px",
		f9: "40px",
		f10: "50px",
		f11: "100px",
	},
	spacing: {
		f0: "0px",
		f1: "2px",
		f2: "4px",
		f3: "8px",
		f4: "12px",
		f5: "15px",
		f6: "24px",
		f7: "22px",
		f8: "28px",
		f9: "60px",
		f10: "80px",
		f11: "100px",
	},
	fonts: [
		"MTNBrighterSansRegular",
		"MTN"
	],
	lineHeights: [
		"0px",
		"0px",
		"0px",
		"0px",
		"0px",
		"0px"
	],
	heights: [
		"0",
		"0",
		"0",
		"48px",
		"80px",
		"159px",
		"180px",
		"210px",
		"0"
	],
	widths: [
		"0",
		"192px",
		"204px",
		"0px",
		"320px",
		"0",
		"408",
		"0",
		"100%"
	],
	image: {},
};

export default generic;