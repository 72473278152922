import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledSubscriptionDetails = styled(motion.div)`
 /* min-height: 212px; */
 display: flex;
 flex-direction: column;
 padding: 24px ${props => props.padded ? "24px" : 0};

 .benefits {
	font-size: 16px;
	font-style: normal;
	font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
	font-weight: 700;
	line-height: 18.75px;
	margin-bottom: 15px;
 }

 .actions {
	 padding: 0;
	 display: flex;
	 flex-direction: column;

	 .method {
		 display: flex;
		 margin-bottom: 30px;
		 color: #BDBDBD;
		 justify-content: space-between;
	 }
 }

 .btn {
	 padding: 16px 24px;
	 ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  border: 1px solid #FFFFFF;
  border-radius: 25px;
 }
`;