import { PWA_ROUTES } from "../../constants/appConstants";


export const bottomTabItems = [{
	id: 1,
	name: "Home",
	icon: "/images/icons/ic_home.svg",
	icon_active: "/images/icons/ic_active_home.svg",
	path: PWA_ROUTES.HOME,
	active: false,
	noIcon: "/images/icons/ic_home.svg"
},
{
	id: 2,
	name: "my play",
	icon: "/images/icons/ic_icon_play.svg",
	icon_active: "/images/icons/ic_active_play.svg",
	path: PWA_ROUTES.SUBSCRIBE_CONFIRM,
	active: false,
	noIcon: ""


},
{
	id: 3,
	name: "manage",
	icon: "/images/icons/ic_settings.svg",
	icon_active: "/images/icons/ic_active_settings.svg",
	path: PWA_ROUTES.MANAGE,
	active: false,
	noIcon: "/images/icons/ic_settings.svg"

}
];