import { profile } from "../types";

export const updateProfile = (data) => async(dispatch) => {
	try{
		if(data.msisdn !==  "undefined" ){
			dispatch({
				type: profile.UPDATED_PROFILE_SUCCESS,
				payload: data
			});
		}

        
	}catch(exception){
		dispatch({
			type: profile.UPDATED_PROFILE_ERROR,
			payload: { message: error?.message, error}
		});
	}
};

export const updateProfileViaApi = (apiCall, payload) => async(dispatch) => {
	try{
		const resp = await apiCall?.updateUserProfile(payload);
		if(resp){
			dispatch({
				type: profile.UPDATED_PROFILE_SUCCESS,
				payload: payload
			});
		}

	}catch(exception){
		dispatch({
			type: profile.UPDATED_PROFILE_ERROR,
			payload: { message: exception }
		});
	}
};

export const resetProfile = () => async (dispatch) => {
	try{
    // localStorage.clear();
    dispatch({
      type: profile.RESET_PROFILE,
    });
  } catch (exception){

		dispatch({
			type: profile.UPDATED_PROFILE_ERROR,
			payload: { message: error?.message, error}
    
		});
	}
};
