import { items } from "../types";

const initialState = {
	loading: false,
	items: [],
	banners: [],
	error: null,
	fetchSucess: false,
	redirectUrlObj: null,
	redirectLoading: false,
	fetchRedirectSuccess: false,
	fetchRedirectError: false,

	advertLoading: false,
	advertSuccess: false,
	advertError: false,
	advertInventory: null,

	advertLoadingMbks: false,
	advertSuccessMbks: false,
	advertErrorMbks: false,
	advertInventoryMbks: null,

	channelsLoading: false,
	channelsSuccess: false,
	channelsError: false,
	channelsData: null,

	playInstanceItems: null,
	playInstance: false,

	categoryMenu: [],
	menuLoading: false,
	menuError: false
};

const itemsReducer = (state = initialState, action) => {
	switch (action.type) {

		case items.FETCH_ADVERT_INVENTORY_REQUEST:
			return {
				...state,
				advertLoading: true,
				advertError: null,
				advertSuccess: false
			};
		case items.FETCH_ADVERT_INVENTORY_SUCCESS:
			return {
				...state,
				advertLoading: false,
				advertError: null,
				advertInventory: action.payload.response,
				advertSuccess: true
			};
		case items.FETCH_ADVERT_INVENTORY_ERROR:
			return {
				...state,
				advertLoading: false,
				advertError: action.payload,
				advertSuccess: false
			};


			case items.FETCH_ADVERT_INVENTORY_REQUEST_MBKS:
				return {
					...state,
					advertLoadingMbks: true,
					advertErrorMbks: null,
					advertSuccessMbks: false

				};
			case items.FETCH_ADVERT_INVENTORY_SUCCESS_MBKS:
				return {
					...state,
					advertLoadingMbks: false,
					advertErrorMbks: null,
					advertInventoryMbks: action.payload.response,
					advertSuccessMbks: true
				};
			case items.FETCH_ADVERT_INVENTORY_ERROR_MBKS:
				return {
					...state,
					advertLoadingMbks: false,
					advertErrorMbks: action.payload,
					advertSuccessMbks: false
				};


				case items.FETCH_CHANNELS_REQUEST:
					return {
						...state,
						channelsLoading: true,
						channelsError: null,
						channelsSuccess: false
					};
				case items.FETCH_CHANNELS_SUCCESS:
					return {
						...state,
						channelsLoading: false,
						channelsError: null,
						channelsData: action.payload.response,
						channelsSuccess: true
					};
				case items.FETCH_CHANNELS_ERROR:
					return {
						...state,
						channelsLoading: false,
						channelsError: action.payload,
						channelsSuccess: false
					};








	case items.FETCH_PLAY_INSTANCE_REQUEST:
		return {
			...state,
			loading: true,
			error: null,
			playInstance: false
		};
	case items.FETCH_PLAY_INSTANCE_SUCCESS:
		return {
			...state,
			loading: false,
			error: null,
			playInstanceItems: action.payload.response,
			playInstance: true
		};
	case items.FETCH_PLAY_INSTANCE_ERROR:
		return {
			...state,
			loading: false,
			error: action.payload,
			playInstance: false
		};


		case items.FETCH_CATEGORIES_REQUEST:
			return {
				...state,
				menuLoading: true,
				menuError: false
			};
		case items.FETCH_CATEGORIES_SUCCESS:
			return {
				...state,
				menuLoading: false,
				menuError: false,
				categoryMenu: action.payload
			};
		case items.FETCH_CATEGORIES_ERROR:
			return {
				...state,
				menuLoading: false,
				menuError: true,
				categoryMenu: action.error
			};
	


	case items.FETCH_ITEMS_REQUEST:
		return {
			...state,
			loading: true,
			error: null,
			fetchSucess: false
		};
	case items.FETCH_ITEMS_SUCCESS:
		return {
			...state,
			loading: false,
			error: null,
			items: action.payload.response,
			fetchSucess: true
		};
	case items.FETCH_ITEMS_ERROR:
		return {
			...state,
			loading: false,
			error: action.payload,
			fetchSucess: false
		};
	case items.FETCH_BANNERS_REQUEST:
		return {
			...state,
			loading: true,
			error: null,
			fetchSucess: false
		};
	case items.FETCH_BANNERS_SUCCESS:
		console.log("banners back", action.payload);
		return {
			...state,
			loading: false,
			error: null,
			banners: action.payload,
			fetchSucess: false
		};
	case items.FETCH_BANNERS_ERROR:
		return {
			...state,
			loading: false,
			error: action.payload,
			fetchSucess: false
		};

	case items.FETCH_REDIRECT_SUCCESS:
		return {
			...state,
			redirectLoading: false,
			fetchRedirectError: false,
			fetchRedirectSuccess: true,
			redirectUrlObj: action.payload,
		};

	case items.FETCH_REDIRECT_REQUEST:
		return {
			...state,
			redirectLoading: true,
			fetchRedirectError: false,
			fetchSucess: false
		};

	case items.FETCH_REDIRECT_ERROR:
		return {
			...state,
			redirectLoading: false,
			fetchRedirectError: action.error,
			fetchSucess: false,
			redirectUrlObj: null,
			error: action.error
		};

	case items.CLEAR_REDIRECT:
		return {
			...state,
			redirectLoading: false,
			fetchRedirectError: false,
			fetchRedirectSuccess: false,
			redirectUrlObj: null,
		};

            
	default:
		return state;
	}
};

export default itemsReducer;