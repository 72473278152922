import styled from "styled-components";
import {motion} from "framer-motion";

export const StyledMenu = styled(motion.div)`
 margin: 24px;
 .sorting-option-header-text{
	position: absolute;
	top: 221px;
	left: 48px;
	font-family: 'MTNBrighterSansRegular';
	font-size: 16px;
	line-height: 21px;
	display: flex;
	align-items: center;
	color: #000;
 }
 .span {
     display: flex;

	 .title {
		 flex: 1 0 80%;
	 }
	 .actions {
		 flex: 1 0 20%;
		 display: flex;
		 justify-content: space-around;

		 .sort-icon {
			 background-image: url('/images/icons/swap_sort.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;

			&:hover {
				cursor:pointer;
			}
		 }
		 .az-icon {
			 background-image: url('/images/icons/a_z.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		 }

	 }
 }

`;

export default StyledMenu;