import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {PWA_ROUTES} from "../../../constants/appConstants";
import {TabBodyContainer, Episode} from "./SeasonTabBody.styles";
import {appState} from "../../../utils/redux/actions";

const SeasonTabBody = ({season, episodes, info, selectedEpisode={episode:2, data:{}}}) =>{
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleOnSelectedEpisode = (selectedEpisodeIndex) =>{
		const _selectedEpisodeItem = {...episodes[selectedEpisodeIndex], episodeTitle: `S${season} E${(selectedEpisodeIndex+1)}`};
		dispatch(appState.onMediaSelectedState(_selectedEpisodeItem));
		navigate(PWA_ROUTES.CONTENT_PLAYER);
	};

	const EpisodeView = () => {
		return episodes.map((_, index)=><Episode key={`${index}_tab`} onClick={()=>handleOnSelectedEpisode(index)} active={index==(selectedEpisode.episode-1)}>
			<div className="thumbnail"/>
			<div className="episode_info">
				<p id="title">{`${info.title} E${(index+1)}`}</p>
				<p id="info">{`S${season} E${(index+1)}, 50 Mins`}</p>
				<p id="timestamp">{"5 December 2021"}</p>
			</div>
			<div className="action_layout">
				<div id="action_icon"/>
			</div>
		</Episode>);
	};

	return (
		<div>
			<TabBodyContainer>
				<div className="header">
					<p>{`${episodes.length} Episode(s)`}</p>
				</div>
				<EpisodeView/>
			</TabBodyContainer>
		</div>
	);
};

export default SeasonTabBody;