import {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectPaymentMethod } from "../../../utils/redux/actions/subscription.action";
import { StyledPaymentMethods } from "../../components/PaymentMethods/PaymentMethods.styles";

const PaymentMethods = ({title, dummies}) => {

	const {paymentMethods, selectedPaymentMethod} = useSelector(state => state.subscriptions);
	const dispatch = useDispatch();

	const selectMethod = (payMethod) => {
		dispatch(selectPaymentMethod(payMethod));
	};

	useEffect(() => {
		let obj = {
			activeUnit: "SZL",
			balance: -1,
			credit: 0,
			id: "000000000000000000000000",
			name: "Airtime"
		};
	  selectMethod(obj);
	}, []);

	const renderPaymentMethods = (selected) => {

		return selected?.map((payMethod,index) => (<div id={`payID${index}`} key={payMethod.balanceType} className="method" onClick={() => {selectMethod(payMethod);}}>
			<label htmlFor="html">{payMethod.balanceType}</label>
			<input type="radio" id="paymentMethod" onChange={() => {selectMethod(payMethod);}} checked={ selectedPaymentMethod?.name == payMethod.name } name="paymentMethod" value={payMethod.name} />
		</div>));
	};

	// const Authorize = () =>{
	// 	const provider = `${selectedItem?.vendor?.title}`.includes("Wi-Flix") ? "wiflix" : "wiflix2";
	// 	window.location.replace(`https://mtngh-dev.mtn-dev.auth0.com/authorize?audience=https://mtnplay.mtn.com&scope=subscribe:${provider}&response_type=code&client_id=u2xEQ8Edn5mR6FZkiwfucRe9eKIuCKkY&redirect_uri=${window.origin}/payments`);
	// };

	return (
		<div>
			<StyledPaymentMethods>
				<div className="benefits">
					<span className="heading">
						{title || "How would you like to pay?"}
					</span>
				</div>
				<div className="actions">
					{renderPaymentMethods(dummies ?? paymentMethods)}
				</div>
			</StyledPaymentMethods>
		</div>		
	);
};

export default PaymentMethods;