import {useEffect} from "react";
import {Page, TabbedList, TopNav} from "../../shared/components";
import {useDispatch, useSelector} from "react-redux";
import {fetchUserSubscriptions} from "../../utils/redux/actions/subscription.action";
import {onSubscriptionStatusPositionState} from "../../utils/redux/actions/appState.actions";
import {SubscriptionScreen as events} from "../../analytics/events";
import { clearRedirect} from "../../utils/redux/actions/items.actions";
// import DirectionProvider, { DIRECTIONS } from "react-with-direction/dist/DirectionProvider";

const SubscriptionsScreen = () => {
	const dispatch = useDispatch();
	const {subscriptions, network, appState, language} = useSelector((state) => state);
	const allSubscriptions = subscriptions?.subscriptions;

	useEffect(() => {

		dispatch(clearRedirect());
    
		dispatch({
			type: "ANALYTICS/TRACK_EVENT",
			event: events.subscription_management_screen(
				network?.call?.headers?.isoRegion,
				network?.call?.headers.environment
			),
		});


		dispatch({
			type: "ANALYTICS/TRACK_EVENT",
			event: events.active_tab_tap(
				network?.call?.headers?.isoRegion,
				network?.call?.headers.environment,
				"active"
			),
		  });

	}, []);

	useEffect(() => {
		dispatch(fetchUserSubscriptions(network?.call));
	}, [network]);

	useEffect(() => {
		if(appState.subscriptionStatusPosition!==0){
			dispatch(onSubscriptionStatusPositionState(0));
		}
	}, [appState.subscriptionStatusPosition]);

	const tab_events = {
		dispatch: dispatch,
		Active: {type: "ANALYTICS/TRACK_EVENT", event: events.active_tab_tap(
			network?.call?.headers?.isoRegion,
			network?.call?.headers.environment
		)},
		Inactive: {type: "ANALYTICS/TRACK_EVENT", event: events.inactive_tab_tap(
			network?.call?.headers?.isoRegion,
			network?.call?.headers.environment
		)},
		Pending: {type: "ANALYTICS/TRACK_EVENT", event: events.pending_tab_tap(
			network?.call?.headers?.isoRegion,
			network?.call?.headers.environment
		)},
	};

	const sort_events = {
		dispatch: dispatch,
		ViewModal: {
			type: "ANALYTICS/TRACK_EVENT",
			event: events.sort_option_modal_view(
				network?.call?.headers?.isoRegion,
				network?.call?.headers.environment
			),
		},
		CancelModal: {
			type: "ANALYTICS/TRACK_EVENT",
			event: events.sort_cancel_tap(
				network?.call?.headers?.isoRegion,
				network?.call?.headers.environment
			),
		},
		"a-z": {type: "ANALYTICS/TRACK_EVENT", event: events.sort_a_z_tap(
			network?.call?.headers?.isoRegion,
			network?.call?.headers.environment
		)},
		date: {type: "ANALYTICS/TRACK_EVENT", event: events.sort_date_tap(
			network?.call?.headers?.isoRegion,
			network?.call?.headers.environment
		)},
		price: {type: "ANALYTICS/TRACK_EVENT", event: events.sort_price_tap(
			network?.call?.headers?.isoRegion,
			network?.call?.headers.environment
		)},
		title: {type: "ANALYTICS/TRACK_EVENT", event: events.sort_title_tap(
			network?.call?.headers?.isoRegion,
			network?.call?.headers.environment
		)},
	};


	return (
    <>
      {/* <DirectionProvider direction={language?.chosenLanguage === "arabic" ? DIRECTIONS.RTL:DIRECTIONS.LTR}> */}

      <Page isBottomBar={false}>
        <TopNav
          title={
            language?.chosenPack?.language?.COPY?.SCREENS?.manage
              ?.sections_content?.subscriptions?.screen_title
          }
          centerTitle={true}
          enableSearch={false}
        />
        <TabbedList
          type="subscriptions"
          // items={allSubscriptions === 'Active'? language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections_content?.subscriptions?.active : allSubscriptions === 'InActive'? language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections_content?.subscriptions?.inactive: language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections_content?.subscriptions?.pending}
          items={allSubscriptions}
          sort_events={sort_events}
          tab_events={tab_events}
          initPosition={
            appState.subscriptionStatusPosition !== 0
              ? appState.subscriptionStatusPosition
              : 0
          }
        />
      </Page>
      {/* </DirectionProvider> */}
    </>
  );
};

export default SubscriptionsScreen;
