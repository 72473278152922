import { StyledExistingSubscriptionInfo } from "./ExistingSubscriptionInfo.styles";
import { Button } from "..";
import {  PWA_ROUTES } from "../../../constants/appConstants";
import { useNavigate } from "react-router-dom";


const ExistingSubscriptionInfo = ({info}) => {
	const navigate = useNavigate();

	return (
	  <StyledExistingSubscriptionInfo>
			<div className="info">
				{info}
	   </div>

			<Button isFullscreen={true} clickHandler={() => {
				navigate(PWA_ROUTES.HOME);
			}} className="button" caption="Back" />

	  </StyledExistingSubscriptionInfo>
	);
};

export default ExistingSubscriptionInfo;
