// import React from "react";
import { BottomBar } from "..";
import {StyledPage } from "./Page.styles";

const Page = ({children, isBottomBar}) => {

	return (
		<StyledPage>
			{children}
		 	{isBottomBar ? <BottomBar /> : <></>}
		</StyledPage>
	);
};

export default Page;