// import React from "react";
import { Button } from "..";
import { StyledActiveSubscriptionAction } from "./ActiveSubscriptionActions.styles";

const ActiveSubscriptionInfo = ({isOutline, caption, clickHandler}) => {
	return (
	  <StyledActiveSubscriptionAction>
			<Button isOutline={isOutline}  caption={caption} clickHandler={()=>{clickHandler();}} />
	  </StyledActiveSubscriptionAction>
	);
};

export default ActiveSubscriptionInfo;
