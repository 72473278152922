export const LANDING_CATEGORIES = {
	VIDEOS: "VIDEOS",
	MUSIC: "MUSIC",
	MOVIES: "MOVIES",
	GAMES: "GAMES"
};

export const SELECTED_SOURCE = {
  CHANNEL: "CHANNEL",
  CONTENT: "CONTENT",
};

export const PWA_ROUTES = {
  ROOT: "/",
  LOGIN: "/login",
  HOME: "/home",
  PAYMENTS: "/payments",
  CONTENT_INFO: "/ContentInfo",
  SUBSCRIBE_CONFIRM: "/myplay",
  ITEMS: "/items/:id",
  TOPUP: "/topup",
  TOPUP_METHOD: "/methods",
  TOPUP_VOUCHER: "/voucher",
  SUBSCRIBE_SUCCESS: "/success",
  SUBSCRIBE_FAIL: "/fail",
  CATEGORIES: "/categories",
  MANAGE: "/settings",
  SUBSCRIPTIONS: "/subscriptions",
  PURCHASES: "/purchases",
  ACTIVE_SUBSCRIPTIONS: "/activesubscriptions",
  INACTIVE_SUBSCRIPTIONS: "/inactivesubscriptions",
  PROFILE: "/profile",
  FAQ: "/help",
  TERMS_AND_CONDITIONS: "/terms",
  MY_PLAY: "/myplay",
  GENERIC_ERROR: "/generic",
};

export const ITEM_TYPES = {
	MOVIE: "movies",
	MUSIC: "music",
	GAME: "games",
	VIDEO: "videos",
	SPORTS: "sports",
	TRENDING: "trending",
	OFFERS: "offers",
	LIFESTYLE: "lifestyle"
};

export const ITEM_TIERS = {
	FREE: "free",
	SUBSCRIPTION: "subscription",
	PURCHASE: "purchase"
};

const urls = {
	dev: "https://192.168.1.39:8085",
	peertest: "http://192.168.125.59:8085",
	prod: "https://content.sit.mtnplay.com",
	test: "testing"
};

export const uri = urls.prod;