import Axios from "axios";
import { uri } from "../../constants/appConstants";
// import { useEffect, useState } from "react";
import HelperFunctions from "../HelperFunctions";

let singleInstance = null;
// let headers = {};
export default class ApiCall {
  constructor(props) {
    this.headers = props;
  }

  AxiosInstance() {
    singleInstance = Axios.create("");
    singleInstance.defaults.timeout = 60000;
    singleInstance.defaults.maxRedirects = 1;
    singleInstance.defaults.headers.post["Content-Type"] = "application/json";
    singleInstance.defaults.headers.post["Accept"] = "application/json";

    if (this.headers && this.headers["msisdn"]) {
      singleInstance.interceptors.request.use((config) => {
        if (this.headers.msisdn && this.headers?.msisdn !== "") {
          config.headers["msisdn"] = this.headers.msisdn;
        }
        return config;
      });
    }

    if (this.headers && this.headers["Ln"]) {
      singleInstance.interceptors.request.use((config) => {
        if (this.headers.Ln && this.headers?.Ln !== "") {
          config.headers["Ln"] = this.headers.Ln;
        }
        return config;
      });
    }

    if (this.headers && this.headers["region"]) {
      singleInstance.interceptors.request.use((config) => {
        if (this.headers.region && this.headers?.region !== "") {
          config.headers["region"] = this.headers?.region;
          // config.headers["region"] = "Ghana";
        }
        // config.headers["region"] = "Ghana";
        return config;
      });
    }

    if (this.headers && this.headers["environment"]) {
      singleInstance.interceptors.request.use((config) => {
        if (this.headers.environment && this.headers?.environment !== "") {
          config.headers["environment"] = this.headers?.environment;
        }
        return config;
      });
    }
    return singleInstance;
  }

  async buyCurrentItem(requestBody, msisdn) {
    // const newInstance = { ...requestBody, serviceName };

    return new Promise(async (resolved, rejected) => {
      try {
        let instance = Axios.create("");
        instance.interceptors.request.use((config) => {
          config.headers["msisdn"] = `${msisdn}`;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["region"] = `${this.headers.region}`;
          return config;
        });

        // instance.interceptors.request.use((config) => {
        //   config.headers["authorization"] = `Bearer ${accessToken}`;
        //   return config;
        // });

        // instance.interceptors.request.use((config) => {
        //   config.headers["cp-id"] = serviceName;
        //   return config;
        // });

        const resp = await instance.post(
          `${
            uri.subscriptionService
          }/api/v1/subscribe?uuid=${new Date().getTime()}`,
          requestBody,
        );

        if (`${resp.status}`.startsWith("20")) return resolved(resp.data);

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async subscribePush(payload) {
    return new Promise(async (resolved, rejected) => {
      try {
        const resp = await this.AxiosInstance().post(
          `${
            uri.userService
          }/api/notification/subscribe?uuid=${new Date().getTime()}`,
          payload,
        );
        // const resp = await this.AxiosInstance().post(`http://localhost:5004/api/notification/subscribe?uuid=${new Date().getTime()}`, payload);
        if (resp.status == "200") return resolved(resp.data);
        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async fetchAllPurchases() {
    return new Promise(async (resolved, rejected) => {
      try {
        const resp = await this.AxiosInstance().get(
          `${
            uri.subscriptionService
          }/api/listpurchases?uuid=${new Date().getTime()}`,
        );

        if (resp.status == "200") return resolved(resp.data);

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async fetchAllContentByType(type, skip, limit) {
    return new Promise(async (resolved, rejected) => {
      try {
        let instance = Axios.create("");
        instance.interceptors.request.use((config) => {
          config.headers["Ln"] = this.headers.Ln;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["msisdn"] = this.headers.msisdn;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["region"] = this.headers.region;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["environment"] = this.headers.environment;
          return config;
        });

        const resp = await instance.get(
          `${uri.contentService}/api/content-feed/${type}/${skip}/${limit}`,
        );

        if (resp.status == "200") return resolved({ response: resp.data });

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async fetchContentByCategory(type, sub_category, genre, skip, limit) {
    return new Promise(async (resolved, rejected) => {
      try {
        let instance = Axios.create("");
        instance.interceptors.request.use((config) => {
          config.headers["Ln"] = this.headers.Ln;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["msisdn"] = this.headers.msisdn;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["region"] = this.headers.region;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["environment"] = this.headers.environment;
          return config;
        });

        const resp = await instance.get(
          `${uri.contentService}/api/content-feed/${type}/${skip}/${limit}?sub_categories=${sub_category}&genre=${genre}`,
        );

        if (resp.status == "200") return resolved({ response: resp.data });

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async fetchAllContentByGenre(type, genre, skip, limit) {
    return new Promise(async (resolved, rejected) => {
      try {
        let instance = Axios.create("");
        instance.interceptors.request.use((config) => {
          config.headers["Ln"] = this.headers.Ln;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["msisdn"] = this.headers.msisdn;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["region"] = this.headers.region;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["environment"] = this.headers.environment;
          return config;
        });

        const resp = await instance.get(
          `${uri.contentService}/api/content-feed/${type}/${skip}/${limit}?genre=${genre}`,
        );

        if (resp.status == "200") return resolved({ response: resp.data });

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async useCategoryMenu(language) {
    return new Promise(async (resolved, rejected) => {
      try {
        let instance = Axios.create("");
        instance.interceptors.request.use((config) => {
          config.headers["Ln"] = this.headers.Ln || language;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["msisdn"] = this.headers.msisdn;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["region"] = this.headers.region;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["environment"] = this.headers.environment;
          return config;
        });

        const resp = await instance.get(
          `${uri.contentService}/api/category/builder`,
        );

        if (resp.status == 200) {
          resolved(HelperFunctions.removeDuplicateCategories(resp.data));
        }

        // return { categoryMenu, menuLoading, error };
      } catch (error) {
        rejected(error);
      }
    });
  }

  async fetchAllContentBySubCategory(type, sub_category, skip, limit) {
    return new Promise(async (resolved, rejected) => {
      try {
        let instance = Axios.create("");
        instance.interceptors.request.use((config) => {
          config.headers["Ln"] = this.headers.Ln;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["msisdn"] = this.headers.msisdn;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["region"] = this.headers.region;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["environment"] = this.headers.environment;
          return config;
        });

        const resp = await instance.get(
          `${uri.contentService}/api/content-feed/${type}/${skip}/${limit}?sub_categories=${sub_category}`,
        );

        if (resp.status == "200") return resolved({ response: resp.data });

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }
  async toggleLikeContent(msisdn, contentId) {
    return new Promise(async (resolved, rejected) => {
      try {
        const resp = await this.AxiosInstance().post(
          `${uri.contentService}/api/likecontent`,
          {
            msisdn,
            contentId,
          },
        );
        if (`${resp.status}`.startsWith("20")) return resolved(resp.data);

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async fetchSharedContent(id, region, lang, msisdn) {
    return new Promise(async (resolved, rejected) => {
      try {
        let instance = Axios.create("");
        instance.interceptors.request.use((config) => {
          config.headers["msisdn"] = `${msisdn}`;
          return config;
        });

        const resp = await instance.get(
          `${uri.contentService}/api/share/${id}/${region}/${lang}`,
        );
        if (resp.status == "200") return resolved({ response: resp.data });

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async fetchAllContent() {
    return new Promise(async (resolved, rejected) => {
      try {
        let instance = Axios.create("");
        instance.interceptors.request.use((config) => {
          config.headers["Ln"] = this.headers.Ln;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["msisdn"] = this.headers.msisdn;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["region"] = this.headers.region;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["environment"] = this.headers.environment;
          return config;
        });

        const resp = await instance.get(
          `${uri.contentService}/api/content-feed?uuid=${new Date().getTime()}`,
        );

        if (resp.status == "200") return resolved({ response: resp.data });

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async fetchPlayInstance() {
    return new Promise(async (resolved, rejected) => {
      try {
        let instance = Axios.create("");
        instance.interceptors.request.use((config) => {
          config.headers["Ln"] = this.headers?.Ln;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["msisdn"] = this.headers?.msisdn;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["environment"] = this.headers?.environment;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["region"] = `${this.headers?.region}`;
          return config;
        });

        const resp = await instance.get(
          `${
            uri.contentService
          }/api/getregionstatus?uuid=${new Date().getTime()}`,
        );

        if (resp.status == "200") return resolved({ response: resp.data });

        return rejected(resp);
      } catch (error) {
        console.log("error@@@", error);
        rejected(error);
      }
    });
  }

  // async fetchPlayInstance() {
  //   return new Promise(async (resolved, rejected) => {
  //     try {
  //       const resp = await this.AxiosInstance().get(
  //         `${uri.contentService}/api/getregionstatus?uuid=${new Date().getTime()}`,
  //       );

  //       let instance = Axios.create("");
  //       // instance.interceptors.request.use((config) => {
  //       //    config.headers["msisdn"] = `${msisdn}`;
  //       //   return config;
  //       // });

  //       instance.interceptors.request.use((config) => {
  //         config.headers["environment"] = `${this.headers.environment}`;
  //         return config;
  //       });

  //       if (resp.status == "200") return resolved({ response: resp.data });

  //       return rejected(resp);
  //     } catch (error) {
  //       rejected(error);
  //     }
  //   });
  // }

  async fetchAllContentByProvider(selectedProvider) {
    return new Promise(async (resolved, rejected) => {
      try {
        let instance = Axios.create("");
        instance.interceptors.request.use((config) => {
          config.headers["Ln"] = this.headers.Ln;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["msisdn"] = this.headers.msisdn;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["region"] = this.headers.region;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["environment"] = this.headers.environment;
          return config;
        });

        const resp = await instance.get(
          `${uri.contentService}/api/listcontentservices/${selectedProvider}`,
        );

        if (resp.status == "200") return resolved({ response: resp.data });

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async fetchAdByPlid(plid) {
    return new Promise(async (resolved, rejected) => {
      try {
        const resp = await this.AxiosInstance().get(
          `${
            uri.contentService
          }/api/advert/${plid}?uuid=${new Date().getTime()}`,
        );

        if (resp.status == "200") return resolved({ response: resp.data });

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async fetchRecommendationsContent() {
    return new Promise(async (resolved, rejected) => {
      try {
        let instance = Axios.create("");
        instance.interceptors.request.use((config) => {
          config.headers["Ln"] = this.headers.Ln;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["msisdn"] = this.headers.msisdn;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["region"] = this.headers.region;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["environment"] = this.headers.environment;
          return config;
        });

        const resp = await instance.get(
          `${
            uri.contentService
          }/api/recommendationlist?uuid=${new Date().getTime()}`,
        );
        if (resp.status == "200") return resolved({ response: resp.data });

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async fetchUserSubscriptions() {
    return new Promise(async (resolved, rejected) => {
      try {
        let instance = Axios.create("");
        instance.interceptors.request.use((config) => {
          config.headers["Ln"] = this.headers.Ln;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["msisdn"] = this.headers.msisdn;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["region"] = this.headers.region;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["environment"] = this.headers.environment;
          return config;
        });

        const resp = await instance.get(
          `${
            uri.subscriptionService
          }/api/subscriptions?uuid=${new Date().getTime()}?uuid=${new Date().getTime()}`,
        );

        if (`${resp.status}`.startsWith("20")) return resolved(resp.data);

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async fetchUserPurchases() {
    return new Promise(async (resolved, rejected) => {
      try {
        const resp = await this.AxiosInstance().get(
          `${
            uri.subscriptionService
          }/api/purchases?uuid=${new Date().getTime()}`,
        );
        if (`${resp.status}`.startsWith("20")) return resolved(resp.data);

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async fetchPaymentMethods() {
    return new Promise(async (resolved, rejected) => {
      try {
        const resp = await this.AxiosInstance().get(
          `${uri.userService}/api/payment-options?uuid=${new Date().getTime()}`,
        );

        if (`${resp.status}`.startsWith("20")) return resolved(resp.data);

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async unsubscribe(requestBody) {
    return new Promise(async (resolved, rejected) => {
      try {
        const resp = await this.AxiosInstance().post(
          `${
            uri.subscriptionService
          }/api/unsubscribe?uuid=${new Date().getTime()}`,
          requestBody,
        );

        if (`${resp.status}`.startsWith("20")) return resolved(resp.data);

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async fetchBannerFeed() {
    return new Promise(async (resolved, rejected) => {
      try {
        const resp = await this.AxiosInstance().get(
          `https://upload-prod.mtnplay.com/api/get-banner?uuid=${new Date().getTime()}`,
        );

        if (resp.status == "200") return resolved(resp.data);

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async getRedirect(requestBody) {
    return new Promise(async (resolved, rejected) => {
      try {
        const resp = await this.AxiosInstance().post(
          `${
            uri.subscriptionService
          }/api/redirect?uuid=${new Date().getTime()}`,
          requestBody,
        );

        if (resp.status == "200") return resolved(resp.data);

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async fetchAllSubscriptions() {
    return new Promise(async (resolved, rejected) => {
      try {
        let instance = Axios.create("");
        instance.interceptors.request.use((config) => {
          config.headers["Ln"] = this.headers.Ln;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["msisdn"] = this.headers.msisdn;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["region"] = this.headers.region;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["environment"] = this.headers.environment;
          return config;
        });

        const resp = await instance.get(
          `${
            uri.subscriptionService
          }/api/listsubscriptions?uuid=${new Date().getTime()}`,
        );
        if (resp.status == "200") return resolved(resp.data);
        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async requestOTP(msisdn) {
    return new Promise(async (resolved, rejected) => {
      try {
        const resp = await this.AxiosInstance().get(
          `${uri.userService}/api/create-otp?uuid=${new Date().getTime()}`,
          { headers: { msisdn: msisdn } },
        );

        if (resp.status == "200") return resolved({ response: resp.data });

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async resendOTP(msisdn) {
    return new Promise(async (resolved, rejected) => {
      try {
        const resp = await this.AxiosInstance().get(
          `${uri.userService}/api/resend-otp?uuid=${new Date().getTime()}`,
          { headers: { msisdn: msisdn } },
        );
        if (resp.status == "200") return resolved({ response: resp.data });

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }

  async confirmOTP(otpObject = { msisdn: "", otp: 0 }) {
    return new Promise(async (resolved, rejected) => {
      try {
        const resp = await this.AxiosInstance().post(
          `${uri.userService}/api/validate-otp?uuid=${new Date().getTime()}`,
          otpObject,
        );
        if (resp.status == "200") return resolved({ response: resp.data });

        return rejected(resp);
      } catch (error) {
        rejected(error.response.data);
      }
    });
  }

  async fetchAllChannels() {
    return new Promise(async (resolved, rejected) => {
      try {
        let instance = Axios.create("");
        instance.interceptors.request.use((config) => {
          config.headers["Ln"] = this.headers.Ln;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["msisdn"] = this.headers.msisdn;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["region"] = this.headers.region;
          return config;
        });

        instance.interceptors.request.use((config) => {
          config.headers["environment"] = this.headers.environment;
          return config;
        });

        const resp = await instance.get(
          `${uri.contentService}/api/listservices`,
        );

        if (resp.status == 200) {
          resolved({ response: resp.data });
        }
      } catch (error) {
        rejected(error);
      }
    });
  }

  async saveConsent(requestBody) {
    return new Promise(async (resolved, rejected) => {
      try {
        const resp = await this.AxiosInstance().post(
          `${
            uri.subscriptionService
          }/api/saveconsent?uuid=${new Date().getTime()}`,
          requestBody,
        ); //replace with consent endpoint

        if (resp.status == "200") return resolved(resp.data);

        return rejected(resp);
      } catch (error) {
        rejected(error);
      }
    });
  }
}
