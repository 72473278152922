import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBalances } from "../../../utils/redux/actions/subscription.action";
import { StyledBalanceInfo } from "./BalanceInfo.styles";

const BalanceInfo = ({isFromTopUp}) => {
	const dispatch = useDispatch();
	 const {balances} = useSelector(state => state.subscriptions);
	useEffect(() => {
		dispatch(fetchBalances());
	}, []);
	return (
		<StyledBalanceInfo>
			{(isFromTopUp) ? (
				<div className="current-balance">Insufficient funds</div> ): (
				<div className="current-balance">Current balance: <span className="balance-amount">R{balances?.currentBalance}</span> </div>
			)}
			<div className="info">{ (isFromTopUp) ? "You do not currently have enough airtime to complete your purchase. To complete your purchase please select one of the following options.": "To complete your purchase, you will need to topup with:"}</div>
			{ !isFromTopUp && (
				<div className="amount-needed">
				R {balances?.payable}
			  </div>
			)}
		</StyledBalanceInfo>
	);
};

export default BalanceInfo;
