export default class HelperFunctions {
  decodeJwt(token) {
    var base64Payload = token.split(".")[1];
    var payloadBuffer = Buffer.from(base64Payload, "base64");
    return JSON.parse(payloadBuffer.toString());
  }

  subsStatusFilterUtil(items, subscriptionId, callBack) {
    items?.forEach((item) => {
      item?.data?.forEach((data) => {
        data?.billingTypes?.forEach((billingType) => {
          if (billingType.subscriptionId == subscriptionId) {
            callBack(data.subscribed);
            return;
          }
        });
      });
    });
  }

  performHeaderEnrich() {
    const urlParam = window.location.href;
    const url = new URL(urlParam);
    const params = new URLSearchParams(url.search);
    if (urlParam.includes("msisdn")) {
      if (
        Number.isInteger(params.get("msisdn")) &&
        (params.get("msisdn") !== "" || params.get("msisdn") !== null)
      ) {
        // return params.get('msisdn')
        return "554702626";
      }
    } else {
      //   window.location.replace(`http://auth-dev.dev.mtnplay.com/header?redirect=${urlParam}`);
    } //http://localhost:3000/?msisdn=233NUMBER
    //http://auth-dev.dev.mtnplay.com/header?redirect=http://localhost:3000
  }

  sslIcon(logo) {
    return (function convertIconFromHTTP_to_HTTPS(logo) {
      const _logo = `${logo}`;
      if (_logo.startsWith("https")) {
        return _logo;
      }

      return _logo.replace("http", "https");
    })(logo);
  }

  static queryParamsToMap(queryString) {
    const params = {};
    const queryParamsArray = queryString.replace("?", "").split("&");

    queryParamsArray.forEach((param) => {
      const [key, value] = param.split("=").map(decodeURIComponent);
      if (key.length > 0) {
        params[key] = value;
      }
    });

    return params;
  }

  static removeDuplicateCategories(data) {
    const seen = new Set();
    return data?.filter((item) => {
      const value = item.value.toLowerCase(); // Convert to lower case for case-insensitive comparison
      if (seen.has(value)) {
        return false;
      } else {
        seen.add(value);
        return true;
      }
    });
  }
  static generateShareLink() {
    const currentUrl = window.location.href;
    if (currentUrl.includes("dev") || currentUrl.includes("localhost")) {
      return "https://share.dev.mtnplay.com";
    } else if (currentUrl.includes("sit")) {
      return "https://share.sit.mtnplay.com";
    } else if (currentUrl.includes("uat")) {
      return "https://share.uat.mtnplay.com";
    } else {
      return "https://share.mtnplay.com";
    }
  }

  static getShareEnvParam() {
    const currentUrl = window.location.href;
    if (currentUrl.includes("dev") || currentUrl.includes("localhost")) {
      return "&env=dev";
    } else if (currentUrl.includes("sit")) {
      return "&env=sit";
    } else if (currentUrl.includes("uat")) {
      return "&env=uat";
    } else {
      return null;
    }
  }
}

