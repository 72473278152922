import { authentication } from "../types";

export const requestOTP = (apiCall, payload) => async(dispatch) => {
	dispatch({
		type: authentication.ON_API_CALL,
		payload: "Requesting OTP",
	});
    
	setTimeout(async ()=>{
		try{
			const response = await apiCall?.requestOTP(payload);
    
			if(response.response.StatusCode=="200"){
				dispatch({
					type: authentication.REQUEST_OTP,
					payload: "done",
				});
    
				return;
			}
    
			dispatch({
				type: authentication.ON_API_CALL_ERROR,
				payload: "Error Requesting OTP",
			});
		}catch(error){
			dispatch({
				type: authentication.ON_API_CALL_ERROR,
				payload: "Error Requesting OTP, reason: "+error,
			});
		}
	}, 2000);
};

export const reRequestOTP = (apiCall, payload) => async(dispatch) => {
	dispatch({
		type: authentication.ON_API_CALL,
		payload: "Resending OTP",
	});
    
	try{
		const response = await apiCall?.resendOTP(payload);
		if(response.response.StatusCode=="200"){
			dispatch({
				type: authentication.RE_REQUEST_OTP,
				payload: "done",
			});

			return;
		}

		dispatch({
			type: authentication.ON_RESEND_API_CALL_ERROR,
			payload: "Error Requesting OTP",
		});
	}catch(error){
		dispatch({
			type: authentication.ON_RESEND_API_CALL_ERROR,
			payload: "Error Requesting OTP, reason: "+error,
		});
	}
};

export const confirmOTP = (apiCall, payload) => async(dispatch) => {
	dispatch({
		type: authentication.ON_API_CALL,
		payload: "Validating OTP",
	});
    
	setTimeout(async ()=>{
		try{
			const response = await apiCall?.confirmOTP(payload);

			if(response.response.Message=="success"){
				dispatch({
					type: authentication.CONFIRM_OTP,
					payload: "confirmed",
				});
    
				return;
			}
    
			dispatch({
				type: authentication.ON_API_CALL_ERROR,
				payload: "Error Validating OTP",
			});
		}catch(error){
			dispatch({
				type: authentication.ON_COMFIRM_OTP_API_CALL_ERROR,
				payload: `${error?.Message}`,
			});
		}
	}, 2000);
};

export const clearAuthentication = () => async(dispatch) => {
	dispatch({
		type: authentication.CLEAR_AUTH,
		payload: null,
	});
};