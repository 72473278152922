import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledPaymentMethods = styled(motion.div)`
 display: flex;
 flex-direction: column;
 padding-left: 24px;
 padding-right: 24px;

 
  .modal_text {
	padding-top: 25px;
	padding-bottom: 30px;
	font-family: MTNBrighterSansRegular;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px
	};
	/* text-align: justify; */
  }

  .loader {
	display: flex;
    align-self: center;
	border: 12px solid #f3f3f3; /* Light grey */
	border-top: 12px solid #FBC800; /* Blue */
	border-radius: 50%;
	width: 30px;
	height: 30px;
	animation: spin 1s linear infinite;
	margin-top: 10%;
}
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }

 .benefits {
	font-size: 16px;
	font-style: normal;
	font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
	font-weight: 700;
	line-height: 24px;
	margin-top: ${({theme}) => theme?.spacing?.f7 ?? "15px"};
	margin-left: 24px;
	margin-bottom: ${({theme}) => theme?.spacing?.f5 ?? "15px"};
 }

 .actions {
	 padding: ${({theme}) => `${theme?.spacing?.f0} ${theme?.spacing?.f6}` ?? "0 24px"};
	 margin-bottom: ${({theme}) => theme?.spacing?.f5 ?? "15px"};
	 display: flex;

	font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
	 flex-direction: column;

	 .method {
		 display: flex;
		 padding: 12px;
		 color: #BDBDBD;
		 justify-content: space-between;
	 }
	}
 }

 .actions_second {
	display: flex;
   	font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
	flex-direction: column;

	.method_second {
		font-family: 'MTNBrighterSansRegular';
			  display: flex;
	  justify-content: space-between;
	  color: #fff;
	  margin-top: 20px;
	  font-size: 14px;
   }


}

 .btn {
	  padding: 16px 24px;
	  font-family:  ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    border: 1px solid #FFFFFF;
    border-radius: 25px;
 }


 .switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 18px;

  }
  
  /* Hide default HTML checkbox */
  .switch input {
	opacity: 0;
	width: 0;
	height: 0;
  }

  
  
  /* The slider */
  .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border: 1px solid #ccc;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  .slider:before {
	position: absolute;
	content: "";
	height: 24px;
	width: 24px;
	left: 0px;
	bottom: -4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  input:checked + .slider {
	background-color: #FBC800;
  }
  
  input:focus + .slider {
	box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
	border-radius: 34px;
  }
  
  .slider.round:before {
	border-radius: 50%;
  }
`;