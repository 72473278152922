import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledLandingCategory = styled(motion.div)`
  padding-left: 24px;

  .top-bar {
    /* min-height: 60px; */
    display: flex;
    padding: 24px 10px 10px 0px;
    align-items: center;
    justify-content: space-between;

    .title {
      font-style: normal;
      font-weight: bold;
      text-transform: capitalize;
      font-size: ${({ theme }) => theme?.size?.f7 ?? "20px"};
      line-height: 26px;
    }

    .view-more {
      margin-right: 16px;
      font-family: ${({ theme }) =>
        theme?.fonts[0] ?? "MTNBrighterSansRegular"};
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 11.72px;
      text-transform: uppercase;
    }
  }
  .content {
    min-height: 253px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-left: ${({ isVertica }) => (isVertica ? "0px" : "0px")};
    margin-left: -10px;
    width: ${({ isVertica }) => (isVertica ? "105%" : "100%")};
    flex-wrap: ${({ isVertica }) => (isVertica ? "wrap" : "unset")};
    overflow-y: scroll;
    overflow-x: scroll;
  }

  .content-load {
    /* min-height: 213px; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
    width: ${({ isVertica }) => (isVertica ? "100%" : "100%")};
    flex-wrap: ${({ isVertica }) => (isVertica ? "wrap" : "unset")};
    overflow-y: scroll;
    overflow-x: scroll;
  }
`;