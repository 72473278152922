import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { HomeScreen as events } from "../../../analytics/events";

const BannerCarousel = () => {
  // const homeBanners = useSelector((state) => state.homeBanners);

  // const apiBanners = useSelector((state) => state.banners);
  

  const {
    items,
    network,
    // language,
  } = useSelector((state) => state);



  // const language = useSelector((state) => state.language);
  const carouselRef = useRef(null);
  const dispatch = useDispatch();
  // const [items, setItems] = useState([]);

  // const { chosenLanguage } = language;
  // const { network } = useSelector((state) => state);

  // useEffect(() => {
  //   if (chosenLanguage.code.length > 0) {
  //     setItems(homeBanners[chosenLanguage.code]);
  //   }
  // }, [homeBanners, chosenLanguage]);

  useEffect(() => {
    console.log("apiBanners",items);
  }, [items]);
  
  const onClick = (i) => {
    dispatch({
      type: "ANALYTICS/TRACK_EVENT",
      event: events.banner_hit_home_screen(
        network?.call?.headers?.isoRegion,
        network?.call?.headers.environment,
        items?.banners[i].url,
      ),
    });
    window.open(items?.banners[i].url);
  };

  if (items?.banners?.length === 0) {
    return null;
  }

  return (
    <Carousel
      ref={carouselRef}
      swipeable={false}
      showArrows={false}
      showIndicators={false}
      showStatus={false}
      showThumbs={false}
      onClickItem={onClick}
      autoPlay={items?.banners?.length > 0}
      infiniteLoop={items?.banners?.length > 0}
      useKeyboardArrows={false}
      interval={6000}>
      {items?.banners?.map((item, i) => (
        <div
          key={i}
          style={{
            height: 220,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${item.imageUrl})`,
          }}></div>
      ))}
    </Carousel>
  );
};

export default BannerCarousel;
