import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledPartnerSubscriptionHeader = styled(motion.div)`
 padding: 24px 24px 0;
 display: flex;
 flex-direction: column;
`;
export const StyledPartnerSubscriptionHeaderName = styled(motion.div)`
 font-size: 28px;
 font-style: normal;
 font-weight: 700;
 line-height: 36.4px;
 text-transform: capitalize;
`;
export const StyledPartnerSubscriptionHeaderTags = styled(motion.div)`
font-size: 18px;
 font-style: normal;
 font-weight: 500;
 font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
 line-height: 21.09px;
 text-transform: capitalize;
`;