import styled from "styled-components";
import {motion} from "framer-motion";

export const StyledPagination = styled(motion.div)`
  /* min-height: 100vh; */

  background-color: ${(props) => props?.theme?.greyColor ?? "#000"};
  color: #ffffff;
  display: flex;
  flex-direction: column;
  /* height: 200px; */
  width: 100%;
  margin-bottom: 12px;
`;

export default StyledPagination;