import {useEffect} from "react";
import {useState} from "react";
import {StyledMyplayTabs} from "./MyplayTabs.styles";

const MyplayTabs = ({tabs, toggleTabs, events = {}, initPosition=0}) => {
	const [active, setActive] = useState(tabs[initPosition]);
	const [defaultTabActive, setDefaultTabActive] = useState(false);

	useEffect(() => {
		if (defaultTabActive == true) {
  
			const activeModified = () => {
				if (active === "Subscritions") {
					return "Subscritions";
				} else {
					return "purchases";
				}
			};

			if (activeModified() in events && "dispatch" in events) {

				if (
					"type" in events[activeModified()] &&
          "event" in events[activeModified()] &&
          Object.keys(events[activeModified()].event(activeModified())).length > 0
				) {

					let _event = {
						event: events[activeModified()].event(activeModified()),
						type: events[activeModified()].type,
					};
          
					events.dispatch(_event);

				}
			}
		} else {
			setDefaultTabActive(true);
		}
	}, [active]);

	return (
		<StyledMyplayTabs>
			{tabs.map((tab) => (
				<div
					key={tab}
					onClick={() => {
						setActive(tab);
						toggleTabs(tab);
					}}
					className={`tab ${active === tab ? "active" : ""}`}>
					{tab}
				</div>
			))}
		</StyledMyplayTabs>
	);
};

export default MyplayTabs;
