// import React, {useState, useEffect} from "react";
import {TabContainer, Tab} from "./SeasonTab.styles";
import SeasonTabBody from "./SeasonTabBody";

const SeasonTab = ({seriesData, selectedSeason, onSelectedTab}) =>{

	const seasons = [...Array(seriesData.s).keys()];

	const episodes = seriesData.e[selectedSeason-1];

	const handleOnSelectedTab = (indexAsSeasonNumber) =>{
		onSelectedTab(indexAsSeasonNumber+1); //+1 because Index is Zero based and season starts with 1
	};

	const TabView = () => {
		return seasons.map((_, index)=><Tab key={`${index}_tab`} onClick={()=>handleOnSelectedTab(index)} active={index==(selectedSeason-1)}><p>{`Season ${(index+1)}`}</p></Tab>);
	};

	return (
		<div>
			<TabContainer>
				<TabView/>
			</TabContainer>

			<SeasonTabBody season={selectedSeason} episodes={episodes} info={seriesData.info}/>
		</div>
	);
};

export default SeasonTab;