import {checkNull, EVENT, PARAM} from "./helpers";

export const subscription_management_screen = (opcoOrigin, opcoEnv) => {
	return {
		eventName: EVENT.VIEW_SCREEN,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "discovery",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "subscription_management",
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			}
		],
	};
};

export const inactive_tab_tap = (opcoOrigin, opcoEnv, tap_label) => {
	return {
		eventName: EVENT.TAP,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "settings",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "subscription_management",
			},
			{
				key: PARAM.KEY.TAP_TARGET,
				value: "tab_inactive",
			},
			{
				key: PARAM.KEY.TAP_LABEL,
				value: checkNull(tap_label),
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			}
		],
	};
};

export const active_tab_tap = (opcoOrigin, opcoEnv, tap_label) => {
	return {
		eventName: EVENT.TAP,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "settings",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "subscription_management",
			},
			{
				key: PARAM.KEY.TAP_TARGET,
				value: "tab_active",
			},
			{
				key: PARAM.KEY.TAP_LABEL,
				value: checkNull(tap_label),
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			}
		],
	};
};



export const pending_tab_tap = (opcoOrigin, opcoEnv, tap_label) => {
	return {
		eventName: EVENT.TAP,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "settings",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "subscription_management",
			},
			{
				key: PARAM.KEY.TAP_TARGET,
				value: "tab_pending",
			},
			{
				key: PARAM.KEY.TAP_LABEL,
				value: checkNull(tap_label),
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			}
		],
	};
};

export const sort_option_modal_view = (opcoOrigin, opcoEnv) => {
	return {
		eventName: EVENT.VIEW_MODAL,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "settings",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "subscription_management",
			},
			{
				key: PARAM.KEY.MODAL,
				value: "sort_options",
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			}
		],
	};
};

export const sort_cancel_tap = (opcoOrigin, opcoEnv, tap_label) => {
	return {
		eventName: EVENT.TAP,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "settings",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "subscription_management",
			},
			{
				key: PARAM.KEY.MODAL,
				value: "sort_options",
			},
			{
				key: PARAM.KEY.TAP_TARGET,
				value: "cancel",
			},
			{
				key: PARAM.KEY.TAP_LABEL,
				value: checkNull(tap_label),
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			}
		],
	};
};

export const sort_a_z_tap = (opcoOrigin, opcoEnv, tap_label) => {
	return {
		eventName: EVENT.TAP,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "settings",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "subscription_management",
			},
			{
				key: PARAM.KEY.MODAL,
				value: "sort_options",
			},
			{
				key: PARAM.KEY.SORT_OPTION,
				value: "a-z",
			},
			{
				key: PARAM.KEY.TAP_TARGET,
				value: "sort_option",
			},
			{
				key: PARAM.KEY.TAP_LABEL,
				value: checkNull(tap_label),
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			}
		],
	};
};

export const sort_date_tap = (opcoOrigin, opcoEnv, tap_label) => {
	return {
		eventName: EVENT.TAP,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "settings",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "subscription_management",
			},
			{
				key: PARAM.KEY.MODAL,
				value: "sort_options",
			},
			{
				key: PARAM.KEY.SORT_OPTION,
				value: "date",
			},
			{
				key: PARAM.KEY.TAP_TARGET,
				value: "sort_option",
			},
			{
				key: PARAM.KEY.TAP_LABEL,
				value: checkNull(tap_label),
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			}
		],
	};
};

export const sort_price_tap = (opcoOrigin, opcoEnv, tap_label) => {
	return {
		eventName: EVENT.TAP,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "settings",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "subscription_management",
			},
			{
				key: PARAM.KEY.MODAL,
				value: "sort_options",
			},
			{
				key: PARAM.KEY.SORT_OPTION,
				value: "price",
			},
			{
				key: PARAM.KEY.TAP_TARGET,
				value: "sort_option",
			},
			{
				key: PARAM.KEY.TAP_LABEL,
				value: checkNull(tap_label),
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			}
		],
	};
};

export const sort_title_tap = (opcoOrigin, opcoEnv, tap_label) => {
	return {
		eventName: EVENT.TAP,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "settings",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "subscription_management",
			},
			{
				key: PARAM.KEY.MODAL,
				value: "sort_options",
			},
			{
				key: PARAM.KEY.SORT_OPTION,
				value: "title",
			},
			{
				key: PARAM.KEY.TAP_TARGET,
				value: "sort_option",
			},
			{
				key: PARAM.KEY.TAP_LABEL,
				value: checkNull(tap_label),
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			}
		],
	};
};

export const sort_order_ascending_tap = (opcoOrigin, opcoEnv, tap_label) => {
	return {
		eventName: EVENT.TAP,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "settings",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "subscription_management",
			},
			{
				key: PARAM.KEY.SORT_ORDERING,
				value: "ascending",
			},
			{
				key: PARAM.KEY.TAP_TARGET,
				value: "sort_ordering",
			},
			{
				key: PARAM.KEY.TAP_LABEL,
				value: checkNull(tap_label),
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			}
		],
	};
};

export const sort_order_descending_tap = (opcoOrigin, opcoEnv, tap_label) => {
	return {
		eventName: EVENT.TAP,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "settings",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "subscription_management",
			},
			{
				key: PARAM.KEY.SORT_ORDERING,
				value: "descending",
			},
			{
				key: PARAM.KEY.TAP_TARGET,
				value: "sort_ordering",
			},
			{
				key: PARAM.KEY.TAP_LABEL,
				value: checkNull(tap_label),
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			}
		],
	};
};
