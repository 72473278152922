import {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Faqs, Page, TopNav } from "../../shared/components";

import { HelpScreen as events } from "../../analytics/events";

const HelpScreen = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state);
  const {network} = useSelector((state) => state);

  useEffect(() => {
    dispatch({
      type: "ANALYTICS/TRACK_EVENT",
      event: events.help_topics_list_screen,
    });
  }, []);

  const faq_events = {
    dispatch: dispatch,
    ClickFAQ: { type: "ANALYTICS/TRACK_EVENT", event: events.expand_faq_item(
      network?.call?.headers?.isoRegion,
      network?.call?.headers.environment
    ) },
  };

  return (
    <Page isBottomBar={false}>
      <TopNav
        title={
          language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections?.help
        }
        enableSearch={false}
        centerTitle={true}
      />
      <Faqs events={faq_events} />
    </Page>
  );
};

export default HelpScreen;
