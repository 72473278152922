import { motion } from "framer-motion";
import styled from "styled-components";
export const StyledSearchResultsWrapper = styled(motion.div)`
  margin-top: 24px;
  .results {
    padding-left: 24px;
    padding-right: 24px;

    width: 100%;
    display: grid;
    grid-template-columns: repeat(${({ c }) => c}, 1fr);
    gap: 12px;

    .item.is-search-result {
      margin-right: 0;
      max-width: 100%;

      > div {
        aspect-ratio: 1;
      }

      .thumbnail {
        width: 100%;
        height: 100%;
      }
      .tag {
        width: 100%;
      }
    }
  }

  .title {
    font-style: normal;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 18px;
    line-height: 20px;
    padding-left: 24px;
  }
`;
