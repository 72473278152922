import { items } from "../types";

// import { createBrowserHistory } from 'history'
// import HelperFunctions from "../../HelperFunctions";

export const fetchAdInventory = (call, plid) => async(dispatch) => {
	try {         
		const response = await call?.fetchAdByPlid(plid);
		if(response && response.response){
			dispatch({
				type: items.FETCH_ADVERT_INVENTORY_SUCCESS,
				payload: response,
			});
			return;
		}

		dispatch({
			type: items.FETCH_ADVERT_INVENTORY_REQUEST
		});
	} catch (error) {

		dispatch({
			type: items.FETCH_ADVERT_INVENTORY_ERROR,
			payload: {
				message: error?.message,
				error
			}
		});
		// createBrowserHistory.push('./generic')
		// createBrowserHistory.push('./generic')
	}
};

export const fetchAdInventoryMbks = (call, plid) => async(dispatch) => {


	try {         
		const response = await call?.fetchAdByPlid(plid);
		if(response && response.response){
			dispatch({
				type: items.FETCH_ADVERT_INVENTORY_SUCCESS_MBKS,
				payload: response,
			});
			return;
		}

		dispatch({
			type: items.FETCH_ADVERT_INVENTORY_REQUEST_MBKS
		});
	} catch (error) {

		dispatch({
			type: items.FETCH_ADVERT_INVENTORY_ERROR_MBKS,
			payload: {
				message: error?.message,
				error
			}
		});
		// createBrowserHistory.push('./generic')
		// createBrowserHistory.push('./generic')
	}
};

export const fetchPlayInstance = (call) => async(dispatch) => {

	try {         
		const response = await call?.fetchPlayInstance();
		if(response && response.response){
			dispatch({
				type: items.FETCH_PLAY_INSTANCE_SUCCESS,
				payload: response,
			});
			return;
		}

		dispatch({
			type: items.FETCH_PLAY_INSTANCE_REQUEST
		});
	} catch (error) {

		dispatch({
			type: items.FETCH_PLAY_INSTANCE_ERROR,
			payload: {
				message: error?.message,
				error
			}
		});
		// createBrowserHistory.push('./generic')
		// createBrowserHistory.push('./generic')
	}
};


export const fetchItems = (call) => async(dispatch) => {

	try {         
		const response = await call?.fetchAllContent();
		if(response && response.response){
			dispatch({
				type: items.FETCH_ITEMS_SUCCESS,
				payload: response,
			});
			return;
		}

		dispatch({
			type: items.FETCH_ITEMS_REQUEST
		});
	} catch (error) {

		dispatch({
			type: items.FETCH_ITEMS_ERROR,
			payload: {
				message: error?.message,
				error
			}
		});
		// createBrowserHistory.push('./generic')
		// createBrowserHistory.push('./generic')
	}
};

export const fetchAllCategories = (call, language) => async (dispatch) => {
  try {
    const response = await call?.useCategoryMenu(language);

    if (response.length) {
      dispatch({
        type: items.FETCH_CATEGORIES_SUCCESS,
        payload: response,
      });
      return;
    }

    dispatch({
      type: items.FETCH_CATEGORIES_REQUEST,
    });
  } catch (error) {
    dispatch({
      type: items.FETCH_CATEGORIES_ERROR,
      payload: {
        message: error?.message,
        error,
      },
    });
  }
};

export const fetchAllContentByProvider =
  (call, selectedProvider) => async (dispatch) => {
    try {
      //dispatch(fetchAllContentByProvider(network?.call , selectedItem));
      dispatch({
        type: items.FETCH_ITEMS_REQUEST,
      });

      const response = await call?.fetchAllContentByProvider(selectedProvider);
      if (response && response.response) {
        dispatch({
          type: items.FETCH_ITEMS_SUCCESS,
          payload: response,
        });
        return;
      }
    } catch (error) {
      dispatch({
        type: items.FETCH_ITEMS_ERROR,
        payload: {
          message: error?.message,
          error,
        },
      });
      // createBrowserHistory.push('./generic')
      // createBrowserHistory.push('./generic')
    }
  };

export const fetchFilteredItems = (call, params) => async (dispatch) => {
  try {
    if (params == undefined)
      return dispatch({ type: items.FILTER_MENU_ITEMS_SUCCESS, payload: [] });

    dispatch({ type: items.FILTER_MENU_ITEMS_REQUEST });

    if (params.sub_categorie == undefined && params.genre == undefined) {
      call
        ?.fetchAllContentByType(params.type, 0, 50)
        .then((response) => {
          if (response && response.response) {
            dispatch({
              type: items.FILTER_MENU_ITEMS_SUCCESS,
              payload: response.response,
            });
            return;
          }
        })
        .catch((error) => {
          dispatch({
            type: items.FILTER_MENU_ITEMS_ERROR,
            payload: {
              message: "Netowork Error",
              error,
            },
          });
        });
    } else if (params.sub_categorie == undefined && params.genre != undefined) {
      call
        ?.fetchAllContentByGenre(params.type, params.genre, 0, 50)
        .then((response) => {
          if (response && response.response) {
            dispatch({
              type: items.FILTER_MENU_ITEMS_SUCCESS,
              payload: response.response,
            });
            return;
          }
        })
        .catch((error) => {
          dispatch({
            type: items.FILTER_MENU_ITEMS_ERROR,
            payload: {
              message: "Netowork Error",
              error,
            },
          });
        });
    } else if (params.sub_categorie != undefined && params.genre == undefined) {
      call
        ?.fetchAllContentBySubCategory(params.type, params.sub_categorie, 0, 50)
        .then((response) => {
          if (response && response.response) {
            dispatch({
              type: items.FILTER_MENU_ITEMS_SUCCESS,
              payload: response.response,
            });
            return;
          }
        })
        .catch((error) => {
          dispatch({
            type: items.FILTER_MENU_ITEMS_ERROR,
            payload: {
              message: "Netowork Error",
              error,
            },
          });
        });
    } else {
      params.genre = params.genre.search("All") === 0 ? "" : params.genre;
      params.sub_categorie =
        params.sub_categorie.search("All") === 0 ? "" : params.sub_categorie;

      call
        ?.fetchContentByCategory(
          params.type,
          params.sub_categorie,
          params.genre,
          0,
          50,
        )
        .then((response) => {
          if (response && response.response) {
            dispatch({
              type: items.FILTER_MENU_ITEMS_SUCCESS,
              payload: response.response,
            });
            return;
          }
        })
        .catch((error) => {
          if (error.response?.data?.StatusCode == 404) {
            dispatch({
              type: items.FILTER_MENU_ITEMS_SUCCESS,
              payload: [],
            });
          } else {
            dispatch({
              type: items.FILTER_MENU_ITEMS_ERROR,
              payload: {
                message: "Netowork Error",
                error,
              },
            });
          }
        });
    }

    return;
  } catch (error) {
    dispatch({
      type: items.FILTER_MENU_ITEMS_ERROR,
      payload: {
        message: error?.message,
        error,
      },
    });
  }
};

// export const fetchNextPaginationByType = (call, params) => async(dispatch, _) => {
//     try {    
//         if(params==undefined) return dispatch({type: items.FILTER_MENU_ITEMS_SUCCESS, payload: []});

//         dispatch({type: items.FILTER_MENU_ITEMS_REQUEST});

//         if(params.sub_categorie==undefined && params.genre==undefined){
//             call?.fetchAllContentByType(params.type, 0, 50).then(response=>{
//                 if(response && response.response){
//                     dispatch({
//                         type: items.FILTER_MENU_ITEMS_SUCCESS,
//                         payload: response.response,
//                     });
//                     return;
//                 }
//             }).catch(error=>{
//                 dispatch({
//                     type: items.FILTER_MENU_ITEMS_ERROR,
//                     payload: {
//                         message: 'Netowork Error',
//                         error
//                     }
//                 });
//             });
//         }else if(params.sub_categorie==undefined && params.genre!=undefined){
//             call?.fetchAllContentByGenre(params.type, params.genre, 0, 50).then(response=>{
//                 if(response && response.response){
//                     dispatch({
//                         type: items.FILTER_MENU_ITEMS_SUCCESS,
//                         payload: response.response,
//                     });
//                     return;
//                 }
//             }).catch(error=>{
//                 dispatch({
//                     type: items.FILTER_MENU_ITEMS_ERROR,
//                     payload: {
//                         message: 'Netowork Error',
//                         error
//                     }
//                 });
//             });
//         }else if(params.sub_categorie!=undefined && params.genre==undefined){
//             call?.fetchAllContentBySubCategory(params.type, params.sub_categorie, 0, 50).then(response=>{
//                 if(response && response.response){
//                     dispatch({
//                         type: items.FILTER_MENU_ITEMS_SUCCESS,
//                         payload: response.response,
//                     });
//                     return;
//                 }
//             }).catch(error=>{
//                 dispatch({
//                     type: items.FILTER_MENU_ITEMS_ERROR,
//                     payload: {
//                         message: 'Netowork Error',
//                         error
//                     }
//                 });
//             });
//         }else{
//             call?.fetchContentByCategory(params.type, params.sub_categorie, params.genre, 0, 50).then(response=>{
//                 if(response && response.response){
//                     dispatch({
//                         type: items.FILTER_MENU_ITEMS_SUCCESS,
//                         payload: response.response,
//                     });
//                     return;
//                 }
//             }).catch(error=>{
//                 dispatch({
//                     type: items.FILTER_MENU_ITEMS_ERROR,
//                     payload: {
//                         message: 'Netowork Error',
//                         error
//                     }
//                 });
//             });
//         }

//         return;
//     } catch (error) {

//         dispatch({
//             type: items.FILTER_MENU_ITEMS_ERROR,
//             payload: {
//                 message: error?.message,
//                 error
//             }
//         });
//     }
// }


export const fetchBanners = (call) => async(dispatch) => {
	try {
		const response = await call?.fetchBannerFeed();
		if(response){

			dispatch({
				type: items.FETCH_BANNERS_SUCCESS,
				payload: response
			});

			return;
		}

		dispatch({
			type: items.FETCH_BANNERS_REQUEST
		});

	} catch (error) {
		dispatch({
			type: items.FETCH_BANNERS_ERROR,
			payload: {
				message: error?.message,
				error
			}
		});
		// createBrowserHistory.push('./generic')
	}
};


export const fetchChannels = (call) => async(dispatch) => {
	try {
		const response = await call?.fetchAllChannels();
		if(response){

			dispatch({
				type: items.FETCH_CHANNELS_SUCCESS,
				payload: response
			});

			return;
		}

		dispatch({
			type: items.FETCH_CHANNELS_REQUEST
		});

	} catch (error) {
		dispatch({
			type: items.FETCH_CHANNELS_ERROR,
			payload: {
				message: error?.message,
				error
			}
		});
		// createBrowserHistory.push('./generic')
	}
};


export const getRedirect = (call, payload) => async(dispatch) => {
	try {
		const response = await call?.getRedirect(payload);
    
		if(response){

			dispatch({
				type: items.FETCH_REDIRECT_SUCCESS,
				payload: response
			});

			return;
		}

		dispatch({
			type: items.FETCH_REDIRECT_REQUEST
		});

	} catch (error) { 
		dispatch({
			type: items.FETCH_REDIRECT_ERROR,
			error: error?.message
		});
	}
};


export const clearRedirect = () => async(dispatch) =>{
	dispatch({
		type: items.CLEAR_REDIRECT
	});
};