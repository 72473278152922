import { items } from "../types";

const initialState = {
	loading: false,
	items: [],
	error: null
};

const filteredMenuItems = (state = initialState, action) => {
	switch (action.type) {
	case items.FILTER_MENU_ITEMS_REQUEST:
		return {
			...state,
			loading: true,
			error: null,
		};
	case items.FILTER_MENU_ITEMS_SUCCESS:
		return {
			...state,
			loading: false,
			error: null,
			items: action.payload,
		};
	case items.FILTER_MENU_ITEMS_ERROR:
		return {
			...state,
			loading: false,
			error: action.payload,
		};
	default:
		return state;
	}
};

export default filteredMenuItems;