import {EVENT, PARAM} from "./helpers";

export const authenticationAttempt = (opcoOrigin, opcoEnv) => {
	return {
		eventName: EVENT.VIEW_SCREEN,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "authenticate_request",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "content_detail",
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			}
		],
	};
};
