import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import { Button } from "../../shared/components/Button";
import {confirmOTP, clearAuthentication, reRequestOTP} from "../../utils/redux/actions/authentication.actions";
import {updateProfile} from "../../utils/redux/actions/profile.actions";
import { PWA_ROUTES } from "../../constants/appConstants";
import OtpInput from "react-otp-input";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {AuthenticationScreen as events} from "../../analytics/events";

const Otp = () => {
	const params = useLocation();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {network, authentication} = useSelector((state) => state);
	// const {profile} = useSelector((state) => state);

	const [msisdn] = useState(params?.state?.msisdn);
	const [otpValue, setOtpValue] = useState("");
	const [loading, setLoading] = useState(false);
	const [info, setInfo] = useState("Your OTP was sent to ");
	const [otpError, setOtpError] = useState("");

	useEffect(()=>{
		setLoading(authentication.loading);
		if(authentication.msg === "confirmed"){
			dispatch({
				type: "ANALYTICS/TRACK_EVENT",
				event: events.auth_sucess(
					network?.call?.headers?.isoRegion,
					network?.call?.headers.environment,
				),
			});
			dispatch(updateProfile({msisdn:msisdn}));
			return;
		}

		if(authentication.msg === "Resending OTP"){
			setInfo(`${authentication.msg} to`);
			return;
		}

		if(authentication.msgResend === "done"){
			setInfo("Your OTP was sent to ");
			return;
		}

	}, [authentication.loading]);

	useEffect(()=>{
		setOtpError(authentication.otpError);
	}, [authentication.otpError]);

	useEffect(()=>{
		if(network?.call?.headers !== undefined){
			localStorage.setItem("msisdn", msisdn);
			navigate(PWA_ROUTES.HOME);
		}
	}, [network]);

	useEffect(()=>{
		dispatch({
			type: "ANALYTICS/TRACK_EVENT",
			event: events.otp_screen(
				network?.call?.headers?.isoRegion,
				network?.call?.headers.environment,
			),
		});

		if(params?.state?.msisdn===undefined || `${params?.state?.msisdn}`.includes("undefined")){
			dispatch(clearAuthentication());
			navigate(PWA_ROUTES.LOGIN);
		}
	}, []);

	const submitLogin = () => {
		if(otpValue.length===4){
			dispatch(confirmOTP(network?.call, {msisdn:msisdn, otp:Number(otpValue)}));
			dispatch({
				type: "ANALYTICS/TRACK_EVENT",
				event: events.auth_request(
					network?.call?.headers?.isoRegion,
					network?.call?.headers.environment,
				),
			});
		}
	};

	const handleOnEnterOPT = (e) => {
		setOtpValue(e);

		if (e.length < 4) {
      setOtpError("");
    }
	};

	const handleBackBtn = () =>{
		dispatch(clearAuthentication());
		navigate(PWA_ROUTES.LOGIN);
	};

	const handleResendBtn = () =>{
		dispatch(reRequestOTP(network?.call, msisdn));
	};

	return (
		<div style={{justifyContent:"center", alignItems:"center"}}>
			<div style={{ marginTop:40}}>
				<div className="titles" style={{ display:"flex", alignItems: "center", justifyContent:"space-around"}}>
  
					<p style={{color:"white", fontWeight: "bold", fontSize: 22}}>Enter your OTP</p>
				</div>
            
				<div style={{textAlign:"center", marginTop:60}}>
					<p style={{color:"white", fontSize: 16, textAlign:"center"}}>{info}</p>
					<p style={{color:"white", fontWeight: "bold", fontSize: 16, textAlign:"center"}}><span>{msisdn}</span></p>

				</div>              
				<p style={{marginTop:60}}>
					<div style={{display:"flex",justifyContent:"center"}}>
						<div>
							<span style={{ color:"#FFFFFF", fontSize:"12px"}}>One time pin</span>
							<div style={{display:"flex", marginTop:4}}>
								<OtpInput
									containerStyle={{ 
										color: "#FFFFFF",
									}}
									inputStyle={{
										width: "40px",
										height: "40px",
										margin: "0 1.5rem 0 0",
										fontSize: "1rem",
										borderRadius: 4,
										borderWidth: 1,
										borderColor: "#FFFFFF",
										color: "#FFFFFF",
										backgroundColor: "transparent"
									}}
									value={otpValue}
									onChange={handleOnEnterOPT}
									numInputs={4}
									isInputNum={true}
								/>
							</div>
						</div>
					</div>

					{otpError.length>0 &&
                            <div style={{position:"relative", marginTop:"20px"}}>
                            	<p style={{color:"white", fontSize: 10, textAlign:"center"}}> <span style = {{color:"#DC6428"}}>{otpError}</span></p>
                            </div>
					}

					<div style={{ padding: 40, marginTop:150}}>
						{
							!loading?
								<div >
									<Button style={{padding:100}}  isOutline={false} clickHandler={() => {submitLogin();}}  caption="CONTINUE" />
								</div>
								: <div >
									<Button loading style={{padding:100}}/>
								</div>
						}
                     
						<div style={{marginTop:25 }}>
							<Button isOutline style={{ marginTop:10 }} clickHandler={() => handleBackBtn()}  caption="BACK"  />
						</div>    
					</div>
                        
					<div style={{height:80}}>
						<div style={{marginTop:"15%",position:"absolute", width:"100%" }}>
							<p style={{color:"white", fontSize: 12, textAlign:"center"}}><span>{"Didn't get your OTP?"}</span> <span onClick={handleResendBtn} style = {{color:"#2196F3"}}>{"Tap here to resend"}</span></p>
						</div>    
					</div>
				</p>
			</div>
		</div>
	);
};

export default Otp;