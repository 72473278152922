import { motion } from "framer-motion";
import styled from "styled-components";

export const TabContainer = styled(motion.div)`
    display: flex;
    overflow-x: scroll;
    width: 100%;
    height: 48px;
    background-color: black;
    margin-top: 15px;
`;

export const Tab = styled(motion.div)`
    width: 120px;
    height: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    flex: none;
    order: 0;
    flex-grow: 1;

    ${({active}) => active ?
    `
    border-style: none none solid none;
    border-width: 2px;
    border-color: #FFCB05;`
	:
	""
    };

    p{
        width: 100%;
        height: 24px;
        font-family: 'MTNBrighterSansRegular';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: red;
        color: ${({active}) => active ? "#FFCB05" : "white"};
    }
`;