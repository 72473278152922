// import React from "react";
// import { Link } from "react-router-dom";
import { Button } from "..";
// import { PWA_ROUTES } from "../../../constants/appConstants";
import { StyledConfirmSection } from "./ConfirmSection.styles";

const ConfirmSection = ({confirmHandler, buttonCaption, loading}) => {
	return (
    <StyledConfirmSection>
      {/* <Link to={PWA_ROUTES.CONTENT_INFO}>
        <Button isOutline={true} isFullscreen={true} caption={"back"} />
      </Link> */}
      <Button
        loading={loading}
        isFullscreen={true}
        caption={buttonCaption || "confirm purchase"}
        clickHandler={confirmHandler}
      />
    </StyledConfirmSection>
  );
};

export default ConfirmSection;

