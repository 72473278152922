import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledTopUpMethods = styled(motion.div)`
   display: flex;
   flex-direction: column;

   .flex-contain {
	   display: flex;
	   justify-content: center;
	   align-items: center;
   }

   .button {
	   min-width: 140px;
   }
`;