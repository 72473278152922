import { recommendations } from "../types";

export const fetchRecommendationsItems = (call) => async(dispatch) => {


	try {         
		const response = await call?.fetchRecommendationsContent();
		if(response && response.response){
			dispatch({
				type: recommendations.FETCH_RECOMMENDATIONS_SUCCESS,
				payload: response,
			});
			return;
		}

		dispatch({
			type: recommendations.FETCH_RECOMMENDATIONS_REQUEST
		});
	} catch (error) {

		dispatch({
			type: recommendations.FETCH_RECOMMENDATIONS_ERROR,
			payload: {
				message: error?.message,
				error
			}
		});
	}
};
