import {events} from "../utils/redux/types";
import tracker from "./tracker";

const initialState = {
	tracker: tracker ? tracker : null,
};


const storageProfile = localStorage.getItem("profile");
const trackerReducer = (state = initialState, action) => {

	const persistedSession = localStorage.getItem("persisted_session");
	const items = JSON.parse(localStorage.getItem("persist:mtnplay-pwa"));

	if ("persisted_session" in localStorage) {
		if(persistedSession !== "undefined") {
			tracker?.user?.setCiam(persistedSession);
		} else {
			localStorage.setItem("persisted_session", tracker?.session?.session?.id);
			tracker?.user?.setCiam(tracker?.session?.session?.id);
		}

	} else {
		localStorage.setItem("persisted_session", tracker?.session?.session?.id);
		tracker?.user?.setCiam(tracker?.session?.session?.id);
	}



	switch (action.type) {
	case events.TRACK_EVENT:
		if (tracker?.session?.session?.id !== null) {
          
			tracker?.user?.set(items? JSON.parse(items.profile)?.data?.msisdn : storageProfile?.data?.msisdn);
			tracker.trackEvent(action.event);
        
			return {
				...state,
				tracker,
            
			};
		}

	case events.TRACK_PURCHASE:
		tracker.trackQuickBuy(action.event);
		return {
			...state,
			tracker,
		};
	default:
		return state;
	}

};

export default trackerReducer;















// import React, {useEffect} from 'react';
// import { useSelector } from 'react-redux';
// import {PWA_ROUTES} from '../../constants/appConstants';

// const ItemSelected = (props) => {
// 	const {subscriptions} = useSelector(state => state);
// 	const selectedItem = subscriptions?.selectedItem;
// 	return (props.children)
// }

// export default ItemSelected;