import { useSelector } from "react-redux";
import {useState, useRef, useEffect} from "react";
import { Closebar } from "../CloseBar";
import { ItemImageView } from "../ItemImageView";
import { StyledContentTopNav } from "./ContentTopNav.styles";

const ContentTopNav = () => {
	const [isSearch] = useState(false);
	// const [searchTerm, setSearchTerm] = useState("");
	const searchInput = useRef(null);
	const selectedItem = useSelector(state => state?.subscriptions?.selectedItem);

	useEffect(() => {
		if(isSearch) {
			searchInput.current.focus();
		}
	}, [isSearch]);
	return (
		<StyledContentTopNav>
			<ItemImageView  image={selectedItem?.imageUrl}/>
			<div className="box">
				<Closebar />
			</div>
		</StyledContentTopNav>
	);
};

export default ContentTopNav;
