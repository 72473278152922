  import {EVENT, PARAM} from "./helpers";

export const login_screen = (opcoOrigin, opcoEnv) => {
	return {
		eventName: EVENT.VIEW_SCREEN,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "discovery",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "login",
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			},
		],
	};
};

export const otp_screen = (opcoOrigin, opcoEnv) => {
	return {
		eventName: EVENT.VIEW_SCREEN,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "discovery",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "otp",
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			},
		],
	};
};


export const login_authentication = (opcoOrigin, opcoEnv, origin) => {
		return {
			eventName: EVENT.VIEW_SCREEN,
			eventParameters: [
				{
					key: "authentication_origin",
					value: origin,
				},
				{
					key: PARAM.KEY.OPCO_ORIGIN,
					value: opcoOrigin,
				},
				{
					key: PARAM.KEY.OPCO_ENVIRONMENT,
					value: opcoEnv,
				},
			],
		};
};
  

export const otp_request = (opcoOrigin, opcoEnv) => {
	return {
		eventName: EVENT.AUTHENTICATION,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "otp_request",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "login",
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			},
		],
	};
};
  
export const otp_successful = (opcoOrigin, opcoEnv) => {
	return {
		eventName: EVENT.AUTHENTICATION,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "otp_success",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "login",
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			},
		],
	};
};

export const otp_fail = (opcoOrigin, opcoEnv) => {
	return {
		eventName: EVENT.AUTHENTICATION,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "otp_fail",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "login",
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			},
		],
	};
};

export const otp_resend = (opcoOrigin, opcoEnv) => {
	return {
		eventName: EVENT.AUTHENTICATION,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "otp_resend",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "otp",
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			},
		],
	};
};

export const auth_request = (opcoOrigin, opcoEnv) => {
	return {
		eventName: EVENT.AUTHENTICATION,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "authenticate_request",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "otp",
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			},
		],
	};
};

export const auth_sucess = (opcoOrigin, opcoEnv) => {
	return {
		eventName: EVENT.AUTHENTICATION,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "authenticate_success",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "otp",
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			},
		],
	};
};

export const auth_fail= (opcoOrigin, opcoEnv) => {
	return {
		eventName: EVENT.AUTHENTICATION,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "authenticate_fail",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "otp",
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			},
		],
	};
};



