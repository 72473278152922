// import { motion } from "framer-motion";
import styled from "styled-components";
import { ITEM_TYPES } from "../../../constants/appConstants";

export const StyledLandingCategoryItem = styled.div`
  margin-right: 60px;

  .tag {
    /* height: 60px; */
    width: 140px;
    /* background: #111111; */
    padding: 4px;
    padding-left: 0;
    /* margin-bottom: 12px; */
    border-radius: 0 0 8px 8px;
    font-size: ${({ theme }) => theme?.size?.f1 ?? "8px"};
    font-style: normal;
    font-weight: 400;
    font-family: ${({ theme }) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
    /* padding-left: 10px; */
    padding-top: 10px;
  }

  .tag-desc {
    font-family: "MTN-Bold";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16.9px;
    color: #ffffff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .tag-sub-desc {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.06px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }

  .tag-description-sub {
    display: flex;
    justify-items: center;
    justify-content: center;

    padding: 6px;

    padding: 4px;

    background: #ffcb05;
    color: #111111;
    border-radius: 2px 0px 0px 2px;
    font-family: "MTNBrighterSansRegular";
    font-style: normal;
    font-weight: bold;

    line-height: 12px;
    text-transform: capitalize;

    line-height: 12px;
    text-transform: capitalize;
  }

  &.is-search-result {
    height: fit-content;
    width: 100%;
    display: inline-block;
    .tag-sub-desc {
      display: block;
      height: 15px;
      width: 90px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .tag-description-free {
    display: flex;
    justify-items: center;
    justify-content: center;

    display: flex;
    justify-items: center;
    justify-content: center;

    padding: 4px;
    background: #111111;
    color: #ffffff;
    border-radius: 2px 0px 0px 2px;
    font-family: "MTNBrighterSansRegular";
    font-style: normal;
    font-weight: bold;

    line-height: 12px;
    text-transform: capitalize;

    line-height: 12px;
    text-transform: capitalize;
  }

  .tag-description-once {
    display: flex;
    justify-items: center;
    justify-content: center;

    padding: 6px;

    padding: 4px;

    background: #111111;
    color: #ffcb05;
    border-radius: 2px 0px 0px 2px;
    font-family: "MTNBrighterSansRegular";
    font-style: normal;
    font-weight: bold;

    line-height: 12px;
    text-transform: capitalize;

    line-height: 12px;
    text-transform: capitalize;
  }

  .tag-description-pending {
    display: flex;
    justify-items: center;
    justify-content: center;

    padding: 4px;

    background: #dc6428;
    color: #fff;
    border-radius: 2px 0px 0px 2px;
    font-family: "MTNBrighterSansRegular";
    font-style: normal;
    font-weight: bold;

    line-height: 12px;
    text-transform: capitalize;
  }

  .metadata {
    margin-top: 12px;
    min-height: ${({ item }) => (item.description ? "30px" : "20px")};
    max-width: 120px;
    line-height: 12px;
    font-family: ${({ theme }) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
    font-size: ${({ theme }) => theme?.size?.f2 ?? "10px"};
    font-style: normal;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    text-transform: capitalize;

    & span {
      font-size: ${({ theme }) => theme?.size?.f1 ?? "8px"};
      line-height: 9px;
      font-family: ${({ theme }) =>
        theme?.fonts[0] ?? "MTNBrighterSansRegular"};
    }
  }

  .thumbnail {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    font-size: 8px;
    padding-top: 12px;
    font-family: "MTNBrighterSansRegular";
    font-style: normal;
    font-weight: bold;
    /* height: 120px; */
    aspect-ratio: 1;
    width: 140px;
    background-image: ${({ item, isVisible }) =>
      item?.imageUrl?.length && isVisible > 0 ? `url(${item?.imageUrl})` : ""};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
  }

  @media (320px < width < 414px) {
    .thumbnail {
      width: 110px;
    }
    .tag {
      width: 110px;
    }
  }

  &.is-search-result {
    max-width: ${({ isVertical }) => (isVertical ? "50%" : "164px")};

    display: ${({ isVertical }) => (isVertical ? "inline-block" : "unset")};

    .tag {
      min-height: 60px;

      min-width: 140px;

      /* background: #111111; */

      padding: 4px;
      padding-left: 0;

      border-radius: 0 0 8px 8px;

      font-size: ${({ theme }) => theme?.size?.f1 ?? "8px"};

      font-style: normal;

      font-weight: 400;

      font-family: ${({ theme }) =>
        theme?.fonts[0] ?? "MTNBrighterSansRegular"};

      /* padding-left: 12px; */

      padding-right: 12px;

      padding-top: 10px;

      padding-bottom: 12px;
    }

    .tag-desc {
      font-family: "MTN-Bold";

      font-style: normal;

      font-weight: 500;

      font-size: 13px;

      line-height: 16.09px;

      color: #ffffff;

      overflow: hidden;

      text-overflow: ellipsis;

      white-space: nowrap;
    }

    .tag-description-sub {
      display: flex;

      justify-items: center;

      justify-content: center;

      padding: 6px;

      background: #ffcb05;

      color: #111111;

      border-radius: 2px 0px 0px 2px;

      font-family: "MTNBrighterSansRegular";

      font-style: normal;

      font-weight: bold;

      line-height: 12px;

      text-transform: capitalize;
    }

    height: fit-content;

    width: 100%;

    display: inline-block;

    .tag-sub-desc {
      display: block;

      height: 15px;

      width: 100%;

      white-space: nowrap;

      text-overflow: ellipsis;

      overflow: hidden;

      font-family: "Roboto";

      font-style: normal;

      font-weight: 400;

      font-size: 12px;

      line-height: 14.06px;

      align-items: center;

      text-transform: capitalize;
    }

    .tag-description-free {
      display: flex;

      justify-items: center;

      justify-content: center;

      padding: 6px;

      background: #111111;

      color: #ffffff;

      border-radius: 2px 0px 0px 2px;

      font-family: "MTNBrighterSansRegular";

      font-style: normal;

      font-weight: bold;

      line-height: 12px;

      text-transform: capitalize;
    }

    .tag-description-once {
      display: flex;

      justify-items: center;

      justify-content: center;

      padding: 6px;

      background: #111111;

      color: #ffcb05;

      border-radius: 2px 0px 0px 2px;

      font-family: "MTNBrighterSansRegular";

      font-style: normal;

      font-weight: bold;

      line-height: 12px;

      text-transform: capitalize;
    }

    .tag-description-pending {
      display: flex;

      justify-items: center;

      justify-content: center;

      padding: 6px;

      background: #dc6428;

      color: #fff;

      border-radius: 2px 0px 0px 2px;

      font-family: "MTNBrighterSansRegular";

      font-style: normal;

      font-weight: bold;

      line-height: 12px;

      text-transform: capitalize;
    }

    .metadata {
      margin-top: 12px;

      min-height: ${({ item }) => (item.description ? "30px" : "20px")};

      max-width: 170px;

      line-height: 12px;

      font-family: ${({ theme }) =>
        theme?.fonts[0] ?? "MTNBrighterSansRegular"};

      font-size: ${({ theme }) => theme?.size?.f2 ?? "10px"};

      font-style: normal;

      font-weight: 500;

      display: flex;

      flex-direction: column;

      text-transform: capitalize;

      & span {
        font-size: ${({ theme }) => theme?.size?.f1 ?? "8px"};

        line-height: 9px;

        font-family: ${({ theme }) =>
          theme?.fonts[0] ?? "MTNBrighterSansRegular"};
      }
    }

    .thumbnail {
      display: flex;

      align-items: flex-end;

      flex-direction: column;

      font-size: 10px;

      padding-top: 12px;

      font-family: "MTNBrighterSansRegular";

      font-style: normal;

      font-weight: bold;
      min-width: 140px;

      min-height: ${({ item, isSearchResult }) => {
        if (isSearchResult) return "150px";

        if (item.trending || item.offers) return "120px";
        else {
          switch (item.itemType) {
            case ITEM_TYPES.TRENDING:
              return "120px";

            case ITEM_TYPES.MUSIC:
              return "120px";

            case ITEM_TYPES.MOVIE:
              return "180px";

            case ITEM_TYPES.GAME:
              return "120px";

            case ITEM_TYPES.VIDEO:
              return "100px";

            default:
              return "120px";
          }
        }
      }};

      background-image: ${({ item }) =>
        item?.imageUrl?.length > 0 ? `url(${item?.imageUrl})` : ""};

      background-position: center;

      background-repeat: no-repeat;

      background-size: cover;

      border-radius: 8px;

      min-height: ${({ isSearchResult }) => {
        return isSearchResult ? "150px" : "172px";
      }};
    }
  }
`;
