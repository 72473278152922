import { appState } from "../types";

export const saveHomeScrollState = (position) => async(dispatch) => {
	dispatch({
		type: appState.HOME_SCREEN_SCROLL_STATE,
		payload: position,
	});
};

export const saveMyPlayScrollState = (position) => async(dispatch) => {
	dispatch({
		type: appState.PLAY_SCREEN_SCROLL_STATE,
		payload: position,
	});
};

export const saveHomeMenuState = (menuState) => async(dispatch) => {
	dispatch({
		type: appState.HOME_SCREEN_MENU_STATE,
		payload: menuState,
	});
};

export const onMediaSelectedState = (mediaState) => async(dispatch) => {
	dispatch({
		type: appState.MEDIA_SELECTION_STATE,
		payload: mediaState,
	});
};

export const onSubscriptionStatusPositionState = (position) => async(dispatch) => {
	dispatch({
		type: appState.SUBSCRIPTION_STATUS_POSITION_STATE,
		payload: position,
	});
};

export const onInstalledPWA = () => async(dispatch) => {
	dispatch({
		type: appState.INSTALLED_PWA,
		payload: true,
	});
};

export const interactionPosition = (position) => async(dispatch) => {
	dispatch({
		type: appState.INTERACTION_POSITION_STATE,
		payload: position,
	});
};

export const interactionOnClose = (closedState) => async(dispatch) => {
	console.log("reached aaction");
	dispatch({
		type: appState.INTERACTION_CLOSED_STATE,
		payload: closedState,
	});
};