import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { StyledCloseBar } from "./CloseBar.styles";
import { subscriptions as subAction } from "../../../utils/redux/actions";
import { PWA_ROUTES } from "../../../constants/appConstants";

const CloseBar = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { subscriptions } = useSelector((state) => state);
  const data = {
    title: subscriptions?.selectedItem?.title,
    age: subscriptions?.selectedItem?.age,
    genre:
      subscriptions?.selectedItem?.genre?.length > 0
        ? subscriptions?.selectedItem?.genre.join(" . ")
        : "",
  };
  // eslint-disable-next-line no-unused-vars
  const closeScreen = () => {
    dispatch(subAction.selectItem(null));
    const url = new URL(window.location.href);
    const s = Array.from(url.searchParams.keys());
    if (s.includes("region") && s.includes("id")) {
      navigate(PWA_ROUTES.HOME);
      return;
    }
    navigate(-1);
  };

  return (
    <StyledCloseBar>
      <div className="titles">
        <span className="title">
          <b>{data?.title}</b>
        </span>
        <span className="description">{data?.genre}</span>
      </div>
      {/* <div onClick={closeScreen} className="icon close"></div> */}
    </StyledCloseBar>
  );
};

export default CloseBar;
