import {
  TopNav,
  Page,
  Border,
  SubMenuItem,
  ActiveSubscriptionInfo,
} from "../../shared/components";
import { useSelector } from "react-redux";

const InactiveSubscriptionScreen = () => {
  const { subscriptions } = useSelector((state) => state);
  const selectedItem = subscriptions?.selectedItem;

  return (
    <Page isBottomBar={false}>
      <TopNav enableSearch={false} title={`${selectedItem?.vendor?.title}`} />
      <SubMenuItem disabled={true} item={selectedItem} />
      <ActiveSubscriptionInfo info={`${selectedItem?.vendor?.slogan}`} />
      <Border />
      {/* <ActiveSubscriptionActions isOutline={true} caption="Renew Subscription" clickHandler={()=>{console.log("Is there a flow for Renew Subscription?")}} /> */}
    </Page>
  );
};

export default InactiveSubscriptionScreen;
