import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledContentTopNav = styled(motion.div)`
    position: relative;
    width: 100%;
    height: 260px;
    margin-bottom: 24px;
    border-radius: 4px;
    background-image: ${({image}) => image ? `url(${image})` : "url(/images/eternal.png)"};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .parent_box{
        display: flex;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.75) 29.69%, rgba(0, 0, 0, 0) 45.83%, rgba(0, 0, 0, 0) 90.62%, rgba(0, 0, 0, 0.5) 99.99%, #000000 100%);
        border-radius: 0px 0px 8px 8px;

        .box{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            padding: 0px;
            gap: 10px;
            width: 100%;
            height: 70px;
            padding: 24px;
            gap: 10px;
            // position: fixed;
            
            .header{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                width: 264px;
                height: 44px;
    
                .title{
                    width: auto;
                    height: 24px;
                    font-family: 'MTN';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    text-transform: capitalize;
                    color: #FFFFFF;
                }
    
                .info{
                    width: auto;
                    height: 20px;
                    font-family: 'MTNBrighterSansRegular';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                    color: #FFFFFF;
                }
            }
    
            .close{
                width: 25.87px;
                height: 24px;
                background-image: url('/images/icons/close.svg');
                background-position: top left;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }
`;