import {checkNull, EVENT, PARAM} from "./helpers";

export const payment_view_screen = (opcoOrigin, opcoEnv) => {
	return {
		eventName: EVENT.VIEW_SCREEN,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "discovery",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "payment",
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			},
		],
	};
};



export const consent_request_tap = (opcoOrigin, opcoEnv) => {
return {
	eventName: EVENT.TAP,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "checkout",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "subscription_consent_request",
		},
		{
			key: PARAM.KEY.OPCO_ORIGIN,
			value: opcoOrigin,
		},
		{
			key: PARAM.KEY.OPCO_ENVIRONMENT,
			value: opcoEnv,
		},
	],
};

};

export const checkout_view_screen_success = (
	opcoOrigin, opcoEnv,
	content_subscription_price,
	content_id,
	content_title,
	provider_title
) => {
	return {
		eventName: EVENT.VIEW_SCREEN,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "subscription",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "checkout_response",
			},
			{
				key: PARAM.KEY.CONTENT_SUBSCRIPTION_PRICE,
				value: checkNull(content_subscription_price),
			},
			{
				key: "content_id",
				value: content_id,
			},
			{
				key: "content_title",
				value: content_title,
			},
			{
				key: "subscription_response",
				value: "success",
			},
			{
				key: "service_provider",
				value: provider_title,
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			},
		],
	};

};

export const checkout_view_screen_already = (
	opcoOrigin, opcoEnv,
	content_subscription_price,
	content_id,
	content_title
) => {
	return {
		eventName: EVENT.VIEW_SCREEN,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "subscription",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "checkout_response",
			},
			{
				key: PARAM.KEY.CONTENT_SUBSCRIPTION_PRICE,
				value: checkNull(content_subscription_price),
			},
			{
				key: "content_id",
				value: content_id,
			},
			{
				key: "content_title",
				value: content_title,
			},
			{
				key: "subscription_response",
				value: "already_subcribed",
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			},
		],
	};

};


export const checkout_view_screen_insufficient = (
	opcoOrigin, opcoEnv,
	content_subscription_price,
	content_id,
	content_title
) => {
	return {
		eventName: EVENT.VIEW_SCREEN,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "subscription",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "checkout_response",
			},
			{
				key: PARAM.KEY.CONTENT_SUBSCRIPTION_PRICE,
				value: checkNull(content_subscription_price),
			},
			{
				key: "content_id",
				value: content_id,
			},
			{
				key: "content_title",
				value: content_title,
			},
			{
				key: "subscription_response",
				value: "insufficient_funds",
			},
			{
				key: PARAM.KEY.OPCO_ORIGIN,
				value: opcoOrigin,
			},
			{
				key: PARAM.KEY.OPCO_ENVIRONMENT,
				value: opcoEnv,
			},
		],
	};

};





export const confirm_purchase_tap = (
	opcoOrigin, opcoEnv,
	content_category,
	content_title,
	content_price_tier,
	content_subscription_price,
	content_vendor,
) => {
	return {
		eventName: EVENT.TAP,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "checkout",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "subscription_details",
			},
			{
				key: PARAM.KEY.CONTENT_CATEGORY,
				value: checkNull(content_category),
			},
			{
				key: PARAM.KEY.CONTENT_TITLE,
				value: checkNull(content_title),
			},
			{
				key: PARAM.KEY.CONTENT_PRICE_TIER,
				value: checkNull(content_price_tier),
			},
			{
				key: PARAM.KEY.CONTENT_SUBSCRIPTION_PRICE,
				value: checkNull(content_subscription_price),
			},
			{
				key: PARAM.KEY.CONTENT_VENDOR,
				value: checkNull(content_vendor),
			},
		],
	};
};




export const unsubscribe = (
	opcoOrigin, opcoEnv,
	content_subscription_price,
	vendor_title
) => {
	return {
		eventName: EVENT.TAP,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "unsubscribe",
			},
			{
				key: PARAM.KEY.CONTENT_SUBSCRIPTION_PRICE,
				value: checkNull(content_subscription_price),
			},
			{
				key: "service_provider",
				value: vendor_title
			},
		],
	};
};


export const confirm_back_tap = (opcoOrigin, opcoEnv, tap_label) => {
	return {
		eventName: EVENT.TAP,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "checkout",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "subscription_details",
			},
			{
				key: PARAM.KEY.TAP_TARGET,
				value: "back",
			},
			{
				key: PARAM.KEY.TAP_LABEL,
				value: checkNull(tap_label),
			},
		],
	};
};

export const checkout_confirmation_view_screen = (
	opcoOrigin, opcoEnv,
	content_category,
	content_title,
	content_price_tier,
	content_subscription_price,
) => {
	return {
		eventName: EVENT.VIEW_SCREEN,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "checkout",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "confirmation",
			},
			{
				key: PARAM.KEY.CONTENT_CATEGORY,
				value: checkNull(content_category),
			},
			{
				key: PARAM.KEY.CONTENT_TITLE,
				value: checkNull(content_title),
			},
			{
				key: PARAM.KEY.CONTENT_PRICE_TIER,
				value: checkNull(content_price_tier),
			},
			{
				key: PARAM.KEY.CONTENT_SUBSCRIPTION_PRICE,
				value: checkNull(content_subscription_price),
			},
		],
	};
};

export const checkout_subscription_details_view_screen = (
	opcoOrigin, opcoEnv,
	content_category,
	content_title,
	content_price_tier,
	content_subscription_price,
) => {
	return {
		eventName: EVENT.VIEW_SCREEN,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "checkout",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "subscription_details",
			},
			{
				key: PARAM.KEY.CONTENT_CATEGORY,
				value: checkNull(content_category),
			},
			{
				key: PARAM.KEY.CONTENT_TITLE,
				value: checkNull(content_title),
			},
			{
				key: PARAM.KEY.CONTENT_PRICE_TIER,
				value: checkNull(content_price_tier),
			},
			{
				key: PARAM.KEY.CONTENT_SUBSCRIPTION_PRICE,
				value: checkNull(content_subscription_price),
			},
		],
	};
};
