import {useEffect, useState} from "react";

import {useSelector} from "react-redux";
import {
	Page,
} from "../../shared/components";
import styled from "styled-components";
import frown from "../../assets/images/frown.png";
import { Button } from "../../shared/components/Button";
import { useNavigate } from "react-router-dom";
import { PWA_ROUTES } from "../../constants/appConstants";

const PlayGroundScreen = () => {
	const navigate = useNavigate();

	const {profile} = useSelector((state) => state);

	const [location, setLocation] = useState({lon:"0", lat:"0"});

	useEffect(()=>{
		return setTimeout(()=>{
			if(localStorage.getItem("geo"))
				setLocation(localStorage.getItem("geo"));
		}, 5000);
	}, []);

	return (
		<Page isBottomBar={false}>
			<div style={{ marginTop: 120}}>
				<GenericOverall>
					<img alt="" width="100" height="100" src={frown}/>
				</GenericOverall>

				<div style={{ textAlign: "center", marginTop: 100, fontWeight: 400, fontSize: 34}}>{profile?.data?.msisdn}</div>
				<div style={{ textAlign: "center", marginTop: 80, fontWeight: 400, fontSize: 34}}>{`Country: ${profile?.data?.location}`}</div>
				<div style={{ textAlign: "center", marginTop: 60, fontWeight: 400, fontSize: 34}}>{"Longitude:"+location.lon + " Latitude:"+location.lat}</div>
				<div style={{ marginTop: 40}}>
					<div style={{marginLeft: 50, marginRight: 50, marginTop: 10}}>
						<Button caption={"BACK TO HOMESCREEN"} clickHandler={()=>{ navigate(PWA_ROUTES.HOME);}} />
					</div>
				</div>
			</div>
		</Page>
	);
};

const GenericOverall = styled.div`

display: flex;
justify-content: center;
align-items: center;
`;

// const Loader = styled.div`
// display: flex;
// padding: 14px;
// justify-content: center;

// `;


export default PlayGroundScreen;