import { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { HomeScreen as events } from "../../analytics/events";
import { fetchItemsFilterAllByCategory } from "../../utils/redux/actions/filtered.actions";
import { LandingCategoryItem } from "../../shared/components/LandingCategoryItem";
import { TopNav } from "../../shared/components/TopNav";
import { Page } from "../../shared/components/Page";
import { useNavigate } from "react-router-dom";
import { PWA_ROUTES } from "../../constants/appConstants";
import { subscriptions } from "../../utils/redux/actions";
import { SearchResultsWrapper } from "../../shared/components/SearchResultsWrapper";
import { ClosestSearchResultCard } from "../../shared/components/ClosestSearchResultCard";
import { sortBy } from "lodash";
import { SELECTED_SOURCE } from "../../appConstants";

const SearchScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    items,
    filtered,
    selectedItem,
    error,
    recommendations,
    search: reduxSearchState,
  } = useSelector((state) => state);
  const {language} = useSelector((state) => state);
	const {network} = useSelector((state) => state);

  const [closestSearch, setClosestSearch] = useState(null);
  const [moreResults, setMoreResults] = useState(null);
  const [popularSearch, setPopularSearch] = useState([]);

  useEffect(() => {
    if (recommendations) {
      const { recommendations: rec } = recommendations;
      const { popular_content } = rec;
      if (popular_content) {
        let _popularSearch = popular_content.map((item) =>
          item.data ? item.data : [],
        );
        _popularSearch = _popularSearch.flat();
        setPopularSearch(_popularSearch);
      }
    }
  }, [recommendations]);

  useEffect(() => {
    let sortedResults = [...filtered.items];
    sortedResults = sortBy(sortedResults, ["title"]);
    if (sortedResults.length >= 2) {
      let res = [...sortedResults];
      setClosestSearch(res[0]);
      res.shift();
      setMoreResults([...res]);
      return;
    }

    if (sortedResults.length === 1) {
      setClosestSearch(sortedResults[0]);
      setMoreResults(null);
      return;
    }

    setClosestSearch(null);
    setMoreResults(null);
  }, [filtered]);

  const selectSearchResultItem = (item) => {
    dispatch({
      type: "ANALYTICS/TRACK_EVENT",
      event: events.content_hit_tap(
        network?.call?.headers?.isoRegion,
				network?.call?.headers.environment,
        item?.ID?.id,
        item?.title,
        item?.vendor?.id,
        item?.vendor?.title,
      ),
    });

    dispatch(subscriptions.selectItem(item, SELECTED_SOURCE.CONTENT));
    navigate(PWA_ROUTES.CONTENT_INFO);
  };

  useEffect(() => {
    if (error) {
      navigate(PWA_ROUTES.GENERIC_ERROR);
    }
  }, [error]);

  useEffect(() => {
    doSearch(reduxSearchState.query);
  }, [reduxSearchState]);

  const doSearch = (query) => {
    dispatch(fetchItemsFilterAllByCategory(query, items));
  };

  useEffect(() => {
    if (selectedItem) {
      navigate(PWA_ROUTES.CONTENT_INFO);
    }
  }, [selectedItem]);

  return (
    <Page isBottomBar={false}>
      <TopNav title="" />
      {reduxSearchState.query ? (
        <SearchResultsWrapper>
          {closestSearch ? (
            <Fragment>
              <div style={{ height: 32 }}>
                <span className="title">{language?.chosenPack?.language?.COMPONENTS?.home_search?.closest_search}</span>
              </div>
              <ClosestSearchResultCard
                item={closestSearch}
                onClick={() => selectSearchResultItem(closestSearch)}
                isSearchResult={true}
              />
            </Fragment>
          ) : null}
          {moreResults ? (
            <div style={{ height: 32 }}>
              <span className="title">{language?.chosenPack?.language?.COMPONENTS?.home_search?.more_result}</span>
            </div>
          ) : null}
          {moreResults ? (
            <div className="results">
              {moreResults.map((_item, i) => (
                <div key={i}>
                  <LandingCategoryItem
                    item={_item}
                    onClick={() => selectSearchResultItem(_item)}
                    isSearchResult={true}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </SearchResultsWrapper>
      ) : (
        <SearchResultsWrapper>
          <div style={{ height: 32 }}>
            <span className="title">{language?.chosenPack?.language?.COMPONENTS?.home_search?.popular_search}</span>
          </div>
          <div className="results">
            {popularSearch.map((_item, i) => (
              <div key={i}>
                <LandingCategoryItem
                  item={_item}
                  onClick={() => selectSearchResultItem(_item)}
                  isSearchResult={true}
                />
              </div>
            ))}
          </div>
        </SearchResultsWrapper>
      )}
    </Page>
  );
};

export default SearchScreen;
