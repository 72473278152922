import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledCloseBar = styled(motion.div)`

 display: flex;
 align-items: center;
 justify-content: space-between;
 height: 50px;
 padding: 0px;

	& .titles {
	display: flex;
	flex-direction: column;

		.title {
			font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
			font-style: normal;
			font-weight: 700;
			font-size: 1rem;
			line-height: 23px;
			color: white;
		}

		.description {
			font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			text-transform: capitalize;
		}
	}

	.icon {
		cursor: pointer;
		margin-left: 24px;
	}


	.close {
		background-image: url('/images/icons/close.svg');
		background-position: top left;
		background-repeat: no-repeat;
		background-size: cover;
		align-self: center;
		justify-self: flex-start;
	}
`;