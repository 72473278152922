export const FETCH_ITEMS_REQUEST = "FETCH_ITEMS_REQUEST";
export const FETCH_ITEMS_SUCCESS = "FETCH_ITEMS_SUCCESS";
export const FETCH_ITEMS_ERROR = "FETCH_ITEMS_ERROR";

export const FETCH_CATEGORIES_REQUEST = "FETCH_CATEGORIES_REQUEST";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_ERROR = "FETCH_CATEGORIES_ERROR";

export const FETCH_PLAY_INSTANCE_REQUEST = "FETCH_PLAY_INSTANCE_REQUEST";
export const FETCH_PLAY_INSTANCE_SUCCESS = "FETCH_PLAY_INSTANCE_SUCCESS";
export const FETCH_PLAY_INSTANCE_ERROR = "FETCH_PLAY_INSTANCE_ERROR";

export const FETCH_ADVERT_INVENTORY_REQUEST = "FETCH_ADVERT_INVENTORY_REQUEST";
export const FETCH_ADVERT_INVENTORY_SUCCESS = "FETCH_ADVERT_INVENTORY_SUCCESS";
export const FETCH_ADVERT_INVENTORY_ERROR = "FETCH_ADVERT_INVENTORY_ERROR";

export const FETCH_ADVERT_INVENTORY_REQUEST_MBKS = "FETCH_ADVERT_INVENTORY_REQUEST_MBKS";
export const FETCH_ADVERT_INVENTORY_SUCCESS_MBKS = "FETCH_ADVERT_INVENTORY_SUCCESS_MBKS";
export const FETCH_ADVERT_INVENTORY_ERROR_MBKS = "FETCH_ADVERT_INVENTORY_ERROR_MBKS";

export const FETCH_BANNERS_REQUEST = "FETCH_BANNERS_REQUEST";
export const FETCH_BANNERS_SUCCESS = "FETCH_BANNERS_SUCCESS";
export const FETCH_BANNERS_ERROR = "FETCH_BANNERS_ERROR";

export const SEARCH_ITEMS_REQUEST = "SEARCH_ITEMS_REQUEST";
export const SEARCH_ITEMS_SUCCESS = "SEARCH_ITEMS_SUCCESS";
export const SEARCH_ITEMS_ERROR = "SEARCH_ITEMS_ERROR";

export const FILTER_MENU_ITEMS_REQUEST = "FILTER_MENU_ITEMS_REQUEST";
export const FILTER_MENU_ITEMS_SUCCESS = "FILTER_MENU_ITEMS_SUCCESS";
export const FILTER_MENU_ITEMS_ERROR = "FILTER_MENU_ITEMS_ERROR";

export const FETCH_REDIRECT_REQUEST = "FETCH_REDIRECT_REQUEST";
export const FETCH_REDIRECT_SUCCESS = "FETCH_REDIRECT_SUCCESS";
export const FETCH_REDIRECT_ERROR = "FETCH_REDIRECT_ERROR";
export const CLEAR_REDIRECT = "CLEAR_REDIRECT";

export const FETCH_CHANNELS_REQUEST = "FETCH_CHANNELS_REQUEST";
export const FETCH_CHANNELS_SUCCESS = "FETCH_CHANNELS_SUCCESS";
export const FETCH_CHANNELS_ERROR = "FETCH_CHANNELS_ERROR";
