// import React from "react";
import { StyledActiveSubscriptionInfo } from "./ActiveSubscriptionInfo.styles";

const ActiveSubscriptionInfo = ({info}) => {
	return (
	  <StyledActiveSubscriptionInfo>
			<div className="info">
				{info}
	   </div>
	  </StyledActiveSubscriptionInfo>
	);
};

export default ActiveSubscriptionInfo;
