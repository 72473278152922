import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledBalanceInfo = styled(motion.div)`
  min-height: 148px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  font-family: MTNBrighterSansRegular;
font-style: normal;
font-size: 16px;
line-height: 19px;
font-weight: 400;

.current-balance {
font-weight: 700;
margin-bottom: 12px;

  & .balance-amount {
    color: #419182;
  }
}

.amount-needed {
	font-weight: 700;
	font-size: 24px;
  margin-top: 24px;
}


`;