import {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PartnerSubscriptionButtons } from "..";
import { PWA_ROUTES, INPUT_EVENTS } from "../../../constants/appConstants";
import { topupVoucher } from "../../../utils/redux/actions/subscription.action";
import { StyledTopUpVoucher } from "./TopUpVoucher.styles";

const TopUpVoucher = () => {
	const [voucher, setVoucher] = useState("");
	const {error, loading, voucherTopupStatus} = useSelector(state => state.subscriptions);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	const topUpVoucher = () => {
		dispatch(topupVoucher(voucher));
	}; 

	const automateDashes = (value) => {

		let last = value.lastIndexOf("-");
		let len = value.length;

		if(len - last == 5 && value.length < 24){
			value = value+"-";
			return value;
		}

		if(len - last == 6 && value.length < 24)
			value = value.slice(0,(len-1))+"-"+value.slice(len-1);

		return value;
	};

	const updateVoucher = (e) => {
		const inputValue = e.target.value;
		const inputType = e.nativeEvent.inputType;

		if(inputType == INPUT_EVENTS.DELETE_EVENT){
			setVoucher(inputValue);
			return;
		}

	    if(inputValue && inputValue?.length <= 24) {
			const results = automateDashes(inputValue);
			setVoucher(results);
		}

	};

	useEffect(() => {
		if(voucherTopupStatus) {
			setVoucher("");
			navigate(PWA_ROUTES.CONTENT_INFO);

		}
	}, [voucherTopupStatus]);

	return (
		<StyledTopUpVoucher>
			<div className="title">Enter your voucher code</div>
			<div className="input-wrapper">
				 <div className="input-before-text">
					 Enter the code as it appears on your voucher
				 </div>
			   <input autoComplete="off" className="voucher-input" onChange={(e) => {
				   updateVoucher(e);
			   }} type="text" name="voucher" value={voucher} id="voucher" placeholder="XXXX-XXXX-XXXX-XXXX-XXXX" />
			 </div>
			 {(error) && (
				  <div className="input-error-text">
				  { error?.message || "Please enter a valid voucher code"}
		         </div>
			 )}
			<div className="buttons">

			  {!loading &&  <PartnerSubscriptionButtons confirmRouteHandler={() => {topUpVoucher();}} backRoute={PWA_ROUTES.TOPUP} acknowledgeButtonText={"next"} />}
			 </div>
			
		</StyledTopUpVoucher>
	);
};

export default TopUpVoucher;
