import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { StyledCategoryBar } from "./CategoryMenu.styles";
// import { menuCategory } from '../../../../utils/mock';
import SubMenu from "../SubMenu/SubMenu";
import { fetchFilteredItems , fetchAllCategories} from "../../../../utils/redux/actions/items.actions";
import { appState } from "../../../../utils/redux/actions";
import ApiCall from "../../../../utils/network/apiCalls";

const CategoryMenu = ({
  savedDefaultState,
  saveDepthState,
  saveApiPayload,
  onSaveMenuState,
}) => {
  const dispatch = useDispatch();
  const { network, language, items } = useSelector((state) => state);
  const [depth, setDepth] = useState(
    saveDepthState ? saveDepthState : { depth: 0, dataSet: {} },
  );
  const [defaultState, setDefaultState] = useState(
    savedDefaultState
      ? savedDefaultState
      : {
          subCategories: { selectedIndex: undefined, default: "" },
          genre: { selectedIndex: undefined, default: "" },
        },
  );
  const [apiPayload, setApiPayload] = useState(
    saveApiPayload ? saveApiPayload : undefined,
  );
  const [menuCategory, setMenuCategory] = useState([]);

  const apiCall = new ApiCall({
    Ln: language?.chosenLanguage?.code,
    region: network?.call?.headers?.region,
  });

  // const [menuCategory, setMenuCategory] = useState(null);

  // const { categoryMenu: menuCategory } = ApiCall.useCategoryMenu();

  useEffect(() => {
    if (`${defaultState.subCategories.default}`.length > 0) {
      let _apiPayload = { ...apiPayload };

      if (defaultState.subCategories.selectedIndex === undefined) {
        _apiPayload = {
          ..._apiPayload,
          sub_categorie: defaultState.subCategories.default,
        };
      } else {
        _apiPayload = {
          ..._apiPayload,
          sub_categorie:
            depth.dataSet?.subCategories?.dataSet[
              defaultState.subCategories.selectedIndex
            ],
        };
      }

      if (defaultState.genre.selectedIndex === undefined) {
        _apiPayload = { ..._apiPayload, genre: defaultState.genre.default };
      } else {
        _apiPayload = {
          ..._apiPayload,
          genre:
            depth?.dataSet?.genre?.dataSet[defaultState.genre.selectedIndex],
        };
      }

      setApiPayload(_apiPayload);
    }
  }, [defaultState]);

  useEffect(() => {
    dispatch(fetchAllCategories(apiCall, language?.chosenLanguage?.code));
  }, []);

  useEffect(() => {
    setMenuCategory(items.categoryMenu);
  }, [items]);

  useEffect(() => {
    dispatch(fetchAllCategories(apiCall, language?.chosenLanguage?.code));
  }, [network]);

  useEffect(() => {
    if (apiPayload?.sub_categorie) {
      const payload = { ...apiPayload };

      onSaveMenuState({
        defaultState: defaultState,
        depth: depth,
        apiPayload: payload,
      });
      dispatch(fetchFilteredItems(apiCall, payload));
    }
  }, [apiPayload]);

  const handleSetDepth = (index) => {
    if (!menuCategory.length) return;

    setApiPayload({ ...apiPayload, type: menuCategory[index].value });
    setDepth({ depth: 1, dataSet: menuCategory[index].dataSet });
    setDefaultState({
      ...defaultState,
      subCategories: {
        default: menuCategory[index]?.dataSet?.subCategories.default,
        selectedIndex: undefined,
      },
      genre: {
        default: menuCategory[index]?.dataSet?.genre.default,
        selectedIndex: undefined,
      },
    });
  };
  const CategoryView = () => {
    if (!menuCategory) return <></>;

    return menuCategory.map((v, index) => (
      <div
        className="category"
        style={{ textTransform: "capitalize" }}
        key={v.value}
        onClick={() => handleSetDepth(index)}>
        {v.value === "Video"
          ? language?.chosenPack?.language?.COPY?.SCREENS?.home?.menu_categories
              ?.video
          : v.value}
      </div>
    ));
  };

  const handleReset = () => {
    dispatch(fetchFilteredItems(apiCall, undefined));
    dispatch(appState.saveHomeMenuState(undefined));

    setDepth({ depth: 0, dataSet: {} });
    setDefaultState({
      subCategories: { selectedIndex: undefined, default: "" },
      genre: { selectedIndex: undefined, default: "" },
    });
  };

  const handleUpdateState = (data) => {
    const updatedState = { ...defaultState };
    updatedState[data.key].selectedIndex = data.selectedIndex;
    setDefaultState(updatedState);
  };

  return (
    <StyledCategoryBar>
      {depth.depth === 0 ? (
        <CategoryView />
      ) : (
        <SubMenu
          state={defaultState}
          dataSet={depth.dataSet ? depth.dataSet : []}
          onResetMenu={handleReset}
          onUpdateState={(data) => handleUpdateState(data)}
        />
      )}
    </StyledCategoryBar>
  );
};

export default CategoryMenu;
