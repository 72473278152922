import { search } from "../types";

const initialState = {
  query: "",
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case search.SET_SEARCH_QUERY:
      return { ...state, query: action.payload.query };
    default:
      return state;
  }
};

export default searchReducer;
