import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LandingCategory, Page, TopNav, Button } from "../../shared/components";
import {SubscribeSection as events} from "../../analytics/events";
import { subscriptions} from "../../utils/redux/actions";
// import { ServiceScreen as events } from "../../analytics/events";
import {
//   fetchBanners,
  fetchAllContentByProvider,
  // fetchAdInventory,
  // fetchAdInventoryMbks,
//   fetchChannels
} from "../../utils/redux/actions/items.actions";
import { fetchItemsFilterAllByCategory } from "../../utils/redux/actions/filtered.actions";
import {
  clearSubscription,
  selectItem,
} from "../../utils/redux/actions/subscription.action";
import { clearRedirect } from "../../utils/redux/actions/items.actions";
import close from "../../assets/images/close.png";
// import loaderskel from "../../assets/images/loader-skeleton.png";
import { StyledBottomPopup } from "../../shared/components/BottomBar/popup";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { PWA_ROUTES } from "../../constants/appConstants";
import _ from "lodash";

// import { fetchRecommendationsItems } from "../../utils/redux/actions/recommendations.actions";
// import { Ads } from "../../shared/components/Ads";
import { useAuth0 } from "@auth0/auth0-react";
import { SELECTED_SOURCE } from "../../appConstants";
// import {  ModalButtons, ModalText, Modal } from "../../shared/components";

// import CoolTabs from '../../shared/components/react-cool-tabs';
// import CoolTabs from "react-cool-tabs";
// const helperFunction = new HelperFunctions();

// const width = window.innerWidth;
// const height = window.innerHeight;

const ServiceScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation();

  // eslint-disable-next-line no-unused-vars
  const { items, network, appState, filteredMenuItems, language, profile } =
    useSelector((state) => state);

  const { selectedItem, pendingItem, error, selectedService } = useSelector(
    (state) => state?.subscriptions,
  );

  const { loginWithRedirect, isAuthenticated, getAccessTokenSilently } =
    useAuth0();

  // const [_filtered, set_filtered] = useState(filtered);

  const [query, setQuery] = useState("");

  // const [closeInstallPWA, setCloseInstallPWA] = useState(false);

  const [modalPending, setModalPending] = useState(false);

  const [locationData, setLocationData] = useState(null);

  const {
		playInstanceItems,
	  } = useSelector((state) => state?.items);


  useEffect(() => {
    setLocationData(selectedService);

    getAccessTokenSilently()
      .then(() => {})
      .catch((e) => {
        console.log(e);
      });
  }, [selectedItem]);
  // const [modalControl, setModalControl] = useState(false);
  // const { isAuthenticated } = useAuth0();

  // const platform  = "MacIntel";

  // const ads =
  //   {
  //     position: "videos",
  //     data: {
  //       enableOverlay: true,
  //       header:
  //         language?.chosenPack?.language?.COMPONENTS?.footer_banner?.header,
  //       body: language?.chosenPack?.language?.COMPONENTS?.footer_banner
  //         ?.description,
  //       actionButton:
  //         language?.chosenPack?.language?.COMPONENTS?.footer_banner
  //           ?.button_label,
  //       link:  "http:" + items?.advertInventory?.adResponse?.clickUrl,
  //     },
  //     imgUrl: "http:" + items?.advertInventory?.adResponse?.imgUrl,
  //   };

  // const adsMbks =
  // {
  //   position: "",
  //   data: {
  //     enableOverlay: true,
  //     header:
  //       language?.chosenPack?.language?.COMPONENTS?.footer_banner?.header,
  //     body: language?.chosenPack?.language?.COMPONENTS?.footer_banner
  //       ?.description,
  //     actionButton:
  //       language?.chosenPack?.language?.COMPONENTS?.footer_banner
  //         ?.button_label,
  //     link:  "http:" + items?.advertInventoryMbks?.adResponse?.clickUrl,
  //   },
  //   imgUrl: "http:" + items?.advertInventoryMbks?.adResponse?.imgUrl,
  // };

  // const emptyFilteredSections = filtered == null ? true : (Object.keys(filtered).length === 0);

  useEffect(() => {
    // dispatch({
    //   type: "ANALYTICS/TRACK_EVENT",
    //   event: events.home_screen,
    // });

    dispatch(clearRedirect());
    items?.items?.map((res) => {
      if (res.name === pendingItem?.itemType) {
        res?.data?.map((response) => {
          response?.billingTypes?.map((ress) => {
            if (ress.subscriptionId === pendingItem.subscriptionId) {
              if (response.subscribed === "Active") {
              } else if (response.subscribed === "Pending") {
                if (localStorage.getItem("pending-state-closed") !== null) {
                  setModalPending(false);
                } else {
                  setModalPending(true);
                }
              } else if (response.subscribed === "Inactive") {
                //setModalPending(false)
              }
            }
          });
        });
      }
    });
    getMobileOperatingSystem();
    // setLocationData(selectedItem);

  }, []);

  const [serviceItems, setServiceItems] = useState({ items: [] });

  useEffect(() => {
    if (locationData?.id) {
      const filteredItems = _.map(items.items, (category) => ({
        ...category,
        data: _.filter(category.data, {
          serviceId: locationData?.id,
        }),
      }));
      
      setServiceItems({ items: filteredItems });
    }
  }, [items, locationData]);

  useEffect(() => {
    // navigate(PWA_ROUTES.CONTENT_INFO);
    if (locationData !== null) {
      // navigate(PWA_ROUTES.CONTENT_INFO);
      dispatch(fetchAllContentByProvider(network?.call, locationData?.id));
    }
    if (selectedItem == null) {
      dispatch(selectItem(locationData));
    }
  }, [locationData]);

  // useEffect(() => {
  //   // navigate(PWA_ROUTES.CONTENT_INFO);
  //   if (selectedItem !== null) {
  //     navigate(PWA_ROUTES.CONTENT_INFO);
  //   }
  // }, [selectedItem]);

  useEffect(() => {
    if (error !== null) {
      dispatch(clearSubscription());
      navigate(PWA_ROUTES.GENERIC_ERROR);
    }
  }, [error]);

  useEffect(() => {
    items?.items?.map((res) => {
      if (res.name === pendingItem?.itemType) {
        res?.data?.map((response) => {
          response?.billingTypes?.map((ress) => {
            if (ress.subscriptionId === pendingItem.subscriptionId) {
              if (response.subscribed === "Active") {
              } else if (response.subscribed === "Pending") {
                if (localStorage.getItem("pending-state-closed") !== null) {
                  setModalPending(false);
                } else {
                  setModalPending(true);
                }
              } else if (response.subscribed === "Inactive") {
                //setModalPending(false)
              }
            }
          });
        });
      }
    });
  }, [pendingItem]);

  useEffect(() => {
    doSearch(query);
  }, [query]);

  useEffect(() => {
    if (
      network?.call !== null ||
      network?.call !== undefined ||
      network?.call !== ""
    ) {
      if (
        network?.call?.headers?.region !== null ||
        network?.call?.headers?.region !== undefined ||
        network?.call?.headers?.region !== ""
      ) {
        if (network?.call?.headers?.region) {
          //   dispatch(fetchChannels(network?.call));
          //   dispatch(clearSubscription());
          //   dispatch(fetchRecommendationsItems(network?.call));
          // dispatch(fetchAllContentByProvider(network?.call));
          //   dispatch(fetchBanners(network?.call));
          // dispatch(fetchAdInventory(network?.call, 1687));
          // dispatch(fetchAdInventoryMbks(network?.call, 1688));
        }
      }
    }
  }, [network]);

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      if (localStorage.getItem("user-closed") === null) {
      } else {
      }
      return "iOS";
    }

    return "unknown";
  }

  // const modalSwich = () =>{
  // 	setModal(false);
  // 	localStorage.setItem("user-closed", true);
  // };

  const switchPendingState = () => {
    setModalPending(false);
    localStorage.setItem("pending-state-closed", true);
  };

  const doSearch = (query) => {
    dispatch(fetchItemsFilterAllByCategory(query, items));
  };

  // const bannerClick = (itemIn) => {
  //   dispatch(subscriptions.selectItem(itemIn));
  // };

  // useEffect(() => {
  //   if (selectedItem !== null) {
  //     navigate(PWA_ROUTES.CONTENT_INFO);
  //   }
  // }, [selectedItem]);

  const RenderMenuFilteredCategories = ({ contents }) => {
    if (contents.items.length == 0) return <EmptyPage>{""}</EmptyPage>;
    return (
      <div style={{ paddingTop: 30 }}>
        <LandingCategory
          key={contents?.items[0].itemType}
          viewMoreAction={undefined}
          title={null}
          items={contents?.items}
          adsProperties={undefined}
          appPreState={appState}
          isVertical={false}
        />
      </div>
    );
  };

  const handleOnScrollHorizontal = (e, items) => {
    const closeToEnd =
      e.target.lastElementChild.offsetLeft - e.target.scrollLeft;
    if (closeToEnd < 300) {
      console.log("Call next page", items);
      // Playgination.Next(currentPage)
    }
  };

  const doService = () => {
    if (!isAuthenticated) {
      dispatch({
        type: "ANALYTICS/TRACK_EVENT",
        event: events.authenticationAttempt(
          network?.call?.headers?.isoRegion,
          network?.call?.headers.environment
        ),
      });
      handleAuth0Login();
    }
    if (isAuthenticated && selectedItem?.subscribed !== "Active") {
      dispatch(subscriptions.fetchPaymentMethods(network?.call));
      dispatch(subscriptions.selectItem(locationData, SELECTED_SOURCE.CHANNEL));
      navigate(PWA_ROUTES.PAYMENTS);
    }
  };

  const handleAuth0Login = () => {
    localStorage.setItem(
      "state",
      JSON.stringify({ route: `${PWA_ROUTES.SERVICE}` }),
    );
    loginWithRedirect({
      redirectUri: `${window.location.origin}/auth/callback`,
    });
  };

  const RenderCategories = ({ offers }) => {
    const isNotEmpty = offers?.items?.some((_item) => _item.data?.length > 0);

    if (!isNotEmpty) return <EmptyPage>{language?.chosenPack?.language?.COPY?.SCREENS?.home?.no_result}</EmptyPage>;

    return offers.items.map((offer, index) => {
      if (offer?.data?.length === undefined || offer?.data?.length === 0)
        return <div key={index}></div>;

      return (
        <div key={index}>
          <LandingCategory
            viewMoreAction={offer?.viewMoreAction}
            title={
              offer?.name === "movies"
                ? language?.chosenPack?.language?.COPY?.SCREENS?.home
                    ?.category_description_movie
                : offer?.name === "videos"
                ? language?.chosenPack?.language?.COPY?.SCREENS?.home
                    ?.category_description_video
                : offer?.name
            }
            items={offer?.data}
            // adsProperties={ads}
            appPreState={appState}
            onScrollHorizontal={(e) => {
              handleOnScrollHorizontal(e, offer);
            }}
          />
        </div>
      );
    });
  };

  const gotoServiceDetails = () => {
    dispatch(selectItem(locationData));
    navigate(PWA_ROUTES.SERVICE_DETAILS_SCREEN);
  };

  return (
    <>
      {/* <DirectionProvider direction={language?.chosenLanguage === 'arabic' ? DIRECTIONS.RTL:DIRECTIONS.LTR}> */}

      <Page isBottomBar={false}>
        {modalPending && (
          <StyledBottomPopup>
            <p style={{ fontSize: 14 }}>{language?.chosenPack?.language?.COMPONENTS.success_alert}.</p>
            <img
              onClick={() => switchPendingState()}
              alt=""
              width="19"
              height="19"
              src={close}
            />
          </StyledBottomPopup>
        )}
        {/* <Paginationts/> */}
        <TopNav
          title={locationData?.name}
          searchInputCallBack={setQuery}
          enableSearch={false}
          centerTitle={true}
          showCloseIcon={false}
        />

        <div style={{ marginTop: 15 }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img style={{ width: 120, height: 120 }} src={locationData?.logo} />
          </div>
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <span
              style={{ color: " #ffcb05", fontSize: 14, opacity: 0.9 }}
              className="learn-more"
              onClick={gotoServiceDetails}>
               {language?.chosenPack?.language?.COPY?.SCREENS?.contentInfo?.learn_more}
            </span>
          </div>

          {playInstanceItems?.message === "full" && !isAuthenticated && (
            <div
              style={{
                marginLeft: 50,
                marginRight: 50,
                marginTop: 20,
                fontSize: 14,
              }}>
              <Button
                loading={false}
                caption={language?.chosenPack?.language?.COPY?.SCREENS?.serviceDetail?.login_to_sub}
                clickHandler={doService}
              />
            </div>
          )}

          {playInstanceItems?.message === "full" &&
            isAuthenticated &&
            locationData?.subscribed !== "Active" && (
              <div
                style={{
                  marginLeft: 50,
                  marginRight: 50,
                  marginTop: 20,
                  fontSize: 14,
                }}>
                <Button
                  loading={false}
                  caption={`${language?.chosenPack?.language?.COPY?.SCREENS?.serviceDetail?.subscribe_to} ${locationData?.name}`}
                  clickHandler={doService}
                />
              </div>
            )}

          {playInstanceItems?.message === "full" &&
            isAuthenticated &&
            locationData?.subscribed === "Active" && (
              <div
                style={{
                  marginLeft: 50,
                  marginRight: 50,
                  marginTop: 20,
                  fontSize: 14,
                }}>
                <a
                  onClick={() => {
                    // analyticsWatch();
                  }}
                  style={{
                    borderRadius: 40,
                    height: 40,
                    backgroundColor: "#FFCB05",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#000",
                    marginBottom: "20px",
                  }}
                  href={`${locationData?.homeUrl}?msisdn=${profile?.data?.msisdn}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {`${language?.chosenPack?.language?.COPY?.SCREENS?.serviceDetail?.watch_on} ${locationData?.name}`}
                </a>
              </div>
            )}

          {playInstanceItems?.message !== "full" && (
            <div
              style={{
                marginLeft: 50,
                marginRight: 50,
                marginTop: 20,
                fontSize: 14,
              }}>
              <a
                onClick={() => {
                  // analyticsWatch();
                }}
                style={{
                  borderRadius: 40,
                  height: 40,
                  backgroundColor: "#FFCB05",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#000",
                  marginBottom: "20px",
                  textTransform: "uppercase",
                }}
                href={`${locationData?.homeUrl}?msisdn=${profile?.data?.msisdn}`}
                target="_blank"
                rel="noopener noreferrer">
                {`${language?.chosenPack?.language?.COPY?.SCREENS?.contentInfo?.continue_to} ${locationData?.name}`}
              </a>
            </div>
          )}

          {/* { redirectUrl !== undefined && !selectedItem?.internalPlay && redirectUrlPass !== undefined &&

<div  style={{textDecoration: "none", color: "#ffff", width: "100%", marginLeft: "24px", marginRight:"24px"}}>
    <a  style={{ borderRadius: 40, height: 40, backgroundColor: "#FFCB05", display: "flex", justifyContent: "center",  alignItems:"center", color:"#000"}} href={redirectUrlPass} target="_blank"   rel="noopener noreferrer" > Watch on {selectedItem?.vendor?.title}</a>

</div>
    } */}
        </div>

        {filteredMenuItems.items.length > 0 ? (
          <RenderMenuFilteredCategories contents={filteredMenuItems} />
        ) : (
          <RenderCategories key={"items"} offers={serviceItems} />
        )}

        {/* <Ads data={adsMbks} form/> */}

        {/* {items.loading && (
          <img alt="" width={width} height={height} src={loaderskel} />
        )} */}
      </Page>
      {/* </DirectionProvider> */}
    </>
  );
};

const EmptyPage = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ServiceScreen;