import { motion } from "framer-motion";
import styled from "styled-components";


export const StyledBottomPopup = styled(motion.div)`

  // margin: 10px;
  display: flex;
	justify-content: space-between;

	background: #DC6428;
  color: #fff;
   padding: 20px;
  

  height: 50px; 
  width:95%;



  align-self: center;




	background: #DC6428;
	align-items: center;
	margin-top: auto;
	position: fixed;
  bottom: 80px;
  z-index: 999;
  border-radius: 10px;



//   animation: moveToRight 0.5s ease-in-out;
//   animation-delay: 9ms;

//   @keyframes moveToRight {
//     0% {
//       transform: translateX(100%);
//     }
//     100% {
//         transform: translateX(0px);
//     }
// }


`;

export const StyledBottomPopupActive = styled(motion.div)`

  margin: 10px;
  display: flex;
	justify-content: space-between;

	background: #32801f;
  color: #fff;
  padding: 20px;
  
  border-radius: 10px; 

  animation: moveToRight 0.5s ease-in-out;
  animation-delay: 9ms;

  @keyframes moveToRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0px);
    }
}


`;