// import React from "react";
import { StyledBorder } from "./Border.styles";

const Border = ({marginTop}) => {
	return (
		<StyledBorder marginTop={marginTop}>
			
		</StyledBorder>
	);
};

export default Border;
