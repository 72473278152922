import styled from "styled-components";
import { motion } from "framer-motion";
export const StyledTopNavLanguageToggle = styled(motion.div)`
  text-transform: uppercase;
  justify-self: flex-start;
  padding-right: 24px;
  position: relative;

  ::before {
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 20px;
    height: 20px;
    background-image: url("/images/icons/chevron-down.svg");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
    transition: all linear 0.2s;
    transform: ${({ isOpen }) =>
      isOpen ? "rotateZ(180deg)" : "rotateZ(0deg)"};
  }
`;
