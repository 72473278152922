import { appState } from "../types";

const initialState = {
	myPlayScreen:{yPosition:0},
	homeScreen:{yPosition:0},
	videoPlayer:{seek:0.0},
	audioPlayer:{seek:0.0},
	menuState: undefined,
	selectedMedia: undefined,
	subscriptionStatusPosition: 0,
	installedPWA: false,
	interactivePosition: undefined,
	closedState: undefined
};

const itemsReducer = (state = initialState, action) => {
	switch (action.type) {
	case appState.HOME_SCREEN_MENU_STATE:
		return {
			...state,
			menuState: action.payload
		};
	case appState.PLAY_SCREEN_SCROLL_STATE:
		return {
			...state,
			myPlayScreen: {yPosition: action.payload ? action.payload : 0}
		};
	case appState.HOME_SCREEN_SCROLL_STATE:
		return {
			...state,
			homeScreen: {yPosition: action.payload ? action.payload : 0}
		};
	case appState.MUSIC_PLAYER_SEEK_STATE:
		return {
			...state,
			audioPlayer: {seek: action.payload ? action.payload : 0.0}
		};
	case appState.VIDEO_PLAYER_SEEK_STATE:
		return {
			...state,
			videoPlayer: {seek: action.payload ? action.payload : 0.0}
		};
	case appState.MEDIA_SELECTION_STATE:
		return {
			...state,
			selectedMedia: action.payload
		};
	case appState.SUBSCRIPTION_STATUS_POSITION_STATE:
		return {
			...state,
			subscriptionStatusPosition: action.payload
		};
	case appState.INSTALLED_PWA:
		return {
			...state,
			installedPWA: action.payload
		};
	case appState.INTERACTION_POSITION_STATE:
		return {
			...state,
			interactivePosition: action.payload
		};
		case appState.INTERACTION_CLOSED_STATE:
			console.log("reached reducer", action.payload);
		return {
			...state,
			closedState: action.payload
		};

			
		
	default:
		return state;
	}
};

export default itemsReducer;