import styled  from "styled-components";
import {motion} from "framer-motion";

export const AdsPace = styled(motion.div)`

    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'MTN';
    font-style: normal;
    font-weight: bold;
    font-size: ${({theme}) => theme?.size?.f5 ?? "18px"};
    margin-top: 26%;
    padding: 5%;
    letter-spacing: 0.05px;
    text-transform: capitalize;
`;


export const StyledBanner = styled(motion.div)`
    background-image: ${({image}) => image ? `url(${image})` : "url(/images/MTN-Play-DSM-Logo.png)"};
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 220px;
`;

export const StyledBannerTransRes = styled(motion.div)`
     background-image: ${({image}) => image ? `url(${image})` : "url(/images/MTN-Play-DSM-Logo.png)"};
	   background-position: center;
     background-repeat: no-repeat;
     background-size: contain;
     padding-top:50px;
	   height: 100px;
  	
`;

export const StyledPaymentBannerTransRes = styled(motion.div)`
     background-image: ${({image}) => image ? `url(${image})` : "url(/images/MTN-Play-DSM-Logo.png)"};
	   background-position: center;
     background-repeat: no-repeat;
     background-size: cover;
     height: 200px;
`;


// export const StyledBanner = styled(motion.div)`
//      background-image: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, 0) 40.00%, rgba(17, 17, 17, 0.4) 60.00%, rgba(17, 17, 17, 0.9) 87.08%, #111111 100%), ${({image}) => image ? `url(${image})` : `url(/images/MTN-Play-DSM-Logo.png)`};
// 	   background-position: center left;
//      background-repeat: no-repeat;
//      background-size: cover;
// 	   min-height: 120px;
//   	 min-width: 320px; 
// `;

export const StyledOverlay = styled(motion.div)`
	   background: rgba(0, 0, 0, 0.75);
		 min-height: ${({theme}) => theme?.heights[6] ?? "180px"};
	   min-width: ${({theme}) => theme?.widths[8] ?? "100%"};
		 position: absolute;
     padding: 24px;
     display: flex;
     flex-direction: column;
     justify-content: space-around;
     align-items: center;

	.banner-question {
		font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
    font-style: normal;
		font-weight: bold;
		font-size: ${({theme}) => theme?.size?.f7 ?? "20px"};
		line-height: 23px;
    text-transform: uppercase;
    text-align: center;
	}

  .banner-justification {
    font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
    font-style: normal;
    font-weight: 500;
    font-size: ${({theme}) => theme?.size?.f5 ?? "16px"};
    line-height: 19px;
    text-align: center;
    display: flex;
    align-items: center;
    margin: 12px;
  }

  .outline-btn {
    min-height: ${({theme}) => theme?.heights[3] ?? "48px"};
    min-width: ${({theme}) => theme?.widths[2] ?? "204px"};
    padding: 16px 24px;
    font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
    font-style: normal;
    font-weight: 700;
    font-size: ${({theme}) => theme?.size?.f3 ?? "12px"};
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 25px;
    color: #000;
    text-transform: uppercase;
 }
`;