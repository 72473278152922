import { language } from "../types";





export const setLanguage = (newLanguage) => async (dispatch) => {
  dispatch({
    type: language.SET_LANGUAGE_SUCCESS,
    payload: newLanguage,
  });
};

export const toggleLanguageModal = () => async (dispatch, getState) => {
  const { language: currentLanguageState } = getState();
  dispatch({
    type: language.TOGGLE_LANGUAGE_MODAL,
    payload: !currentLanguageState.isLanguageModalOpen,
  });
};